import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { color, fontFamily, innerWidth, rem, breakpoints } from 'variables/_index'
import { useInView } from 'react-intersection-observer'
import classNames from 'classnames'

export interface AboutSectionProps {
  readonly noProps?: string
}

type AboutSectionPropsClassName = AboutSectionProps & {
  readonly className: string
}

function Component({ className }: AboutSectionPropsClassName) {
  const { ref, inView } = useInView({
    rootMargin: '-100px', // ref要素が現れてから100px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  return (
    <div className={className}>
      <div ref={ref} className={classNames('e-inner', inView && 'is-view')}>
        <div className="e-worries">
          <div className={classNames('e-worry', 'e-worry1')}>
            <div className="e-worry-in">どうやって大学を選ぶといいのかな?</div>
          </div>
          <div className={classNames('e-worry', 'e-worry2')}>
            <div className="e-worry-in">自分にあう大学ってどこだろう?</div>
          </div>
          <div className={classNames('e-worry', 'e-worry3')}>
            <div className="e-worry-in">自分だけでは難しそう…</div>
          </div>
        </div>
        <div className="e-arrow">
          <StaticImage src="../../../../assets/images/course/high-school/fv/arrow01.png" alt="" placeholder="none" loading="lazy" />
        </div>
        <div className="e-body">
          <div className="e-lead">高校生コースでは</div>
          <p className="e-content">
            受験のプロである担任と一緒に、
            <br className="u-hidden-pc" />
            <span className="is-em">自分の個性</span>を<span className="is-em">最大限生かせる</span>
            <br />
            <span className="is-em">大学・受験方式を見つけ</span>、
            <br className="u-hidden-pc" />
            <span className="is-em">大学合格を目指します</span>。
          </p>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<AboutSectionPropsClassName>`
  padding-top: ${rem(54)};
  padding-bottom: ${rem(50 / 2)};

  @media ${breakpoints.lg} {
    padding-top: ${rem(50)};
    padding-bottom: ${rem(80)};
  }

  @keyframes highSchoolAboutLead {
    0% {
      opacity: 0;
      translate: -100% 0;
    }

    100% {
      opacity: 1;
      translate: 0 0;
    }
  }

  @keyframes highSchoolAboutLeadIn {
    0% {
      opacity: 0;
      translate: 100% 0;
    }

    100% {
      opacity: 1;
      translate: 0 0;
    }
  }

  @keyframes highSchoolAboutArrow {
    0% {
      opacity: 0;
      translate: ${rem(0)} ${rem(-10)};
    }

    100% {
      opacity: 1;
      translate: ${rem(0)} ${rem(0)};
    }
  }

  @keyframes highSchoolAboutLogo {
    0% {
      opacity: 0;
      translate: ${rem(0)} ${rem(10)};
    }

    100% {
      opacity: 1;
      translate: ${rem(0)} ${rem(0)};
    }
  }

  > .e-inner {
    width: calc(${rem(375)} - ${rem(60)});
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${rem(1000)};
    }

    &.is-view {
      > .e-worries {
        > .e-worry1 {
          animation: highSchoolAboutLead linear 1s forwards 0s;

          @media ${breakpoints.lg} {
            animation: none;
          }

          > .e-worry-in {
            animation: highSchoolAboutLeadIn linear 1s forwards 0s;

            @media ${breakpoints.lg} {
              animation: none;
            }
          }
        }

        > .e-worry2 {
          animation: highSchoolAboutLead linear 1s forwards 0.6s;

          @media ${breakpoints.lg} {
            animation: none;
          }

          > .e-worry-in {
            animation: highSchoolAboutLeadIn linear 1s forwards 0.6s;

            @media ${breakpoints.lg} {
              animation: none;
            }
          }
        }

        > .e-worry3 {
          animation: highSchoolAboutLead linear 0.8s forwards 1.2s;

          @media ${breakpoints.lg} {
            animation: none;
          }

          > .e-worry-in {
            animation: highSchoolAboutLeadIn linear 0.8s forwards 1.2s;

            @media ${breakpoints.lg} {
              animation: none;
            }
          }
        }
      }

      > .e-arrow {
        animation: highSchoolAboutArrow linear 0.6s forwards 1.8s;

        @media ${breakpoints.lg} {
          animation: none;
        }
      }

      > .e-body {
        animation: highSchoolAboutLogo linear 0.8s forwards 2.4s;

        @media ${breakpoints.lg} {
          animation: none;
        }
      }
    }

    > .e-worries {
      width: fit-content;
      margin-left: auto;
      margin-right: auto;

      > .e-worry {
        width: fit-content;
        overflow: hidden;
        opacity: 0;
        translate: -100% 0;

        @media ${breakpoints.lg} {
          opacity: 1;
          translate: 0 0;
        }

        &:nth-child(n + 2) {
          margin-top: ${rem(25 / 2)};

          @media ${breakpoints.lg} {
            margin-top: ${rem(32)};
          }
        }

        > .e-worry-in {
          padding: ${rem(5)} ${rem(18 / 2)};
          color: ${color.main};
          border: 1px solid ${color.main};
          font-size: ${rem(32 / 2)};
          font-weight: 700;
          letter-spacing: 0.1em;
          text-indent: 0.1em;
          line-height: 1;
          opacity: 0;
          translate: 100% 0;

          @media ${breakpoints.lg} {
            opacity: 1;
            translate: 0 0;
            padding: ${rem(6)} ${rem(20)} ${rem(5)};
            font-size: ${rem(26)};
          }
        }
      }
    }

    > .e-arrow {
      text-align: center;
      margin-top: ${rem(50 / 2)};
      margin-bottom: ${rem(50 / 2)};
      opacity: 0;
      translate: ${rem(0)} ${rem(-10)};

      @media ${breakpoints.lg} {
        opacity: 1;
        translate: 0 0;
        margin-top: ${rem(45)};
        margin-bottom: ${rem(40)};
      }

      img {
        width: ${rem(134.02 / 2)};

        @media ${breakpoints.lg} {
          width: ${rem(80)};
        }
      }
    }

    > .e-body {
      opacity: 0;
      translate: ${rem(0)} ${rem(10)};

      @media ${breakpoints.lg} {
        opacity: 1;
        translate: 0 0;
      }

      > .e-lead {
        width: fit-content;
        margin-top: ${rem(30 / 2)};
        margin-left: auto;
        margin-right: auto;
        background: ${color.highSchoolMain};
        color: #fff;
        padding: ${rem(7)} ${rem(30 / 2)} ${rem(9)};
        font-size: ${rem(40 / 2)};
        font-weight: 500;
        font-family: ${fontFamily.zenKaku};
        letter-spacing: 0.15em;
        text-indent: 0.15em;
        line-height: 1;

        @media ${breakpoints.lg} {
          margin-top: ${rem(24)};
          padding: ${rem(9)} ${rem(26)} ${rem(11)};
          font-size: ${rem(30)};
        }
      }

      > .e-content {
        width: fit-content;
        margin: ${rem(5)} 0 0;
        font-size: ${rem(36 / 2)};
        letter-spacing: 0.15em;
        line-height: 2;
        font-weight: 500;
        margin-left: auto;
        margin-right: auto;

        @media ${breakpoints.lg} {
          margin-top: ${rem(18)};
          font-size: ${rem(20)};
          text-align: center;
        }

        .is-em {
          display: inline-block;
          color: ${color.accent2};
          font-weight: 700;
          position: relative;

          &::before {
            content: '';
            background: #b2eeff;
            height: ${rem(8 / 2)};
            width: 100%;
            position: absolute;
            bottom: ${rem(0)};
            left: 0;
            z-index: -1;
          }
        }
      }
    }
  }
`

export const AboutSection: (props: AboutSectionProps) => JSX.Element = StyledComponent

export default AboutSection
