import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { color, fontFamily, rem, breakpoints } from 'variables/_index'
import { EffectFade, Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
// import SwiperClass from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/effect-fade'
import 'swiper/css/autoplay'
import classNames from 'classnames'
import mv1SPImage from 'assets/images/course/high-school/fv/mv1-sp.png'
import mv2SPImage from 'assets/images/course/high-school/fv/mv2-sp.png'
import mv1PCImage from 'assets/images/course/high-school/fv/mv1-pc.png'
import mv2PCImage from 'assets/images/course/high-school/fv/mv2-pc.png'

export interface HeadSectionProps {
  readonly noProps?: string
}

type HeadSectionPropsClassName = HeadSectionProps & {
  readonly className: string
}

function Component({ className }: HeadSectionPropsClassName) {
  const swiperRef = useRef(null)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  useEffect(() => {
    // スライダー開始のタイミング
    // setTimeout(() => {
    //   startSlide() // delayが3sなので合計4.5s後に発火
    // }, 0)

    // スライダーを擬似的に隠す期間（画像読み込みでちらつくため）
    setTimeout(() => {
      setIsLoaded(true)
    }, 1500)
  }, [])

  // スライダーの自動再生をストップ
  // const stopSlide = () => {
  //   if (swiperRef.current && swiperRef.current.swiper) {
  //     swiperRef.current.swiper.autoplay.stop()
  //   }
  // }

  // スライダーの自動再生をスタート
  const startSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.autoplay.start()
    }
  }

  return (
    <div className={className}>
      <h1 className="e-title">
        <StaticImage
          className={classNames('e-mv1-sp-image')}
          src="../../../../assets/images/course/high-school/fv/title.png"
          alt="高校生コース"
          placeholder="none"
          loading="eager"
        />
      </h1>
      <div className="e-main">
        <div className="e-slide">
          <Swiper
            ref={swiperRef}
            modules={[EffectFade, Pagination, Autoplay]}
            pagination={{ clickable: true }}
            navigation={false}
            loop={true}
            speed={1500}
            effect={'fade'}
            autoplay={{
              delay: 3000,
            }}
            lazy={true}
          >
            <SwiperSlide>
              <picture>
                <source srcSet={mv1PCImage} media="(min-width: 768px)" width="840" height="580" />
                <img src={mv1SPImage} alt="" width="360" height="380.5" decoding="async" />
              </picture>
            </SwiperSlide>
            <SwiperSlide>
              <picture>
                <source srcSet={mv2PCImage} media="(min-width: 768px)" width="840" height="580" />
                <img src={mv2SPImage} alt="" className={classNames(isLoaded && 'is-loaded')} width="360" height="380.5" decoding="async" />
              </picture>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className={classNames('e-slide-cover', isLoaded && 'is-loaded')}>
          <picture>
            <source srcSet={mv1PCImage} media="(min-width: 768px)" width="840" height="580" />
            <img src={mv1SPImage} alt="" width="360" height="380.5" decoding="async" />
          </picture>
        </div>
        <div className="e-catch">
          <div className="e-catch-line1">
            <div className="e-catch-line1-in">
              <span className="is-em">受験のプロ</span>と
            </div>
          </div>
          <div className="e-catch-line2">
            <div className="e-catch-line2-in">
              ともに<span className="is-em">探し</span>、ともに<span className="is-em">作り</span>、
            </div>
          </div>
          <div className="e-catch-line3">
            <div className="e-catch-line3-in">
              ともに<span className="is-em">歩む</span>
            </div>
          </div>
        </div>
        <div className="e-footer">
          <div className="e-point-area">
            <div className="e-points">
              <div className="e-point2">
                <StaticImage
                  className="e-point2-image"
                  src="../../../../assets/images/course/high-school/fv/point1.svg"
                  alt="通信指導34年の実績"
                  placeholder="none"
                  loading="lazy"
                />
              </div>
              <div className="e-point3">
                <StaticImage
                  className="e-point3-image"
                  src="../../../../assets/images/course/high-school/fv/point2.svg"
                  alt="指導会員数50万人以上"
                  placeholder="none"
                  loading="lazy"
                />
              </div>
            </div>
            <p className="e-attention">※運営会社CKCネットワーク株式会社全体の実績です。</p>
          </div>
        </div>
      </div>
      <div className="e-logo">
        <StaticImage
          className="e-logo-image"
          src="../../../../assets/images/course/high-school/fv/logo-type3.png"
          alt="オンライン指導塾 Fit NET STUDY"
          placeholder="none"
          loading="lazy"
        />
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<HeadSectionPropsClassName>`
  @keyframes highSchoolPop {
    0% {
      opacity: 0;
      translate: ${rem(0)} ${rem(10)};
    }

    100% {
      opacity: 1;
      translate: ${rem(0)} ${rem(0)};
    }
  }

  @keyframes highSchoolText {
    0% {
      opacity: 0;
      translate: ${rem(0)} ${rem(10)};
    }

    100% {
      opacity: 1;
      translate: ${rem(0)} ${rem(0)};
    }
  }

  @keyframes highSchoolCatch {
    0% {
      opacity: 0;
      translate: -100% 0;
    }

    100% {
      opacity: 1;
      translate: 0 0;
    }
  }

  @keyframes highSchoolCatchIn {
    0% {
      opacity: 0;
      translate: 100% 0;
    }

    100% {
      opacity: 1;
      translate: 0 0;
    }
  }

  @keyframes highSchoolPoints {
    0% {
      opacity: 0;
      translate: ${rem(0)} ${rem(8)};
    }

    100% {
      opacity: 1;
      translate: ${rem(0)} ${rem(0)};
    }
  }

  > .e-title {
    height: ${rem(132 / 2)};
    padding: ${rem(22)} ${rem(20)} ${rem(16)};
    font-size: ${rem(50 / 2)};
    color: #fff;
    background: ${color.highSchoolMain};
    text-align: center;
    letter-spacing: 0.25em;
    font-family: ${fontFamily.zenKaku};
    font-weight: 500;

    @media ${breakpoints.lg} {
      height: ${rem(90)};
      padding-top: ${rem(38)};
      padding-bottom: ${rem(17)};
    }

    img,
    svg {
      width: ${rem(353.8 / 2)};
      height: ${rem(45.6 / 2)};

      @media ${breakpoints.lg} {
        width: ${rem(268.89)};
        height: ${rem(34.66)};
      }
    }
  }

  > .e-main {
    overflow: hidden;
    position: relative;
    margin-top: ${rem(30 / 2)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(35)};
      width: ${rem(1000)};
      margin-left: auto;
      margin-right: auto;
    }

    > .e-slide {
      padding-left: ${rem(15)};
      overflow: hidden;

      @media ${breakpoints.lg} {
        padding-left: ${rem(160)};
      }

      .swiper-pagination {
        padding-right: ${rem(40 / 2)};
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: ${rem(20 / 2)};
        bottom: ${rem(20 / 2)};

        @media ${breakpoints.lg} {
          padding-right: ${rem(0)};
          padding-left: ${rem(195)};
          gap: ${rem(20)};
          bottom: ${rem(30)};
          justify-content: flex-start;
        }

        .swiper-pagination-bullet {
          width: ${rem(12 / 2)};
          height: ${rem(12 / 2)};
          background-color: #fff;
          opacity: 0.3;
          margin: 0;

          @media ${breakpoints.lg} {
            width: ${rem(8)};
            height: ${rem(8)};
          }

          &.swiper-pagination-bullet-active {
            opacity: 1;
          }
        }
      }

      .e-mv1-sp-image {
        @media ${breakpoints.lg} {
          display: none;
        }
      }

      .e-mv2-sp-image {
        display: none;

        @media ${breakpoints.lg} {
          display: none;
        }

        &.is-loaded {
          display: block;

          @media ${breakpoints.lg} {
            display: none;
          }
        }
      }

      .e-mv1-pc-image {
        display: none;

        @media ${breakpoints.lg} {
          display: block;
        }
      }

      .e-mv2-pc-image {
        display: none;

        @media ${breakpoints.lg} {
          display: block;
        }

        &.is-loaded {
          display: none;

          @media ${breakpoints.lg} {
            display: block;
          }
        }
      }
    }

    > .e-slide-cover {
      padding-left: ${rem(30 / 2)};
      width: 100%;
      /* height: ${rem(761 / 2)}; */
      background: #fff;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      /* opacity: 1; */
      /* transition: opacity 0.8s ease-out 0s; */

      @media ${breakpoints.lg} {
        padding-left: ${rem(160)};
        /* height: ${rem(580)}; */
      }

      .e-mv1-sp-cover-image {
        @media ${breakpoints.lg} {
          display: none;
        }
      }

      .e-mv1-pc-cover-image {
        display: none;

        @media ${breakpoints.lg} {
          display: block;
        }
      }

      &.is-loaded {
        display: none;
      }
    }

    > .e-catch {
      position: absolute;
      top: ${rem(120)};
      left: ${rem(7)};
      z-index: 2;

      @media ${breakpoints.lg} {
        top: ${rem(158)};
        left: ${rem(0)};
      }

      > .e-catch-line1,
      > .e-catch-line2,
      > .e-catch-line3 {
        width: fit-content;
        overflow: hidden;
        translate: -100% 0;

        > .e-catch-line1-in,
        > .e-catch-line2-in,
        > .e-catch-line3-in {
          width: fit-content;
          padding: ${rem(4)} ${rem(8)} ${rem(6)};
          background: ${color.main};
          color: #fff;
          font-size: ${rem(40 / 2)};
          letter-spacing: 0.05em;
          text-indent: 0.05em;
          font-family: ${fontFamily.zenKaku};
          font-weight: 700;
          line-height: 1;
          translate: 100% 0;

          @media ${breakpoints.lg} {
            padding: ${rem(7)} ${rem(20)} ${rem(11)};
            font-size: ${rem(40)};
          }
        }
      }

      > .e-catch-line2,
      > .e-catch-line3 {
        margin-top: ${rem(20 / 2)};

        @media ${breakpoints.lg} {
          margin-top: ${rem(32)};
        }
      }

      > .e-catch-line1 {
        animation: highSchoolCatch linear 0.6s forwards 0.4s;

        > .e-catch-line1-in {
          animation: highSchoolCatchIn linear 0.6s forwards 0.4s;
        }
      }

      > .e-catch-line2 {
        animation: highSchoolCatch linear 1s forwards 1s;

        > .e-catch-line2-in {
          animation: highSchoolCatchIn linear 1s forwards 1s;
        }
      }

      > .e-catch-line3 {
        animation: highSchoolCatch linear 0.5s forwards 2s;

        > .e-catch-line3-in {
          animation: highSchoolCatchIn linear 0.5s forwards 2s;
        }
      }

      .is-em {
        color: #fff000;
      }
    }

    > .e-footer {
      margin-top: ${rem(-112 / 2)};
      padding-right: ${rem(24 / 2)};
      padding-top: ${rem(30 / 2)};
      width: ${rem(634 / 2)};
      border-radius: 0 ${rem(80 / 2)} 0 0;
      background: rgba(255, 255, 255, 0.9);
      position: relative;
      z-index: 2;

      @media ${breakpoints.lg} {
        margin-top: ${rem(-140)};
        margin-left: auto;
        border-radius: ${rem(80)} 0 0 0;
        padding-left: ${rem(26)};
        padding-right: ${rem(14)};
        padding-top: ${rem(28)};
        padding-bottom: ${rem(0)};
        width: fit-content;
      }

      > .e-point-area {
        width: fit-content;
        margin-left: auto;
        opacity: 0;
        translate: ${rem(0)} ${rem(8)};
        animation: highSchoolPoints linear 0.8s forwards 2.8s;

        > .e-points {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          gap: ${rem(26 / 2)};
          z-index: 2;
          position: relative;

          @media ${breakpoints.lg} {
            gap: ${rem(26)};
          }

          > .e-point2 {
            width: ${rem(217.34 / 2)};

            @media ${breakpoints.lg} {
              width: ${rem(182.26)};
            }
          }

          > .e-point3 {
            width: ${rem(217.34 / 2)};

            @media ${breakpoints.lg} {
              width: ${rem(182.26)};
            }
          }

          img {
            image-rendering: -webkit-optimize-contrast;
          }
        }

        > .e-attention {
          margin: ${rem(22 / 2)} 0 0;
          font-size: ${rem(19 / 2)};
          text-align: center;
          color: #656565;

          @media ${breakpoints.lg} {
            font-size: ${rem(14)};
            margin-top: ${rem(16)};
          }
        }
      }
    }
  }

  > .e-logo {
    margin-top: ${rem(20 / 2)};
    text-align: center;

    @media ${breakpoints.lg} {
      margin-top: ${rem(58)};
    }

    .e-logo-image {
      width: ${rem(370 / 2)};

      @media ${breakpoints.lg} {
        width: ${rem(326.8)};
      }
    }
  }
`

export const HeadSection: (props: HeadSectionProps) => JSX.Element = StyledComponent

export default HeadSection
