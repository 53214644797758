import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { color, fontFamily, innerWidth, rem, breakpoints, innerPadding, innerDummyPadding } from 'variables/_index'
import classNames from 'classnames'
import { Pagination, Navigation, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/autoplay'
import ExperienceCard from '../atom/ExperienceCard'
import ArrowNextImage from 'assets/images/course/high-school/support/support1/arrow-right.svg'
import ArrowPrevImage from 'assets/images/course/high-school/support/support1/arrow-left.svg'

export interface Support2VoiceProps {
  readonly noProps?: string
}

type Support2VoicePropsClassName = Support2VoiceProps & {
  readonly className: string
}

function Component({ className }: Support2VoicePropsClassName) {
  useEffect(() => {}, [])

  return (
    <div className={className}>
      <div className="e-inner">
        <div className="e-head">
          <StaticImage
            className="e-illust"
            src="../../../../../assets/images/course/high-school/support/support1/tablet-illust.png"
            alt=""
            placeholder="none"
            loading="lazy"
          />
          生徒の体験談
        </div>
        <div className="e-slide">
          <Swiper
            modules={[Pagination, Navigation]}
            spaceBetween={30}
            slidesPerView={1.33}
            centeredSlides={true}
            pagination={{ clickable: true, type: 'fraction' }}
            navigation={true}
            loop={true}
            breakpoints={{
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
                centeredSlides: false,
                loop: false,
              },
            }}
          >
            <SwiperSlide>
              <ExperienceCard
                imageElement={
                  <StaticImage src="../../../../../assets/images/course/high-school/support/support1/experience1.png" alt="" placeholder="none" />
                }
                text={
                  <>
                    <span className="is-em">面談で、入試に向けて『いつまでに何をするか』を教えてくれた。</span>
                    それがあったから部活に流されることなく、しっかり準備することができた！
                  </>
                }
                name="K.Nさん"
                university="島根大学"
              />
            </SwiperSlide>
            <SwiperSlide>
              <ExperienceCard
                imageElement={
                  <StaticImage src="../../../../../assets/images/course/high-school/support/support1/experience2.png" alt="" placeholder="none" />
                }
                text={
                  <>
                    担任の先生が、自分の得意不得意を踏まえて<span className="is-em">どう勉強したらいいかアドバイスしてくれた。</span>
                    勉強のやる気が出てきて、勉強習慣もついた！
                  </>
                }
                name="Y.Sさん"
                university="前橋工科大学"
              />
            </SwiperSlide>
            <SwiperSlide>
              <ExperienceCard
                imageElement={
                  <StaticImage src="../../../../../assets/images/course/high-school/support/support1/experience3.png" alt="" placeholder="none" />
                }
                text={
                  <>
                    受験の準備で、担任の先生に<span className="is-em">志望理由書を見てもらい、構成について助言をもらえた</span>
                    のがありがたかったです。
                  </>
                }
                name="H.Kさん"
                university="新潟大学"
              />
            </SwiperSlide>
            <SwiperSlide>
              <ExperienceCard
                imageElement={
                  <StaticImage src="../../../../../assets/images/course/high-school/support/support1/experience4.png" alt="" placeholder="none" />
                }
                text={
                  <>
                    <span className="is-em">高1の時から「評定の大事さ」を教えてもらっていた。</span>
                    そのおかげで、早くから受験を意識して勉強でき、評定平均4.6をとることができた！
                  </>
                }
                name="K.Mさん"
                university="信州大学"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<Support2VoicePropsClassName>`
  padding-top: ${rem(0)};
  padding-bottom: ${rem(100 / 2)};
  background: linear-gradient(#fff ${rem(42)}, #edfbff 0);

  @media ${breakpoints.lg} {
    padding-bottom: ${rem(120)};
  }

  > .e-inner {
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${rem(1000)};
    }

    > .e-head {
      font-family: ${fontFamily.zenKaku};
      font-weight: 700;
      font-size: ${rem(42 / 2)};
      letter-spacing: 0.3em;
      text-align: center;
      color: #00c8ff;
      background: #fff;
      padding: 0 ${rem(10)} ${rem(30 / 2)};
      width: ${rem(540 / 2)};
      margin-left: auto;
      margin-right: auto;
      border-radius: 0 0 ${rem(20 / 2)} ${rem(20 / 2)};

      @media ${breakpoints.lg} {
        width: ${rem(1000)};
        font-size: ${rem(36)};
        letter-spacing: 0.3em;
        padding-bottom: ${rem(28)};
        border-radius: 0 0 ${rem(20)} ${rem(20)};
      }

      > .e-illust {
        width: ${rem(117 / 2)};
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: ${rem(20 / 2)};

        @media ${breakpoints.lg} {
          width: ${rem(93.6)};
          margin-bottom: ${rem(28)};
        }
      }
    }

    > .e-slide {
      margin-top: ${rem(16)};
      width: 100vw;
      overflow: hidden;
      margin-left: calc(50% - 50vw);
      margin-right: calc(50% - 50vw);

      @media ${breakpoints.lg} {
        margin-top: ${rem(40)};
        width: ${rem(940)};
        margin-left: auto;
        margin-right: auto;
      }

      .swiper {
        padding-top: ${rem(80 / 2)};
        padding-bottom: ${rem(60 / 2)};

        @media ${breakpoints.lg} {
          padding-bottom: ${rem(0)};
        }

        .swiper-wrapper {
          @media ${breakpoints.lg} {
            transform: none !important;
            width: auto;
            height: auto;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: ${rem(50)} ${rem(40)};
          }
        }

        .swiper-slide {
          height: auto;

          > * {
            height: 100%;
          }

          @media ${breakpoints.lg} {
            flex-shrink: 1;
            width: auto !important;
            margin-right: 0 !important;
          }

          &.swiper-slide-duplicate {
            @media ${breakpoints.lg} {
              display: none;
            }
          }
        }

        .swiper-pagination {
          bottom: 0;

          @media ${breakpoints.lg} {
            display: none;
          }
        }

        .swiper-button-prev {
          width: ${rem(90 / 2)};
          height: ${rem(90 / 2)};
          border-radius: 0 ${rem(45 / 2)} ${rem(45 / 2)} 0;
          background: #00c8ff url(${ArrowPrevImage}) no-repeat center / ${rem(54.15 / 2)} ${rem(45.73 / 2)};
          left: 0;

          &::after {
            display: none;
          }

          @media ${breakpoints.lg} {
            display: none;
          }
        }

        .swiper-button-next {
          width: ${rem(90 / 2)};
          height: ${rem(90 / 2)};
          border-radius: ${rem(45 / 2)} 0 0 ${rem(45 / 2)};
          background: #00c8ff url(${ArrowNextImage}) no-repeat center / ${rem(54.15 / 2)} ${rem(45.73 / 2)};
          right: 0;

          &::after {
            display: none;
          }

          @media ${breakpoints.lg} {
            display: none;
          }
        }
      }
    }
  }
`

export const Support2Voice: (props: Support2VoiceProps) => JSX.Element = StyledComponent

export default Support2Voice
