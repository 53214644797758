import React from 'react'
import styled from 'styled-components'
import { breakpoints, color, rem } from 'variables/_index'
import ArrowLeftImage from 'assets/images/course/high-school/support/support1/arrow-left.svg'
import ArrowRightImage from 'assets/images/course/high-school/support/support1/arrow-right.svg'

export interface ExperienceCardProps {
  readonly university: string
  readonly text: JSX.Element
  readonly name: string
  readonly imageElement: JSX.Element
}

type ExperienceCardPropsClassName = ExperienceCardProps & {
  readonly className: string
}

function Component({ className, university, imageElement, text, name }: ExperienceCardPropsClassName) {
  return (
    <div className={className}>
      <div className="e-image">{imageElement}</div>
      <p className="e-text">{text}</p>
      <p className="e-name">{name}</p>
      <p className="e-university">{university} 合格</p>
    </div>
  )
}

const StyledComponent = styled(Component)<ExperienceCardPropsClassName>`
  background-color: #fff;
  border: 1.5px solid #00c8ff;
  position: relative;
  padding: ${rem(108)} ${rem(46 / 2)} ${rem(24)};
  display: flex;
  flex-direction: column;

  @media ${breakpoints.lg} {
    padding: ${rem(146)} ${rem(30)} ${rem(30)};
    border-width: 2px;
  }

  > .e-image {
    margin: 0;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: ${rem(-60 / 2)};
    width: ${rem(250 / 2)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(16)};
      width: ${rem(170)};
      top: ${rem(-44)};
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 1.5px solid #00c8ff;
      right: ${rem(5 / 2)};
      bottom: ${rem(5 / 2)};

      @media ${breakpoints.lg} {
        border-width: 2px;
        right: ${rem(5)};
        bottom: ${rem(5)};
      }
    }

    img {
      width: ${rem(250 / 2)};

      @media ${breakpoints.lg} {
        width: ${rem(170)};
      }
    }
  }

  > .e-text {
    margin: 0 0 ${rem(12)};
    font-weight: 500;
    font-size: ${rem(32 / 2)};
    line-height: calc(58 / 32);

    @media ${breakpoints.lg} {
      font-size: ${rem(20)};
      line-height: calc(42 / 20);
      margin-bottom: ${rem(4)};
    }

    .is-em {
      color: #00c8ff;
      background: #ffffc3;
      font-weight: 700;
    }
  }

  > .e-name {
    margin: auto 0 0;
    font-weight: 500;
    font-size: 28px;
    font-size: ${rem(28 / 2)};
    letter-spacing: 0.06em;
    text-align: center;

    @media ${breakpoints.lg} {
      font-size: ${rem(18)};
      margin-top: auto;
    }
  }

  > .e-university {
    margin: ${rem(6)} auto 0;
    font-weight: 500;
    font-size: ${rem(28 / 2)};
    letter-spacing: 0.06em;
    /* min-width: ${rem(260 / 2)}; */
    width: fit-content;
    border-radius: ${rem(28 / 2)};
    background: #e8e8e8;
    padding: ${rem(3)} ${rem(36 / 2)};
    text-align: center;

    @media ${breakpoints.lg} {
      font-size: ${rem(18)};
      padding: ${rem(7)} ${rem(20)};
      border-radius: ${rem(30)};
    }
  }
`

export const ExperienceCard: (props: ExperienceCardProps) => JSX.Element = StyledComponent

export default ExperienceCard
