import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { color, fontFamily, innerWidth, rem, breakpoints, innerPadding, innerDummyPadding } from 'variables/_index'
import Support2PointHead from './support2PointHead'

export interface Support2Point3SectionProps {
  readonly noProps?: string
}

type Support2Point3SectionPropsClassName = Support2Point3SectionProps & {
  readonly className: string
}

function Component({ className }: Support2Point3SectionPropsClassName) {
  return (
    <div className={className}>
      <Support2PointHead
        text="講習"
        numberImageSVG={
          <svg height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg">
            <path
              d="m635.3 389.8c0 .3 5 3.1 5 7.6 0 3.5-2.8 6.5-5.3 8.5-3.2 2.6-9.5 4.7-13.1 5.4-1.8.4-7.9 1.4-7.9-1.6a2.056 2.056 0 0 1 1.7-2.3 8.988 8.988 0 0 1 4 .4 12.22 12.22 0 0 0 3.8-.3c3.7-.7 12.8-3.8 12.8-9.5 0-4.2-3.2-5-6.4-4.3-1.8.4-3.4 1.2-5.1 1.6-1.2.2-3.6.3-3.6-1.6 0-.2.1-.3.1-.5.4-1.8 3.3-2 4.5-2.4 1-.4 2-.8 3-1.3 3-1.5 13.6-8 13.6-12.1 0-3.3-4.9-2.5-6.9-2.1a74.415 74.415 0 0 0 -19.4 7.4c-.2.1-.3.1-.5.2-.5.1-.7-.4-.7-.9a3.847 3.847 0 0 1 1.2-2.9c1.8-1.4 7.7-3.5 9.7-4.1 3-1 6.2-1.8 9.2-2.5 3.4-.7 11-2 11 3.5-.2 7.5-10.7 12.4-10.7 13.8z"
              fill="#00c8ff"
              transform="translate(-610 -371.846)"
            />
            <path d="m0 0h40v40h-40z" fill="none" />
          </svg>
        }
      />
      <div className="e-image">
        <div className="e-image-student">
          <StaticImage
            src="../../../../../assets/images/course/high-school/support/support2/point3/student3.png"
            alt=""
            placeholder="none"
            loading="lazy"
          />
        </div>
        <div className="e-image-star1-1">
          <StaticImage src="../../../../../assets/images/course/high-school/support/common/star-blue1.png" alt="" placeholder="none" loading="lazy" />
        </div>
        <div className="e-image-star1-2">
          <StaticImage src="../../../../../assets/images/course/high-school/support/common/star-blue2.png" alt="" placeholder="none" loading="lazy" />
        </div>
        <div className="e-image-star1-3">
          <StaticImage src="../../../../../assets/images/course/high-school/support/common/star-blue4.png" alt="" placeholder="none" loading="lazy" />
        </div>
        <div className="e-image-star1-4">
          <StaticImage
            src="../../../../../assets/images/course/high-school/support/support2/star-blue3.png"
            alt=""
            placeholder="none"
            loading="lazy"
          />
        </div>
      </div>
      <div className="e-box">
        <div className="e-box-body">
          <div className="e-box-text">
            春・夏・冬の<span className="is-em">講習時期には、基礎学力をアップするための総復習</span>
            を行います。これまでに学習した単元を総復習し苦手克服をすることで、成績アップの土台を固めます。
            <div className="e-box-illust1">
              <StaticImage
                src="../../../../../assets/images/course/high-school/support/support2/point3/illust1.png"
                alt=""
                placeholder="none"
                loading="lazy"
              />
            </div>
          </div>
          <div className="e-box-attentions">
            <div className="e-box-attention">※講習は別途料金がかかります。</div>
            <div className="e-box-attention">※一部予習をする教科があります。</div>
          </div>
        </div>
        <div className="e-box-image">
          <StaticImage
            src="../../../../../assets/images/course/high-school/support/support2/point3/image3-1.png"
            alt=""
            placeholder="none"
            loading="lazy"
          />
        </div>
      </div>
      <div className="e-plus">
        <StaticImage
          src="../../../../../assets/images/course/high-school/support/support2/point3/plus.png"
          alt=""
          placeholder="none"
          loading="lazy"
        />
      </div>
      <div className="e-box2">
        <h6 className="e-box2-head">目的に合わせた“４つ”のルーム</h6>
        <div className="e-box2-image">
          <StaticImage
            src="../../../../../assets/images/course/high-school/support/support2/point3/image3-2.png"
            alt=""
            placeholder="none"
            loading="lazy"
          />
        </div>
        <div className="e-box2-text">日々の勉強に集中できる環境を整え、勉強につまずかないようにサポートします。</div>
        <div className="e-rooms">
          <div className="e-room">
            <div className="e-room-head">
              <div className="e-room-icon">
                <StaticImage
                  src="../../../../../assets/images/course/high-school/support/support2/point3/room-icon1.png"
                  alt=""
                  placeholder="none"
                  loading="lazy"
                />
              </div>
              <div className="e-room-name">スタディルーム</div>
            </div>
            <div className="e-room-body">宿題やテスト勉強ができる自習室。いつも先生が見守っています。</div>
            <div className="e-room-time">月～土　18：00～21：40</div>
          </div>
          <div className="e-room">
            <div className="e-room-head">
              <div className="e-room-icon">
                <StaticImage
                  src="../../../../../assets/images/course/high-school/support/support2/point3/room-icon2.png"
                  alt=""
                  placeholder="none"
                  loading="lazy"
                />
              </div>
              <div className="e-room-name">
                解説ルーム<span className="is-small">（予約制）</span>
              </div>
            </div>
            <div className="e-room-body">勉強でわからないところを先生に質問できます。学校の宿題もOK。</div>
          </div>
          <div className="e-room">
            <div className="e-room-head">
              <div className="e-room-icon">
                <StaticImage
                  src="../../../../../assets/images/course/high-school/support/support2/point3/room-icon3.png"
                  alt=""
                  placeholder="none"
                  loading="lazy"
                />
              </div>
              <div className="e-room-name">
                単元テストルーム<span className="is-small">（予約制）</span>
              </div>
            </div>
            <div className="e-room-body">授業ごとにある単元テストに合格できなくても、ここで再チャレンジできます。</div>
          </div>
          <div className="e-room">
            <div className="e-room-head">
              <div className="e-room-icon">
                <StaticImage
                  src="../../../../../assets/images/course/high-school/support/support2/point3/room-icon4.png"
                  alt=""
                  placeholder="none"
                  loading="lazy"
                />
              </div>
              <div className="e-room-name">
                担任ルーム<span className="is-small">（予約制）</span>
              </div>
            </div>
            <div className="e-room-body">勉強に関する悩みや不安には、いつでも担任の先生と個別コーチング。</div>
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<Support2Point3SectionPropsClassName>`
  padding-top: ${rem(60 / 2)};

  @media ${breakpoints.lg} {
    padding-top: ${rem(40)};
  }

  > .e-head {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  > .e-image {
    margin-top: ${rem(20 / 2)};
    position: relative;
    z-index: 2;

    @media ${breakpoints.lg} {
      margin-top: ${rem(20)};
    }

    > .e-image-student {
      text-align: center;

      img {
        width: ${rem(264.62 / 2)};

        @media ${breakpoints.lg} {
          width: ${rem(264.62)};
        }
      }
    }

    > .e-image-star1-1 {
      width: ${rem(20 / 2)};
      height: ${rem(20 / 2)};
      position: absolute;
      top: ${rem(28 / 2)};
      right: calc(50% + ${rem(122 / 2)});
      animation: highSchoolStar linear var(--star-speed) infinite forwards 0.7s;

      @media ${breakpoints.lg} {
        width: ${rem(20)};
        height: ${rem(20)};
        top: ${rem(28)};
        right: calc(50% + ${rem(122)});
      }
    }

    > .e-image-star1-2 {
      width: ${rem(20 / 2)};
      height: ${rem(20 / 2)};
      position: absolute;
      top: ${rem(28 / 2)};
      left: calc(50% + ${rem(8 / 2)});
      animation: highSchoolStar linear var(--star-speed) infinite forwards 0s;

      @media ${breakpoints.lg} {
        width: ${rem(20)};
        height: ${rem(20)};
        top: ${rem(28)};
        left: calc(50% + ${rem(8)});
      }
    }

    > .e-image-star1-3 {
      width: ${rem(30 / 2)};
      height: ${rem(30 / 2)};
      position: absolute;
      top: ${rem(52 / 2)};
      left: calc(50% + ${rem(34 / 2)});
      animation: highSchoolStar linear var(--star-speed) infinite forwards 1.4s;

      @media ${breakpoints.lg} {
        width: ${rem(30)};
        height: ${rem(30)};
        top: ${rem(52)};
        left: calc(50% + ${rem(34)});
      }
    }

    > .e-image-star1-4 {
      width: ${rem(30 / 2)};
      height: ${rem(30 / 2)};
      position: absolute;
      top: ${rem(38 / 2)};
      left: calc(50% + ${rem(78 / 2)});
      animation: highSchoolStar linear var(--star-speed) infinite forwards 2.1s;

      @media ${breakpoints.lg} {
        width: ${rem(24)};
        height: ${rem(24)};
        top: ${rem(38)};
        left: calc(50% + ${rem(78)});
      }
    }
  }

  > .e-box {
    margin-top: ${rem(-30 / 2)};
    background: #edfbff;
    border-radius: ${rem(10 / 2)};
    padding: ${rem(60 / 2)} ${rem(60 / 2)} ${rem(0)};

    @media ${breakpoints.lg} {
      display: flex;
      align-items: center;
      gap: ${rem(60)};
      margin-top: ${rem(-30)};
      border-radius: ${rem(10)};
      padding: ${rem(60)} ${rem(60)} ${rem(0)};
    }

    > .e-box-body {
      @media ${breakpoints.lg} {
        width: calc(50% - ${rem(60 / 2)});
      }

      > .e-box-text {
        font-size: ${rem(32 / 2)};
        line-height: 2;
        position: relative;
        text-align: justify;

        @media ${breakpoints.lg} {
          font-size: ${rem(18)};
        }

        .is-em {
          background: linear-gradient(transparent 82%, #b2eeff 0);
        }

        > .e-box-illust1 {
          position: absolute;
          top: calc(100% + ${rem(10 / 2)});
          right: 0;

          @media ${breakpoints.lg} {
            top: calc(100% + ${rem(10)});
            right: ${rem(0)};
          }

          img {
            width: ${rem(74.49 / 2)};

            @media ${breakpoints.lg} {
              width: ${rem(53.21)};
            }
          }
        }
      }

      > .e-box-attentions {
        margin-top: ${rem(20 / 2)};

        @media ${breakpoints.lg} {
          margin-top: ${rem(20)};
        }

        > .e-box-attention {
          font-size: ${rem(20 / 2)};
          line-height: 1.6;

          @media ${breakpoints.lg} {
            font-size: ${rem(13)};
          }
        }
      }
    }

    > .e-box-image {
      text-align: center;
      margin-top: ${rem(40 / 2)};
      margin-right: ${rem(-60 / 2)};

      @media ${breakpoints.lg} {
        margin-top: ${rem(20)};
        margin-right: ${rem(-60)};
        width: calc(50% - ${rem(60 / 2)});
      }

      img {
        /* width: ${rem(610 / 2)}; */
        width: 100%;
      }
    }
  }

  > .e-plus {
    text-align: center;
    margin-top: ${rem(30 / 2)};
    margin-bottom: ${rem(30 / 2)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(20)};
      margin-bottom: ${rem(20)};
    }

    img {
      width: ${rem(100 / 2)};

      @media ${breakpoints.lg} {
        width: ${rem(80)};
      }
    }
  }

  > .e-box2 {
    background: #edfbff;
    border-radius: ${rem(10 / 2)};
    padding: ${rem(30 / 2)} ${rem(60 / 2)} ${rem(25 / 2)};

    @media ${breakpoints.lg} {
      border-radius: ${rem(10)};
      padding: ${rem(40)} ${rem(60)} ${rem(40)};
    }

    > .e-box2-head {
      font-size: ${rem(38 / 2)};
      font-weight: 700;
      letter-spacing: 0.05em;
      text-indent: 0.05em;
      color: ${color.main};
      font-family: ${fontFamily.zenKaku};
      text-align: center;
      white-space: nowrap;

      @media ${breakpoints.lg} {
        font-size: ${rem(32)};
      }
    }

    > .e-box2-image {
      text-align: center;
      margin-top: ${rem(30 / 2)};
      margin-left: ${rem(-60 / 2)};

      @media ${breakpoints.lg} {
        margin-top: ${rem(40)};
        margin-left: ${rem(0)};
        text-align: center;
      }

      img {
        /* width: ${rem(610 / 2)}; */
        width: 100%;

        @media ${breakpoints.lg} {
          width: ${rem(410)};
        }
      }
    }

    > .e-box2-text {
      margin-top: ${rem(40 / 2)};
      font-size: ${rem(32 / 2)};
      line-height: 2;
      text-align: justify;

      @media ${breakpoints.lg} {
        margin-top: ${rem(30)};
        font-size: ${rem(18)};
        text-align: center;
      }

      .is-em {
        background: linear-gradient(transparent 82%, #b2eeff 0);
      }
    }

    > .e-rooms {
      margin-top: ${rem(50 / 2)};
      margin-left: ${rem(-60 / 2)};
      margin-right: ${rem(-60 / 2)};

      @media ${breakpoints.lg} {
        margin-top: ${rem(30)};
        margin-left: ${rem(0)};
        margin-right: ${rem(0)};
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(${rem(410)}, 1fr));
        gap: 0 ${rem(60)};
      }

      > .e-room {
        border-top: 1px solid ${color.main};
        padding: ${rem(25 / 2)} ${rem(60 / 2)};

        @media ${breakpoints.lg} {
          border-top-width: 1px;
          padding: ${rem(20)} ${rem(0)};
        }

        > .e-room-head {
          display: flex;
          align-items: center;

          > .e-room-icon {
            flex-shrink: 0;
            margin-right: ${rem(30 / 2)};

            @media ${breakpoints.lg} {
              margin-right: ${rem(20)};
            }

            img {
              width: ${rem(100 / 2)};

              @media ${breakpoints.lg} {
                width: ${rem(70)};
              }
            }
          }

          > .e-room-name {
            flex-grow: 1;
            font-size: ${rem(32 / 2)};
            font-weight: 700;
            font-family: ${fontFamily.zenKaku};
            letter-spacing: 0.05em;
            text-indent: 0.05em;
            color: ${color.main};

            @media ${breakpoints.lg} {
              font-size: ${rem(24)};
            }

            .is-small {
              font-size: ${rem(26 / 2)};

              @media ${breakpoints.lg} {
                font-size: ${rem(18)};
              }
            }
          }
        }

        > .e-room-body {
          margin-top: ${rem(20 / 2)};
          line-height: 1.76;
          font-size: ${rem(26 / 2)};
          text-align: justify;

          @media ${breakpoints.lg} {
            margin-top: ${rem(20)};
            font-size: ${rem(16)};
          }
        }

        > .e-room-time {
          margin-top: ${rem(10 / 2)};
          font-size: ${rem(28 / 2)};
          color: ${color.main};

          @media ${breakpoints.lg} {
            margin-top: ${rem(10)};
            font-size: ${rem(16)};
          }
        }
      }
    }
  }
`

export const Support2Point3Section: (props: Support2Point3SectionProps) => JSX.Element = StyledComponent

export default Support2Point3Section
