import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { color, fontFamily, innerWidth, rem, breakpoints, innerPadding, innerDummyPadding } from 'variables/_index'
import { siteStructure } from 'utils/site'
import { Link } from 'gatsby'
import { ArrowRightKu } from 'components/icon'

export interface OverviewSectionProps {
  readonly noProps?: string
}

type OverviewSectionPropsClassName = OverviewSectionProps & {
  readonly className: string
}

function Component({ className }: OverviewSectionPropsClassName) {
  return (
    <div className={className}>
      <div className="e-inner">
        <h2 className="e-head">授業概要</h2>
        <div className="e-image">
          <StaticImage src="../../../../assets/images/course/high-school/overview/illust.png" alt="" placeholder="none" />
        </div>
        <div className="e-boxes">
          <div className="e-box">
            <h3 className="e-box-head">｜教科｜</h3>
            <div className="e-box1-content">
              <div className="e-box1-items">
                <div className="e-box1-item">
                  <span className="is-head">数学｜</span>
                  <span className="is-body">数学　I・Ⅱ・Ⅲ・A・B・C</span>
                </div>
                <div className="e-box1-item">
                  <span className="is-head">英語｜</span>
                  <span className="is-body">論理表現Ⅰ・論理表現Ⅱ</span>
                </div>
              </div>
            </div>
          </div>
          <div className="e-box">
            <h3 className="e-box-head">｜授業形式｜</h3>
            <div className="e-box2-content">
              <div className="e-box2-items">
                <div className="e-box2-item">
                  <span className="is-head">1回｜</span>
                  <span className="is-body">80分　　講師1人 対 生徒2人</span>
                </div>
              </div>
            </div>
          </div>
          <div className="e-box">
            <h3 className="e-box-head">｜時間割｜</h3>
            <div className="e-box3-content">
              <div className="e-box3-left">月～土曜日｜</div>
              <div className="e-box3-right">
                <div className="e-box3-items">
                  <div className="e-box3-item">
                    <span className="is-head">1｜</span>
                    <span className="is-body"> 17：30～18：50</span>
                  </div>
                  <div className="e-box3-item">
                    <span className="is-head">2｜</span>
                    <span className="is-body"> 19：00～20：20</span>
                  </div>
                  <div className="e-box3-item">
                    <span className="is-head">3｜</span>
                    <span className="is-body"> 20：30～21：50</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="e-box">
            <h3 className="e-box-head">｜料金について｜</h3>
            <div className="e-box4-content">
              <div className="e-box4-lead">高校生</div>
              <div className="e-box4-item">週1回　19,800円/月</div>
              <div className="e-box4-button">
                {/* TODO:ボタンのサイズの見直し */}
                <Link className="e-button" to={siteStructure.fee.path}>
                  <div className="e-text">もっと詳しくみる</div>
                  <ArrowRightKu className="e-arrow" color="#fff" />
                </Link>
              </div>
            </div>
          </div>
          <div className="e-illust-head">
            <StaticImage src="../../../../assets/images/course/high-school/overview/head.png" alt="" placeholder="none" />
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<OverviewSectionPropsClassName>`
  padding-top: ${rem(100 / 2)};
  padding-bottom: ${rem(60 / 2)};

  @media ${breakpoints.lg} {
    padding-top: ${rem(80)};
    padding-bottom: ${rem(80)};
  }

  > .e-inner {
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${innerWidth.lg};
      padding-left: ${rem(innerPadding.lg + innerDummyPadding.lg)};
      padding-right: ${rem(innerPadding.lg + innerDummyPadding.lg)};
    }

    > .e-head {
      margin-top: ${rem(10 / 2)};
      text-align: center;
      font-size: ${rem(50 / 2)};
      font-weight: 700;
      font-family: ${fontFamily.zenKaku};
      letter-spacing: 0.3em;
      text-indent: 0.3em;
      color: ${color.main};

      @media ${breakpoints.lg} {
        margin-top: ${rem(10)};
        font-size: ${rem(46)};
      }
    }

    > .e-image {
      margin-top: ${rem(60 / 2)};
      text-align: center;

      @media ${breakpoints.lg} {
        margin-top: ${rem(60)};
      }

      img {
        width: ${rem(260 / 2)};

        @media ${breakpoints.lg} {
          width: ${rem(182)};
        }
      }
    }

    > .e-boxes {
      margin-top: ${rem(70 / 2)};
      padding-top: ${rem(60 / 2)};
      border-radius: ${rem(30 / 2)};
      position: relative;
      background: #edfbff;

      @media ${breakpoints.lg} {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(${rem(410)}, 1fr));
        gap: 0 ${rem(60)};
        padding-top: ${rem(60)};
        padding-left: ${rem(60)};
        padding-right: ${rem(60)};
        margin-top: ${rem(70)};
        border-radius: ${rem(30)};
      }

      > .e-illust-head {
        position: absolute;
        top: ${rem(-50 / 2)};
        left: 50%;
        transform: translateX(-50%);
        text-align: center;

        @media ${breakpoints.lg} {
          top: ${rem(-50)};
        }

        img {
          width: ${rem(300 / 2)};

          @media ${breakpoints.lg} {
            width: ${rem(264)};
          }
        }
      }

      > .e-box {
        border-top: 1px solid ${color.main};
        padding: ${rem(40 / 2)} ${rem(60 / 2)};

        @media ${breakpoints.lg} {
          border-width: 1px;
          padding: ${rem(80)} ${rem(0)};
        }

        > .e-box-head {
          text-align: center;
          font-family: ${fontFamily.zenKaku};
          line-height: 1.2;
          font-size: ${rem(40 / 2)};
          letter-spacing: 0.2em;
          font-weight: 700;
          color: ${color.main};

          @media ${breakpoints.lg} {
            font-size: ${rem(38)};
          }
        }

        > .e-box1-content {
          margin-top: ${rem(20 / 2)};
          /* padding: ${rem(60 / 2)} ${rem(60 / 2)} ${rem(80 / 2)}; */

          @media ${breakpoints.lg} {
            margin-top: ${rem(40)};
            /* padding: ${rem(50)} ${rem(40)}; */
          }

          > .e-box1-items {
            > .e-box1-item {
              background: #fff;
              padding: ${rem(2)} ${rem(10 / 2)};
              font-size: ${rem(28 / 2)};
              letter-spacing: 0.05em;
              white-space: nowrap;

              @media ${breakpoints.lg} {
                padding: ${rem(5)} ${rem(20)};
                font-size: ${rem(18)};
              }

              &:nth-child(n + 2) {
                margin-top: ${rem(10 / 2)};

                @media ${breakpoints.lg} {
                  margin-top: ${rem(10)};
                }
              }

              > .is-head {
                color: ${color.main};
              }

              > .is-body {
              }
            }
          }
        }

        > .e-box2-content {
          margin-top: ${rem(30 / 2)};
          /* padding: ${rem(60 / 2)} ${rem(60 / 2)} ${rem(80 / 2)}; */

          @media ${breakpoints.lg} {
            margin-top: ${rem(40)};
            /* padding: ${rem(50)} ${rem(60)}; */
          }

          > .e-box2-items {
            > .e-box2-item {
              background: #fff;
              padding: ${rem(2)} ${rem(10 / 2)};
              font-size: ${rem(28 / 2)};
              letter-spacing: 0.05em;
              white-space: nowrap;

              @media ${breakpoints.lg} {
                padding: ${rem(5)} ${rem(20)};
                font-size: ${rem(18)};
              }

              &:nth-child(n + 2) {
                margin-top: ${rem(10 / 2)};

                @media ${breakpoints.lg} {
                  margin-top: ${rem(10)};
                }
              }

              > .is-head {
                color: ${color.main};
              }

              > .is-body {
              }
            }
          }
        }

        > .e-box3-content {
          display: flex;
          margin-top: ${rem(20 / 2)};
          /* padding: ${rem(60 / 2)} ${rem(60 / 2)} ${rem(80 / 2)};*/

          @media ${breakpoints.lg} {
            margin-top: ${rem(40)};
            /* padding: ${rem(50)} ${rem(60)}; */
          }

          > .e-box3-left {
            color: ${color.main};
            width: ${rem(210 / 2)};
            flex-shrink: 0;
            font-size: ${rem(28 / 2)};
            letter-spacing: 0.05em;

            @media ${breakpoints.lg} {
              width: ${rem(130)};
              font-size: ${rem(18)};
              padding-top: ${rem(3)};
            }
          }

          > .e-box3-right {
            flex-grow: 1;

            @media ${breakpoints.lg} {
            }

            > .e-box3-items {
              > .e-box3-item {
                background: #fff;
                padding: ${rem(2)} ${rem(10 / 2)};
                font-size: ${rem(28 / 2)};
                letter-spacing: 0.05em;
                white-space: nowrap;
                text-align: center;

                @media ${breakpoints.lg} {
                  padding: ${rem(5)} ${rem(20)};
                  font-size: ${rem(18)};
                  text-align: left;
                }

                &:nth-child(n + 2) {
                  margin-top: ${rem(10)};
                }

                > .is-head {
                  /* color: ${color.main}; */
                }

                > .is-body {
                }
              }
            }
          }
        }

        > .e-box4-content {
          > .e-box4-lead {
            margin-top: ${rem(20 / 2)};
            color: ${color.main};
            font-size: ${rem(28 / 2)};
            letter-spacing: 0.05em;
            white-space: nowrap;
            text-align: center;

            @media ${breakpoints.lg} {
              margin-top: ${rem(32)};
              font-size: ${rem(18)};
            }
          }

          > .e-box4-item {
            margin-top: ${rem(10 / 2)};
            background: #fff;
            padding: ${rem(2)} ${rem(30 / 2)};
            width: fit-content;
            margin-left: auto;
            margin-right: auto;
            font-size: ${rem(28 / 2)};
            letter-spacing: 0.05em;
            white-space: nowrap;
            text-align: center;

            @media ${breakpoints.lg} {
              margin-top: ${rem(10)};
              padding: ${rem(5)} ${rem(20)};
              font-size: ${rem(18)};
              width: 100%;
            }
          }

          > .e-box4-button {
            margin-top: ${rem(30 / 2)};
            text-align: center;

            @media ${breakpoints.lg} {
              margin-top: ${rem(20)};
            }

            > .e-button {
              display: inline-block;
              width: ${rem(450 / 2)};
              max-width: 100%;
              height: ${rem(110 / 2)};
              padding: 0;
              border-radius: 100px;
              border: ${rem(4)} solid #b9f0ff;
              background-color: ${color.main};
              font-family: ${fontFamily.zenKaku};
              font-weight: 500;
              color: #fff;
              text-decoration: none;
              transition: background-color 0.3s ease 0s, color 0.3s ease 0s;
              cursor: pointer;
              position: relative;

              @media ${breakpoints.md} {
                border-width: ${rem(4)};
                width: ${rem(280)};
                height: ${rem(60)};
                font-size: ${rem(16)};
              }

              > .e-text {
                display: flex;
                height: 100%;
                align-items: center;
                justify-content: center;
                font-size: ${rem(32 / 2)};
                letter-spacing: 0.05em;
                text-indent: 0.05em;

                @media ${breakpoints.md} {
                  font-size: ${rem(16)};
                }
              }

              > .e-arrow {
                position: absolute;
                right: ${rem(50 / 2)};
                top: 50%;
                transform: translateY(-50%);
                width: ${rem(7)};
                height: auto;

                path {
                  transition: stroke color 0.3s ease 0s;
                }

                @media ${breakpoints.lg} {
                  right: ${rem(45)};
                  width: ${rem(7)};
                }
              }

              &:hover,
              &:focus {
                background-color: #effbff;
                color: ${color.main};

                > .e-arrow {
                  path {
                    stroke: ${color.main};
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const OverviewSection: (props: OverviewSectionProps) => JSX.Element = StyledComponent

export default OverviewSection
