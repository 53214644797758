import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { color, fontFamily, innerWidth, rem, breakpoints, innerPadding, innerDummyPadding } from 'variables/_index'
import Rellax from 'rellax'

export interface SupportHeadSectionProps {
  readonly noProps?: string
}

type SupportHeadSectionPropsClassName = SupportHeadSectionProps & {
  readonly className: string
}

function Component({ className }: SupportHeadSectionPropsClassName) {
  const illustRef1 = useRef<HTMLDivElement>(null)
  const illustRef2 = useRef<HTMLDivElement>(null)
  const illustRef3 = useRef<HTMLDivElement>(null)
  const illustRef4 = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (illustRef1.current) {
      new Rellax(illustRef1.current, {
        speed: 0.8, // -10 ~ 10
        center: true,
      })
    }

    if (illustRef2.current) {
      new Rellax(illustRef2.current, {
        speed: 0.7, // -10 ~ 10
        center: true,
      })
    }

    if (illustRef3.current) {
      new Rellax(illustRef3.current, {
        speed: 0.6, // -10 ~ 10
        center: true,
      })
    }

    if (illustRef4.current) {
      new Rellax(illustRef4.current, {
        speed: 0.5, // -10 ~ 10
        center: true,
      })
    }
  }, [])

  return (
    <div className={className}>
      <div className="e-secret-head-student">
        <StaticImage src="../../../../../assets/images/course/high-school/support/common/student.png" alt="" placeholder="none" loading="lazy" />
      </div>
      <div ref={illustRef1} className="e-secret-head-illust1">
        <StaticImage src="../../../../../assets/images/course/high-school/support/common/illus1.png" alt="" placeholder="none" loading="lazy" />
      </div>
      <div ref={illustRef2} className="e-secret-head-illust2">
        <StaticImage src="../../../../../assets/images/course/high-school/support/common/illus2.png" alt="" placeholder="none" loading="lazy" />
      </div>
      <div ref={illustRef3} className="e-secret-head-illust3">
        <StaticImage src="../../../../../assets/images/course/high-school/support/common/illus3.png" alt="" placeholder="none" loading="lazy" />
      </div>
      <div ref={illustRef4} className="e-secret-head-illust4">
        <StaticImage src="../../../../../assets/images/course/high-school/support/common/illus4.png" alt="" placeholder="none" loading="lazy" />
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<SupportHeadSectionPropsClassName>`
  position: relative;
  text-align: center;

  .e-secret-head-student {
    img {
      width: ${rem(160 / 2)};

      @media ${breakpoints.lg} {
        width: ${rem(160)};
      }
    }
  }

  .e-secret-head-illust1 {
    position: absolute;
    top: ${rem(40 / 2)};
    right: calc(50% + ${rem(96 / 2)});
    z-index: 2;

    @media ${breakpoints.lg} {
      top: ${rem(40)};
      right: calc(50% + ${rem(96)});
    }

    img {
      width: ${rem(88 / 2)};
      height: ${rem(88 / 2)};

      @media ${breakpoints.lg} {
        width: ${rem(88)};
        height: ${rem(88)};
      }
    }
  }

  .e-secret-head-illust2 {
    position: absolute;
    top: ${rem(142 / 2)};
    right: calc(50% + ${rem(140 / 2)});
    z-index: 2;

    @media ${breakpoints.lg} {
      top: ${rem(142)};
      right: calc(50% + ${rem(140)});
    }

    img {
      width: ${rem(55 / 2)};
      height: ${rem(55 / 2)};

      @media ${breakpoints.lg} {
        width: ${rem(55)};
        height: ${rem(55)};
      }
    }
  }

  .e-secret-head-illust3 {
    position: absolute;
    top: ${rem(48 / 2)};
    left: calc(50% + ${rem(76 / 2)});
    z-index: 2;

    @media ${breakpoints.lg} {
      top: ${rem(48)};
      left: calc(50% + ${rem(76)});
    }

    img {
      width: ${rem(89 / 2)};
      height: ${rem(27 / 2)};

      @media ${breakpoints.lg} {
        width: ${rem(89)};
        height: ${rem(27)};
      }
    }
  }

  .e-secret-head-illust4 {
    position: absolute;
    top: ${rem(68 / 2)};
    left: calc(50% + ${rem(124 / 2)});
    z-index: 2;

    @media ${breakpoints.lg} {
      top: ${rem(68)};
      left: calc(50% + ${rem(124)});
    }

    img {
      width: ${rem(101 / 2)};
      height: ${rem(59 / 2)};

      @media ${breakpoints.lg} {
        width: ${rem(101)};
        height: ${rem(59)};
      }
    }
  }
`

export const SupportHeadSection: (props: SupportHeadSectionProps) => JSX.Element = StyledComponent

export default SupportHeadSection
