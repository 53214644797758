import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { color, fontFamily, innerWidth, rem, breakpoints, innerPadding, innerDummyPadding } from 'variables/_index'
import classNames from 'classnames'
import { useInView } from 'react-intersection-observer'

export interface Support2SectionStudentProps {
  readonly noProps?: string
}

type Support2SectionStudentPropsClassName = Support2SectionStudentProps & {
  readonly className: string
}

function Component({ className }: Support2SectionStudentPropsClassName) {
  const { ref, inView } = useInView({
    rootMargin: '-70px', // ref要素が現れてから100px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  return (
    <div ref={ref} className={classNames(className, inView && 'is-view')}>
      <div className="e-student-image">
        <StaticImage src="../../../../../assets/images/course/high-school/support/support2/student.png" alt="" placeholder="none" loading="lazy" />
      </div>
      <div className="e-student-left">
        <StaticImage
          className="u-hidden-pc"
          src="../../../../../assets/images/course/high-school/support/support2/line-left-sp.png"
          alt=""
          placeholder="none"
          loading="lazy"
        />
        <StaticImage
          className="u-hidden-sp"
          src="../../../../../assets/images/course/high-school/support/support2/line-left-pc.png"
          alt=""
          placeholder="none"
          loading="lazy"
        />
        {/* <div className="e-student-left-line1">
          <StaticImage
            src="../../../../../assets/images/course/high-school/support/support2/line-left1.png"
            alt=""
            placeholder="none"
            loading="lazy"
          />
        </div>
        <div className="e-student-left-line2">
          <StaticImage
            src="../../../../../assets/images/course/high-school/support/support2/line-left2.png"
            alt=""
            placeholder="none"
            loading="lazy"
          />
        </div>
        <div className="e-student-left-line3">
          <StaticImage
            src="../../../../../assets/images/course/high-school/support/support2/line-left3.png"
            alt=""
            placeholder="none"
            loading="lazy"
          />
        </div>
        <div className="e-student-left-line4">
          <StaticImage
            src="../../../../../assets/images/course/high-school/support/support2/line-left4.png"
            alt=""
            placeholder="none"
            loading="lazy"
          />
        </div> */}
      </div>
      <div className="e-student-right">
        <StaticImage
          className="u-hidden-pc"
          src="../../../../../assets/images/course/high-school/support/support2/line-right-sp.png"
          alt=""
          placeholder="none"
          loading="lazy"
        />
        <StaticImage
          className="u-hidden-sp"
          src="../../../../../assets/images/course/high-school/support/support2/line-right-pc.png"
          alt=""
          placeholder="none"
          loading="lazy"
        />
        {/* <div className="e-student-right-line1">
          <StaticImage
            src="../../../../../assets/images/course/high-school/support/support2/line-right1.png"
            alt=""
            placeholder="none"
            loading="lazy"
          />
        </div>
        <div className="e-student-right-line2">
          <StaticImage
            src="../../../../../assets/images/course/high-school/support/support2/line-right2.png"
            alt=""
            placeholder="none"
            loading="lazy"
          />
        </div>
        <div className="e-student-right-line3">
          <StaticImage
            src="../../../../../assets/images/course/high-school/support/support2/line-right3.png"
            alt=""
            placeholder="none"
            loading="lazy"
          />
        </div>
        <div className="e-student-right-line4">
          <StaticImage
            src="../../../../../assets/images/course/high-school/support/support2/line-right4.png"
            alt=""
            placeholder="none"
            loading="lazy"
          />
        </div> */}
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<Support2SectionStudentPropsClassName>`
  text-align: center;
  position: relative;

  @keyframes highSchoolStudentLineLeft {
    0% {
      opacity: 0;
      scale: 1;
      translate: 0 0;
    }

    70% {
      opacity: 1;
      scale: 1;
      translate: 0 0;
    }

    100% {
      opacity: 1;
      scale: 1;
      translate: 0 0;
    }
  }

  @keyframes highSchoolStudentLineRight {
    0% {
      opacity: 0;
      scale: 1;
      translate: 0 0;
    }

    70% {
      opacity: 1;
      scale: 1;
      translate: 0 0;
    }

    100% {
      opacity: 1;
      scale: 1;
      translate: 0 0;
    }
  }

  > .e-student-image {
    padding-left: ${rem(16 / 2)};

    @media ${breakpoints.lg} {
      padding-left: ${rem(16)};
    }

    img {
      width: ${rem(302 / 2)};

      @media ${breakpoints.lg} {
        width: ${rem(302)};
      }
    }
  }

  &.is-view {
    > .e-student-left {
      animation: highSchoolStudentLineLeft ease-in 1.5s forwards 0s 3;
    }

    > .e-student-right {
      animation: highSchoolStudentLineRight ease-in 1.5s forwards 0s 3;
    }
  }

  > .e-student-left {
    position: absolute;
    top: ${rem(44 / 2)};
    right: calc(50% + ${rem(124 / 2)});
    transform-origin: center right;
    opacity: 0;

    @media ${breakpoints.lg} {
      top: ${rem(32)};
      right: calc(50% + ${rem(124)});
    }

    img,
    svg {
      width: ${rem(45 / 2)};

      @media ${breakpoints.lg} {
        width: ${rem(45)};
      }
    }

    > .e-student-left-line1 {
      position: absolute;
      top: ${rem(0)};
      right: ${rem(0)};
      width: ${rem(40 / 4)};
      height: ${rem(42 / 4)};

      @media ${breakpoints.lg} {
        width: ${rem(40 / 2)};
        height: ${rem(42 / 2)};
      }

      img {
      }
    }

    > .e-student-left-line2 {
      position: absolute;
      top: ${rem(9)};
      right: ${rem(5)};
      width: ${rem(68 / 4)};
      height: ${rem(34 / 4)};

      @media ${breakpoints.lg} {
        top: ${rem(12)};
        right: ${rem(5)};
        width: ${rem(68 / 2)};
        height: ${rem(34 / 2)};
      }

      img {
      }
    }

    > .e-student-left-line3 {
      position: absolute;
      top: ${rem(22)};
      right: ${rem(7)};
      width: ${rem(54 / 4)};
      height: ${rem(8 / 4)};

      @media ${breakpoints.lg} {
        top: ${rem(29)};
        right: ${rem(7)};
        width: ${rem(54 / 2)};
        height: ${rem(8 / 2)};
      }

      img {
      }
    }
    > .e-student-left-line4 {
      position: absolute;
      top: ${rem(30)};
      right: ${rem(5)};
      width: ${rem(68 / 4)};
      height: ${rem(32 / 4)};

      @media ${breakpoints.lg} {
        top: ${rem(38)};
        right: ${rem(5)};
        width: ${rem(68 / 2)};
        height: ${rem(32 / 2)};
      }

      img {
      }
    }
  }

  > .e-student-right {
    position: absolute;
    top: ${rem(40 / 2)};
    left: calc(50% + ${rem(124 / 2)});
    transform-origin: center left;
    opacity: 0;

    @media ${breakpoints.lg} {
      top: ${rem(32)};
      left: calc(50% + ${rem(124)});
    }

    img,
    svg {
      width: ${rem(45 / 2)};

      @media ${breakpoints.lg} {
        width: ${rem(45)};
      }
    }

    > .e-student-right-line1 {
      position: absolute;
      top: ${rem(0)};
      left: ${rem(0)};
      width: ${rem(40 / 4)};
      height: ${rem(40 / 4)};

      @media ${breakpoints.lg} {
        width: ${rem(40 / 2)};
        height: ${rem(40 / 2)};
      }
    }

    > .e-student-right-line2 {
      position: absolute;
      top: ${rem(10)};
      left: ${rem(4)};
      width: ${rem(68 / 4)};
      height: ${rem(32 / 4)};

      @media ${breakpoints.lg} {
        top: ${rem(12)};
        width: ${rem(68 / 2)};
        height: ${rem(32 / 2)};
      }

      img {
      }
    }

    > .e-student-right-line3 {
      position: absolute;
      top: ${rem(23)};
      left: ${rem(6)};
      width: ${rem(54 / 4)};
      height: ${rem(20 / 4)};

      @media ${breakpoints.lg} {
        top: ${rem(30)};
        left: ${rem(6)};
        width: ${rem(54 / 2)};
        height: ${rem(20 / 2)};
      }

      img {
      }
    }

    > .e-student-right-line4 {
      position: absolute;
      top: ${rem(32)};
      left: ${rem(4)};
      width: ${rem(68 / 4)};
      height: ${rem(32 / 4)};

      @media ${breakpoints.lg} {
        top: ${rem(40)};
        left: ${rem(4)};
        width: ${rem(68 / 2)};
        height: ${rem(32 / 2)};
      }

      img {
      }
    }
  }
`

export const Support2SectionStudent: (props: Support2SectionStudentProps) => JSX.Element = StyledComponent

export default Support2SectionStudent
