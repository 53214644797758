import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { rem, breakpoints } from 'variables/_index'

export interface Support1StepHeadImageProps {
  readonly teacherImage: JSX.Element
}

type Support1StepHeadImagePropsClassName = Support1StepHeadImageProps & {
  readonly className: string
}

function Component({ className, teacherImage }: Support1StepHeadImagePropsClassName) {
  return (
    <div className={className}>
      <div className="e-step-head-image-teacher">{teacherImage}</div>
      <div className="e-step-head-image-star1">
        <StaticImage src="../../../../../assets/images/course/high-school/support/common/star-blue3.png" alt="" placeholder="none" loading="lazy" />
      </div>
      <div className="e-step-head-image-star2">
        <StaticImage src="../../../../../assets/images/course/high-school/support/common/star-blue1.png" alt="" placeholder="none" loading="lazy" />
      </div>
      <div className="e-step-head-image-star3">
        <StaticImage src="../../../../../assets/images/course/high-school/support/common/star-blue2.png" alt="" placeholder="none" loading="lazy" />
      </div>
      <div className="e-step-head-image-star4">
        <StaticImage src="../../../../../assets/images/course/high-school/support/common/star-blue4.png" alt="" placeholder="none" loading="lazy" />
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<Support1StepHeadImagePropsClassName>`
  text-align: center;

  > .e-step-head-image-teacher {
    img {
      width: ${rem(160 / 2)};

      @media ${breakpoints.lg} {
        width: ${rem(160)};
      }
    }
  }

  > .e-step-head-image-star1 {
    position: absolute;
    top: ${rem(24 / 2)};
    right: calc(50% + ${rem(90 / 2)});
    width: ${rem(30 / 2)};
    height: ${rem(30 / 2)};
    animation: highSchoolStar linear var(--star-speed) infinite forwards 0.7s;

    @media ${breakpoints.lg} {
      top: ${rem(24)};
      right: calc(50% + ${rem(90)});
      width: ${rem(30)};
      height: ${rem(30)};
    }
  }

  > .e-step-head-image-star2 {
    position: absolute;
    top: ${rem(60 / 2)};
    right: calc(50% + ${rem(68 / 2)});
    width: ${rem(20 / 2)};
    height: ${rem(20 / 2)};
    animation: highSchoolStar linear var(--star-speed) infinite forwards 0s;

    @media ${breakpoints.lg} {
      top: ${rem(60)};
      right: calc(50% + ${rem(68)});
      width: ${rem(20)};
      height: ${rem(20)};
    }
  }

  > .e-step-head-image-star3 {
    position: absolute;
    top: ${rem(0)};
    left: calc(50% + ${rem(66 / 2)});
    width: ${rem(20 / 2)};
    height: ${rem(20 / 2)};
    animation: highSchoolStar linear var(--star-speed) infinite forwards 1.4s;

    @media ${breakpoints.lg} {
      top: ${rem(0)};
      left: calc(50% + ${rem(66)});
      width: ${rem(20)};
      height: ${rem(20)};
    }
  }

  > .e-step-head-image-star4 {
    position: absolute;
    top: ${rem(30 / 2)};
    left: calc(50% + ${rem(94 / 2)});
    width: ${rem(30 / 2)};
    height: ${rem(30 / 2)};
    animation: highSchoolStar linear var(--star-speed) infinite forwards 2.1s;

    @media ${breakpoints.lg} {
      top: ${rem(30)};
      left: calc(50% + ${rem(94)});
      width: ${rem(30)};
      height: ${rem(30)};
    }
  }
`

export const Support1StepHeadImage: (props: Support1StepHeadImageProps) => JSX.Element = StyledComponent

export default Support1StepHeadImage
