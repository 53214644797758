import classNames from 'classnames'
import React from 'react'
import styled from 'styled-components'
import Support1Step1Section from './support1Step1Section'
import Support1Step2Section from './support1Step2Section'
import Support1Step3Section from './support1Step3Section'
import { useInView } from 'react-intersection-observer'
import { color, fontFamily, innerWidth, rem, breakpoints, innerPadding, innerDummyPadding } from 'variables/_index'

export interface Support1StepSectionProps {
  readonly noProps?: string
}

type Support1StepSectionPropsClassName = Support1StepSectionProps & {
  readonly className: string
}

function Component({ className }: Support1StepSectionPropsClassName) {
  const { ref, inView } = useInView({
    rootMargin: '-100px', // ref要素が現れてから100px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  return (
    <div className={className}>
      <h5 ref={ref} className={classNames('e-step-head', inView && 'is-view')}>
        <div className="e-step-head-line1"></div>
        <div className="e-step-head-line2"></div>
        <div className="e-step-head-line3"></div>
        <div className="e-step-head-text">コーチング3ステップ</div>
      </h5>
      <div className="e-inner">
        <div className="e-step-boxes">
          <Support1Step1Section />
          <div className="e-step-line"></div>
          <Support1Step2Section />
          <div className="e-step-line"></div>
          <Support1Step3Section />
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<Support1StepSectionPropsClassName>`
  > .e-step-head {
    position: relative;
    padding-top: ${rem(80 / 2)};
    margin-top: ${rem(-80 / 2)};
    background: #fff;
    border-radius: ${rem(10 / 2)};
    width: ${rem(550 / 2)};
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: ${rem(46 / 2)};
    font-weight: 700;
    letter-spacing: 0.1em;
    font-weight: 700;
    font-family: ${fontFamily.zenKaku};
    color: ${color.main};

    @media ${breakpoints.lg} {
      padding-top: ${rem(58)};
      margin-top: ${rem(-110)};
      height: ${rem(110)};
      border-radius: ${rem(20)} ${rem(20)} 0 0;
      width: ${rem(1000)};
      font-size: ${rem(36)};
      border-radius: ${rem(10)} ${rem(10)} 0 0;
    }

    > .e-step-head-line1,
    > .e-step-head-line2,
    > .e-step-head-line3 {
      width: 1.5px;
      height: ${rem(24 / 2)};
      position: absolute;
      top: ${rem(36 / 2)};
      border-radius: 3px;
      background: ${color.main};
      opacity: 0;

      @media ${breakpoints.lg} {
        width: 3px;
        height: ${rem(20)};
        top: ${rem(26)};
        border-radius: 3px;
      }
    }

    > .e-step-head-line1 {
      left: calc(50% - ${rem(1)});

      @media ${breakpoints.lg} {
        left: calc(50% - 1.5px);
      }
    }

    > .e-step-head-line2 {
      rotate: -24deg;
      right: calc(50% + ${rem(24 / 2)});

      @media ${breakpoints.lg} {
        right: calc(50% + 24px);
      }
    }

    > .e-step-head-line3 {
      rotate: 24deg;
      left: calc(50% + ${rem(24 / 2)});

      @media ${breakpoints.lg} {
        left: calc(50% + 24px);
      }
    }

    &.is-view {
      > .e-step-head-line1 {
        animation: highSchoolShow linear 0.4s forwards 0.4s;
      }

      > .e-step-head-line2 {
        animation: highSchoolShow linear 0.4s forwards 0s;
      }

      > .e-step-head-line3 {
        animation: highSchoolShow linear 0.4s forwards 0.8s;
      }
    }
  }

  > .e-inner {
    width: calc(${rem(375)} - ${rem(40)});
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${innerWidth.lg};
      padding-left: ${rem(innerPadding.lg + innerDummyPadding.lg)};
      padding-right: ${rem(innerPadding.lg + innerDummyPadding.lg)};
    }

    > .e-step-boxes {
      margin-top: ${rem(210 / 2)};

      @media ${breakpoints.lg} {
        margin-top: ${rem(250)};
      }

      > .e-step-box {
      }

      > .e-step-line {
        width: 2px;
        height: ${rem(130 / 2)};
        background: ${color.main};
        margin-top: ${rem(30 / 2)};
        margin-bottom: ${rem(130 / 2)};
        margin-left: ${rem(60 / 2)};

        @media ${breakpoints.lg} {
          width: 2px;
          height: ${rem(100)};
          margin-top: ${rem(40)};
          margin-bottom: ${rem(130)};
          margin-left: ${rem(60)};
        }
      }
    }
  }
`

export const Support1StepSection: (props: Support1StepSectionProps) => JSX.Element = StyledComponent

export default Support1StepSection
