import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { color, fontFamily, innerWidth, rem, breakpoints, innerPadding, innerDummyPadding } from 'variables/_index'
import { useInView } from 'react-intersection-observer'
import classNames from 'classnames'

export interface Support0SectionProps {
  readonly noProps?: string
}

type Support0SectionPropsClassName = Support0SectionProps & {
  readonly className: string
}

function Component({ className }: Support0SectionPropsClassName) {
  const { ref, inView } = useInView({
    rootMargin: '-100px', // ref要素が現れてから100px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  return (
    <div className={className}>
      <div className="e-inner">
        <h2 ref={ref} className={classNames('e-lead', inView && 'is-view')}>
          受験<span className="is-small">の</span>プロ<span className="is-small">が</span>
          <br />
          <span className="e-lead-middle">
            <span className="e-line">
              <span className="e-line-in">
                行きたい大学<span className="is-small">の</span>合格
              </span>
            </span>
          </span>
          <span className="is-small">までを</span>
          <br className="u-hidden-pc" />
          サポート
        </h2>
        <div className="e-calc">
          <div className="e-calc-box">
            <div className="c-support-item">
              <div className="c-support-item__head">
                <StaticImage
                  src="../../../../../assets/images/course/high-school/support/support0/support1-text.png"
                  alt=""
                  placeholder="none"
                  loading="lazy"
                />
              </div>
              <div className="c-support-item__star1-1">
                <StaticImage
                  src="../../../../../assets/images/course/high-school/support/support0/star1.png"
                  alt=""
                  placeholder="none"
                  loading="lazy"
                />
              </div>
              <div className="c-support-item__star1-2">
                <StaticImage
                  src="../../../../../assets/images/course/high-school/support/support0/star2.png"
                  alt=""
                  placeholder="none"
                  loading="lazy"
                />
              </div>
              <div className="c-support-item__star1-3">
                <StaticImage
                  src="../../../../../assets/images/course/high-school/support/support0/star3.png"
                  alt=""
                  placeholder="none"
                  loading="lazy"
                />
              </div>
              <div className="c-support-item__box">
                自分の個性を最大限生かせる
                <br className="u-hidden-pc" />
                大学・受験方式が見つかる
              </div>
            </div>
          </div>
          <div className="e-calc-plus">
            <StaticImage src="../../../../../assets/images/course/high-school/support/support0/plus.png" alt="" placeholder="none" loading="lazy" />
          </div>
          <div className="e-calc-box">
            <div className="c-support-item">
              <div className="c-support-item__head">
                <StaticImage
                  src="../../../../../assets/images/course/high-school/support/support0/support2-text.png"
                  alt=""
                  placeholder="none"
                  loading="lazy"
                />
              </div>
              <div className="c-support-item__star2-1">
                <StaticImage
                  src="../../../../../assets/images/course/high-school/support/support0/star1.png"
                  alt=""
                  placeholder="none"
                  loading="lazy"
                />
              </div>
              <div className="c-support-item__star2-2">
                <StaticImage
                  src="../../../../../assets/images/course/high-school/support/support0/star2.png"
                  alt=""
                  placeholder="none"
                  loading="lazy"
                />
              </div>
              <div className="c-support-item__star2-3">
                <StaticImage
                  src="../../../../../assets/images/course/high-school/support/support0/star3.png"
                  alt=""
                  placeholder="none"
                  loading="lazy"
                />
              </div>
              <div className="c-support-item__box">
                どの受験方式でも必要な
                <br className="u-hidden-pc" />
                基礎学力と定期テスト対策
              </div>
            </div>
          </div>
          <div className="e-calc-arrow">
            <StaticImage src="../../../../../assets/images/course/high-school/support/support0/arrow.png" alt="" placeholder="none" loading="lazy" />
          </div>
          <div className="e-calc-result">
            <div className="e-calc-result-image">
              <StaticImage src="../../../../../assets/images/course/high-school/support/support0/girl.png" alt="" placeholder="none" loading="lazy" />
            </div>
            <div className="e-calc-result-star1">
              <StaticImage
                src="../../../../../assets/images/course/high-school/support/support0/star1.png"
                alt=""
                placeholder="none"
                loading="lazy"
              />
            </div>
            <div className="e-calc-result-star2">
              <StaticImage
                src="../../../../../assets/images/course/high-school/support/support0/star3.png"
                alt=""
                placeholder="none"
                loading="lazy"
              />
            </div>
            <div className="e-calc-result-star3">
              <StaticImage
                src="../../../../../assets/images/course/high-school/support/support0/star2.png"
                alt=""
                placeholder="none"
                loading="lazy"
              />
            </div>
            <div className="e-calc-result-star4">
              <StaticImage
                src="../../../../../assets/images/course/high-school/support/support0/star3.png"
                alt=""
                placeholder="none"
                loading="lazy"
              />
            </div>
            <div className="e-calc-result-star5">
              <StaticImage
                src="../../../../../assets/images/course/high-school/support/support0/star2.png"
                alt=""
                placeholder="none"
                loading="lazy"
              />
            </div>
            <div className="e-calc-result-box">
              行きたい大学へ<span className="is-em">合格</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<Support0SectionPropsClassName>`
  padding-top: ${rem(100 / 2)};
  padding-bottom: ${rem(100 / 2)};
  background: #edfbff;

  @media ${breakpoints.lg} {
    padding-top: ${rem(100)};
    padding-bottom: ${rem(100)};
  }

  @keyframes highSchoolSupport0Catch {
    0% {
      opacity: 0;
      translate: -100% ${rem(10)};
    }

    100% {
      opacity: 1;
      translate: 0 ${rem(10)};
    }
  }

  @keyframes highSchoolSupport0Catch {
    0% {
      opacity: 0;
      translate: -100% ${rem(10)};
    }

    100% {
      opacity: 1;
      translate: 0 ${rem(10)};
    }
  }

  @keyframes highSchoolSupport0CatchIn {
    0% {
      opacity: 0;
      translate: 100% 0;
    }

    100% {
      opacity: 1;
      translate: 0 0;
    }
  }

  .c-support-item {
    position: relative;
    display: inline-block;

    &__head {
      position: absolute;
      bottom: calc(100% + ${rem(10 / 2)});
      left: ${rem(-54 / 2)};

      @media ${breakpoints.lg} {
        bottom: calc(100% + ${rem(8)});
        left: ${rem(-20)};
      }

      img {
        width: ${rem(250 / 2)};

        @media ${breakpoints.lg} {
          width: ${rem(200)};
        }
      }
    }

    &__box {
      padding: ${rem(18 / 2)} ${rem(4)};
      background: #5cdcff;
      width: ${rem(600 / 2)};
      max-width: 100%;
      border-radius: ${rem(10 / 2)};
      color: #fff;
      text-align: center;
      font-family: ${fontFamily.zenKaku};
      font-size: ${rem(38 / 2)};
      font-weight: 700;
      letter-spacing: 0.05em;
      line-height: 1.5;

      @media ${breakpoints.lg} {
        padding-top: ${rem(16)};
        padding-bottom: ${rem(15)};
        width: ${rem(800)};
        max-width: 100%;
        border-radius: ${rem(10)};
        font-size: ${rem(26)};
      }
    }

    &__star1-1,
    &__star2-1 {
      position: absolute;
      left: ${rem(184 / 2)};
      bottom: calc(100% + ${rem(45 / 2)});
      width: ${rem(25 / 2)};
      height: ${rem(25 / 2)};

      @media ${breakpoints.lg} {
        width: ${rem(20)};
        height: ${rem(20)};
        left: ${rem(184)};
        bottom: calc(100% + ${rem(45)});
      }

      img {
      }
    }

    &__star1-1 {
      animation: highSchoolStar linear var(--star-speed) infinite forwards 1.4s;
    }

    &__star2-1 {
      left: ${rem(204 / 2)};
      animation: highSchoolStar linear var(--star-speed) infinite forwards 0s;

      @media ${breakpoints.lg} {
        left: ${rem(204)};
      }
    }

    &__star1-2,
    &__star2-2 {
      position: absolute;
      left: ${rem(162 / 2)};
      bottom: calc(100% + ${rem(15 / 2)});
      width: ${rem(20 / 2)};
      height: ${rem(20 / 2)};

      @media ${breakpoints.lg} {
        width: ${rem(16)};
        height: ${rem(16)};
        left: ${rem(162)};
        bottom: calc(100% + ${rem(15)});
      }

      img {
      }
    }

    &__star1-2 {
      animation: highSchoolStar linear var(--star-speed) infinite forwards 0.8s;
    }

    &__star2-2 {
      left: ${rem(182 / 2)};
      animation: highSchoolStar linear var(--star-speed) infinite forwards 0.2s;

      @media ${breakpoints.lg} {
        left: ${rem(182)};
      }
    }

    &__star1-3,
    &__star2-3 {
      position: absolute;
      top: ${rem(20 / 2)};
      width: ${rem(30 / 2)};
      height: ${rem(30 / 2)};

      @media ${breakpoints.lg} {
        top: ${rem(20)};
        width: ${rem(24)};
        height: ${rem(24)};
      }

      img {
      }
    }

    &__star1-3 {
      left: ${rem(-40 / 2)};
      animation: highSchoolStar linear var(--star-speed) infinite forwards 0.1s;

      @media ${breakpoints.lg} {
        left: ${rem(-40)};
      }
    }

    &__star2-3 {
      left: ${rem(20 / 2)};
      animation: highSchoolStar linear var(--star-speed) infinite forwards 0.5s;

      @media ${breakpoints.lg} {
        left: ${rem(20)};
      }
    }
  }

  > .e-inner {
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${innerWidth.lg};
      padding-left: ${rem(innerPadding.lg + innerDummyPadding.lg)};
      padding-right: ${rem(innerPadding.lg + innerDummyPadding.lg)};
    }

    > .e-lead {
      font-size: ${rem(52 / 2)};
      font-weight: 700;
      font-family: ${fontFamily.zenKaku};
      letter-spacing: 0.05em;
      text-align: center;
      line-height: 1;

      @media ${breakpoints.lg} {
        font-size: ${rem(46)};
      }

      .e-lead-middle {
        display: inline-block;
        margin-top: ${rem(8 / 2)};
        margin-bottom: ${rem(30 / 2)};

        @media ${breakpoints.lg} {
          display: inline-block;
          margin-top: ${rem(10)};
          margin-bottom: ${rem(0)};
        }
      }

      .is-small {
        font-size: ${rem(42 / 2)};

        @media ${breakpoints.lg} {
          font-size: ${rem(36)};
        }
      }

      .e-line {
        width: fit-content;
        overflow: hidden;
        translate: -100% ${rem(10)};
        margin-right: ${rem(10 / 2)};
        display: inline-block;

        .e-line-in {
          width: fit-content;
          display: inline-block;
          padding: ${rem(7)} ${rem(10)} ${rem(9)};
          background: ${color.main};
          color: #fff;
          font-family: ${fontFamily.zenKaku};
          font-weight: 700;
          translate: 100% 0;
        }
      }

      &.is-view {
        .e-line {
          animation: highSchoolSupport0Catch linear 0.6s forwards 0s;

          .e-line-in {
            animation: highSchoolSupport0CatchIn linear 0.6s forwards 0s;
          }
        }
      }
    }

    > .e-calc {
      margin-top: ${rem(100 / 2)};
      text-align: center;

      @media ${breakpoints.lg} {
        margin-top: ${rem(120)};
      }

      > .e-calc-box {
      }

      > .e-calc-plus {
        margin-top: ${rem(30 / 2)};
        margin-bottom: ${rem(30 / 2)};

        @media ${breakpoints.lg} {
          margin-top: ${rem(30)};
          margin-bottom: ${rem(30)};
        }

        img {
          width: ${rem(110 / 2)};

          @media ${breakpoints.lg} {
            width: ${rem(78)};
          }
        }
      }

      > .e-calc-arrow {
        margin-top: ${rem(30 / 2)};

        @media ${breakpoints.lg} {
          margin-top: ${rem(30)};
        }

        img {
          width: ${rem(120 / 2)};

          @media ${breakpoints.lg} {
            width: ${rem(78)};
          }
        }
      }

      > .e-calc-result {
        position: relative;
        margin-top: ${rem(232 / 2)};

        @media ${breakpoints.lg} {
          margin-top: ${rem(210)};
        }

        > .e-calc-result-image {
          width: ${rem(450 / 2)};
          position: absolute;
          bottom: 100%;
          left: 50%;
          transform: translateX(-50%);

          @media ${breakpoints.lg} {
            width: ${rem(356)};
          }

          img {
            width: 100%;
          }
        }

        > .e-calc-result-star1 {
          position: absolute;
          right: calc(50% + ${rem(200 / 2)});
          bottom: calc(100% + ${rem(60 / 2)});
          width: ${rem(25 / 2)};
          height: ${rem(25 / 2)};
          animation: highSchoolStar linear var(--star-speed) infinite forwards 0.7s;

          @media ${breakpoints.lg} {
            width: ${rem(20)};
            height: ${rem(20)};
            right: calc(50% + ${rem(154)});
            bottom: calc(100% + ${rem(48)});
          }

          img {
          }
        }

        > .e-calc-result-star2 {
          position: absolute;
          right: calc(50% + ${rem(144 / 2)});
          bottom: calc(100% + ${rem(90 / 2)});
          width: ${rem(30 / 2)};
          height: ${rem(30 / 2)};
          animation: highSchoolStar linear var(--star-speed) infinite forwards 0s;

          @media ${breakpoints.lg} {
            width: ${rem(24)};
            height: ${rem(24)};
            right: calc(50% + ${rem(112)});
            bottom: calc(100% + ${rem(78)});
          }

          img {
          }
        }

        > .e-calc-result-star3 {
          position: absolute;
          right: calc(50% + ${rem(114 / 2)});
          bottom: calc(100% + ${rem(50 / 2)});
          width: ${rem(20 / 2)};
          height: ${rem(20 / 2)};
          animation: highSchoolStar linear var(--star-speed) infinite forwards 1.4s;

          @media ${breakpoints.lg} {
            width: ${rem(16)};
            height: ${rem(16)};
            right: calc(50% + ${rem(88)});
            bottom: calc(100% + ${rem(38)});
          }

          img {
          }
        }

        > .e-calc-result-star4 {
          position: absolute;
          left: calc(50% + ${rem(135 / 2)});
          bottom: calc(100% + ${rem(60 / 2)});
          width: ${rem(30 / 2)};
          height: ${rem(30 / 2)};
          animation: highSchoolStar linear var(--star-speed) infinite forwards 2.1s;

          @media ${breakpoints.lg} {
            width: ${rem(24)};
            height: ${rem(24)};
            left: calc(50% + ${rem(112)});
            bottom: calc(100% + ${rem(48)});
          }

          img {
          }
        }

        > .e-calc-result-star5 {
          position: absolute;
          left: calc(50% + ${rem(185 / 2)});
          bottom: calc(100% + ${rem(110 / 2)});
          width: ${rem(20 / 2)};
          height: ${rem(20 / 2)};
          animation: highSchoolStar linear var(--star-speed) infinite forwards 1s;

          @media ${breakpoints.lg} {
            width: ${rem(16)};
            height: ${rem(16)};
            left: calc(50% + ${rem(152)});
            bottom: calc(100% + ${rem(88)});
          }

          img {
          }
        }

        > .e-calc-result-box {
          display: flex;
          align-items: baseline;
          justify-content: center;
          padding: ${rem(12 / 2)} ${rem(4)};
          background: #5cdcff;
          width: ${rem(600 / 2)};
          margin-left: auto;
          margin-right: auto;
          max-width: 100%;
          border-radius: ${rem(10 / 2)};
          color: #fff;
          text-align: center;
          font-family: ${fontFamily.zenKaku};
          font-size: ${rem(38 / 2)};
          font-weight: 700;
          letter-spacing: 0.1em;
          text-indent: 0.1em;
          line-height: 1.5;

          @media ${breakpoints.lg} {
            width: ${rem(880)};
            font-size: ${rem(29)};
            padding-top: ${rem(5)};
            padding-bottom: ${rem(5)};
            border-radius: ${rem(10)};
          }

          .is-em {
            color: #fff000;
            font-size: ${rem(52 / 2)};
            font-family: ${fontFamily.zenKaku};

            @media ${breakpoints.lg} {
              font-size: ${rem(40)};
            }
          }

          &::before,
          &::after {
            content: '';
            background: #fff;
            width: 1.5px;
            height: ${rem(56 / 2)};
            translate: 0 ${rem(3)};

            @media ${breakpoints.lg} {
              width: 2px;
              height: ${rem(42)};
              translate: 0 ${rem(5)};
            }
          }

          &::before {
            rotate: -24deg;
            margin-right: ${rem(18 / 2)};

            @media ${breakpoints.lg} {
              margin-right: ${rem(18)};
            }
          }

          &::after {
            rotate: 24deg;
            margin-left: ${rem(18 / 2)};

            @media ${breakpoints.lg} {
              margin-left: ${rem(18)};
            }
          }
        }
      }
    }
  }
`

export const Support0Section: (props: Support0SectionProps) => JSX.Element = StyledComponent

export default Support0Section
