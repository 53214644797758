import React from 'react'
import styled from 'styled-components'
import { breakpoints, innerWidth, rem, innerPadding, innerDummyPadding, fontFamily, color } from 'variables/_index'
import { Pagination, Navigation, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/autoplay'
import { StaticImage } from 'gatsby-plugin-image'
import VoiceCard from 'components/section/course/high-school/atom/VoiceCard'
import ArrowNextImage from 'assets/images/course/high-school/voice/slide-arrow-next.svg'
import ArrowPrevImage from 'assets/images/course/high-school/voice/slide-arrow-prev.svg'
import RibbonLeftImage from 'assets/images/course/high-school/voice/ribbon-left.svg'
import RibbonRightImage from 'assets/images/course/high-school/voice/ribbon-right.svg'

export interface VoiceSectionProps {
  readonly npProps?: string
}

type VoiceSectionPropsClassName = VoiceSectionProps & {
  readonly className: string
}

function Component({ className }: VoiceSectionPropsClassName) {
  return (
    <div className={className}>
      <div className="e-inner">
        <h2 className="e-head">
          2024大学入試 合格実績
          <span className="e-stars">
            <span className="e-star1">
              <StaticImage src="../../../../assets/images/course/high-school/voice/star1.svg" alt="" placeholder="none" />
            </span>
            <span className="e-star2">
              <StaticImage src="../../../../assets/images/course/high-school/voice/star2.svg" alt="" placeholder="none" />
            </span>
            <span className="e-star3">
              <StaticImage src="../../../../assets/images/course/high-school/voice/star3.svg" alt="" placeholder="none" />
            </span>
          </span>
        </h2>
        <p className="e-pop">
          <span className="is-em">「大学に合格できた！」</span>の声、続々！
        </p>
        <div className="e-slide">
          <Swiper
            modules={[Pagination, Navigation, Autoplay]}
            spaceBetween={25}
            slidesPerView={1.6}
            centeredSlides={true}
            pagination={{ clickable: true, type: 'fraction' }}
            navigation={true}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
                centeredSlides: false,
              },
            }}
          >
            <SwiperSlide>
              <VoiceCard
                university="島根大学"
                imageElement={<StaticImage src="../../../../assets/images/course/high-school/voice/voice0.jpg" alt="" placeholder="none" />}
                faculty="法文学部"
                name="K.Nさん"
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                university="高知大学"
                imageElement={<StaticImage src="../../../../assets/images/course/high-school/voice/voice1.jpg" alt="" placeholder="none" />}
                faculty="農林海洋科学部"
                name="K.Kさん"
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                university="長崎大学"
                imageElement={<StaticImage src="../../../../assets/images/course/high-school/voice/voice2.jpg" alt="" placeholder="none" />}
                faculty="工学部"
                name="K.Hさん"
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                university="大分大学"
                imageElement={<StaticImage src="../../../../assets/images/course/high-school/voice/voice3.jpg" alt="" placeholder="none" />}
                faculty="経済学部"
                name="R.Oさん"
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                university="前橋工科大学"
                imageElement={<StaticImage src="../../../../assets/images/course/high-school/voice/voice4.png" alt="" placeholder="none" />}
                faculty="建築・都市・環境工学群"
                name="Y.Sさん"
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                university="新潟大学"
                imageElement={<StaticImage src="../../../../assets/images/course/high-school/voice/voice5.png" alt="" placeholder="none" />}
                faculty="医学部"
                name="H.Kさん"
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                university="信州大学"
                imageElement={<StaticImage src="../../../../assets/images/course/high-school/voice/voice6.png" alt="" placeholder="none" />}
                faculty="工学部"
                name="K.Mさん"
              />
            </SwiperSlide>
          </Swiper>
        </div>
        <p className="e-attention">
          他にも多くの塾生が、
          <br />
          <span className="is-em">国公立・難関私立大学に合格！</span>
        </p>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<VoiceSectionPropsClassName>`
  padding-top: ${rem(56)};
  padding-bottom: ${rem(46)};
  overflow: hidden;
  background: linear-gradient(rgb(255, 255, 255) ${rem(65)}, rgb(237, 251, 255) 0px);

  @media ${breakpoints.lg} {
    padding-top: ${rem(68)};
    padding-bottom: ${rem(66)};
    background: linear-gradient(rgb(255, 255, 255) ${rem(103)}, rgb(237, 251, 255) 0px);
  }

  > .e-inner {
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${innerWidth.lg};
      padding-left: ${rem(innerPadding.lg + innerDummyPadding.lg)};
      padding-right: ${rem(innerPadding.lg + innerDummyPadding.lg)};
    }

    > .e-head {
      font-family: ${fontFamily.zenKaku};
      font-weight: 700;
      font-size: ${rem(46 / 2)};
      letter-spacing: 0.05em;
      color: #fff;
      background: #ff8c00;
      padding-top: ${rem(6)};
      padding-bottom: ${rem(6)};
      padding-left: ${rem(5)};
      padding-right: ${rem(5)};
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      position: relative;

      @media ${breakpoints.lg} {
        font-size: ${rem(38)};
        padding-top: ${rem(10)};
        padding-bottom: ${rem(10)};
        padding-left: ${rem(16)};
        padding-right: ${rem(16)};
      }

      &::before {
        content: '';
        background: url(${RibbonLeftImage});
        background-size: 100% 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 100%;
        aspect-ratio: 30 / 75;
        margin-right: -1px;
      }

      &::after {
        content: '';
        background: url(${RibbonRightImage});
        background-size: 100% 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 100%;
        aspect-ratio: 30 / 75;
        margin-left: -1px;
      }

      > .e-stars {
        > .e-star1 {
          display: block;
          position: absolute;
          bottom: calc(100% + ${rem(16)});
          left: ${rem(-15)};
          width: ${rem(24.2 / 2)};
          height: ${rem(24.2 / 2)};
          animation: highSchoolStar linear var(--star-speed) infinite forwards 1.4s;

          @media ${breakpoints.lg} {
            width: ${rem(20.66)};
            height: ${rem(20.66)};
            left: ${rem(-24)};
            bottom: calc(100% + ${rem(28)});
          }
        }

        > .e-star2 {
          display: block;
          position: absolute;
          bottom: calc(100% + ${rem(6)});
          right: ${rem(4)};
          width: ${rem(29.85 / 2)};
          height: ${rem(29.85 / 2)};
          animation: highSchoolStar linear var(--star-speed) infinite forwards 0.6s;

          @media ${breakpoints.lg} {
            width: ${rem(25.48)};
            height: ${rem(25.48)};
            bottom: calc(100% + ${rem(12)});
            right: ${rem(6)};
          }
        }

        > .e-star3 {
          display: block;
          position: absolute;
          bottom: calc(100% + ${rem(14)});
          right: ${rem(-8)};
          width: ${rem(24.2 / 2)};
          height: ${rem(24.2 / 2)};
          animation: highSchoolStar linear var(--star-speed) infinite forwards 0.1s;

          @media ${breakpoints.lg} {
            width: ${rem(20.66)};
            height: ${rem(20.66)};
            bottom: calc(100% + ${rem(25)});
            right: ${rem(-14)};
          }
        }
      }
    }

    > .e-pop {
      font-family: ${fontFamily.zenKaku};
      font-size: ${rem(30 / 2)};
      font-weight: 500;
      letter-spacing: 0.05em;
      text-align: center;
      margin: ${rem(24)} 0 0;
      position: relative;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;

      @media ${breakpoints.lg} {
        font-size: ${rem(24)};
        margin-top: ${rem(46)};
      }

      .is-em {
        font-size: ${rem(36 / 2)};
        font-weight: 700;
        color: #ff8c00;
        background: linear-gradient(to right, transparent ${rem(6)}, #fff 0, #fff calc(100% - ${rem(6)}), transparent 0);
        position: relative;

        @media ${breakpoints.lg} {
          font-size: ${rem(32)};
          background: linear-gradient(to right, transparent ${rem(10)}, #fff 0, #fff calc(100% - ${rem(10)}), transparent 0);
        }
      }

      &::before,
      &::after {
        content: '';
        background: #333;
        width: 1.5px;
        height: ${rem(30)};
        top: 50%;
        translate: 0 -50%;
        position: absolute;

        @media ${breakpoints.lg} {
          width: ${rem(3)};
          height: ${rem(42)};
        }
      }

      &::before {
        rotate: -20deg;
        right: calc(100% + ${rem(8)});
      }

      &::after {
        rotate: 20deg;
        left: calc(100% + ${rem(8)});
      }
    }

    > .e-slide {
      margin-top: ${rem(12)};
      width: 100vw;
      margin-left: calc(50% - 50vw);
      margin-right: calc(50% - 50vw);

      @media ${breakpoints.lg} {
        width: ${rem(900)};
        margin-left: auto;
        margin-right: auto;
        margin-top: ${rem(30)};
        padding-left: ${rem(30)};
        padding-right: ${rem(30)};
        overflow: hidden;
      }

      .swiper {
        padding-top: ${rem(10)};
        padding-bottom: ${rem(30)};

        @media ${breakpoints.lg} {
          padding-bottom: ${rem(50)};
          overflow: visible;
        }
      }

      .swiper-slide {
        height: auto;

        > [class^='voiceCard'] {
          height: 100%;
        }
      }

      .swiper-button-prev,
      .swiper-button-next {
        width: ${rem(90 / 2)};
        height: ${rem(90 / 2)};
        margin-top: ${rem(-34)};

        @media ${breakpoints.lg} {
          width: ${rem(60)};
          height: ${rem(60)};
          margin-top: ${rem(-50)};
        }
      }

      .swiper-button-prev::after,
      .swiper-button-next::after {
        display: none;
      }

      .swiper-button-prev {
        background-image: url(${ArrowPrevImage});
        background-repeat: no-repeat;
        background-size: contain;
        left: auto;
        right: calc(50% + calc(100 / 375 * 100vw)); // 28.533vw

        @media ${breakpoints.lg} {
          right: auto;
          left: ${rem(-30)};
        }
      }

      .swiper-button-next {
        background-image: url(${ArrowNextImage});
        background-repeat: no-repeat;
        background-size: contain;
        right: auto;
        left: calc(50% + calc(100 / 375 * 100vw)); // 28.533vw

        @media ${breakpoints.lg} {
          left: auto;
          right: ${rem(-30)};
        }
      }

      .swiper-pagination {
        bottom: ${rem(0)};
        font-size: ${rem(14)};

        @media ${breakpoints.lg} {
          font-size: ${rem(18)};
        }
      }

      .swiper-pagination-bullet {
        width: ${rem(24 / 2)};
        height: ${rem(24 / 2)};
        background-color: #fff;
        opacity: 1;
        margin-right: ${rem(12 / 2)};
        margin-left: ${rem(12 / 2)};

        @media ${breakpoints.lg} {
          width: ${rem(8)};
          height: ${rem(8)};
          margin-right: ${rem(6)};
          margin-left: ${rem(6)};
        }

        &.swiper-pagination-bullet-active {
          background-color: #ffe10a;
        }
      }
    }

    > .e-attention {
      margin: ${rem(10)} 0 0;
      font-family: ${fontFamily.zenKaku};
      font-weight: 500;
      font-size: ${rem(32 / 2)};
      letter-spacing: 0.05em;
      text-align: center;
      line-height: calc(64 / 42);

      @media ${breakpoints.lg} {
        font-size: ${rem(24)};
        margin-top: ${rem(22)};
      }

      .is-em {
        font-size: ${rem(42 / 2)};
        color: #00c8ff;
        border-bottom: 1.5px solid #00c8ff;

        @media ${breakpoints.lg} {
          font-size: ${rem(36)};
          border-width: ${rem(3)};
        }
      }
    }
  }
`

export const VoiceSection: (props: VoiceSectionProps) => JSX.Element = StyledComponent

export default VoiceSection
