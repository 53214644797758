import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { color, fontFamily, innerWidth, rem, breakpoints } from 'variables/_index'
import Support0Section from './support/support0Section'
import Support1Section from './support/support1Section'
import Support2Section from './support/support2Section'
import CtaSection from './ctaSection'
import Support2Voice from './support/support2Voice'

export interface SupportSectionProps {
  readonly noProps?: string
}

type SupportSectionPropsClassName = SupportSectionProps & {
  readonly className: string
}

function Component({ className }: SupportSectionPropsClassName) {
  return (
    <div className={className}>
      <div className="e-head-section">
        <Support0Section />
      </div>
      <div className="e-support1-section">
        <Support1Section />
      </div>
      <Support2Voice />
      <CtaSection id="high-school-support-contact" isTopNoSpace={true} />
      <div className="e-support2-section">
        <Support2Section />
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<SupportSectionPropsClassName>`
  > .e-head-section {
  }

  > .e-support1-section {
  }

  > .e-support2-section {
  }
`

export const SupportSection: (props: SupportSectionProps) => JSX.Element = StyledComponent

export default SupportSection
