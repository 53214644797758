import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { color, fontFamily, innerWidth, rem, breakpoints, innerDummyPadding, innerPadding } from 'variables/_index'
import ButtonExperienceType2 from 'components/atoms/buttonExperienceType2'
import { siteStructure } from 'utils/site'
import classNames from 'classnames'

export interface CtaSectionProps {
  readonly id: string
  readonly isTopNoSpace?: boolean
}

type CtaSectionPropsClassName = CtaSectionProps & {
  readonly className: string
}

function Component({ className, id, isTopNoSpace }: CtaSectionPropsClassName) {
  return (
    <div className={classNames(className, isTopNoSpace && 'is-top-no-space')}>
      <div className="e-image">
        <StaticImage src="../../../../assets/images/course/high-school/cta/girl.png" alt="" placeholder="none" />
      </div>
      <div className="e-wrap">
        <div className="e-inner">
          <div className="e-box">
            <div className="e-pop">
              <span className="is-line">
                実際<span className="is-small">の</span>授業
              </span>
              <span className="is-small">と</span>
              <span className="is-line">コーチング</span>
              <span className="is-small">を</span>
              <br className="u-hidden-pc" />
              無料<span className="is-small">で</span>
              体験!
            </div>
            <div className="e-lead">
              ｜<span className="is-em">最</span>
              <span className="is-em">短</span>
              <span className="is-em">翌</span>
              <span className="is-em">日</span>｜<span className="is-em">ス</span>
              <span className="is-em">マ</span>
              <span className="is-em">ホ</span>でもOK｜
            </div>
            <div className="e-button">
              <ButtonExperienceType2
                id={id}
                href={siteStructure.experience1.path}
                imageElement={<StaticImage src="../../../../assets/images/course/high-school/cta/illust.png" alt="" placeholder="none" />}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<CtaSectionPropsClassName>`
  padding-top: ${rem(56 / 2)};
  position: relative;

  @media ${breakpoints.lg} {
    padding-top: ${rem(56)};
  }

  &.is-top-no-space {
    margin-top: ${rem(-56 / 2)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(-56)};
    }
  }

  > .e-image {
    text-align: center;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;

    img {
      width: ${rem(265 / 2)};
      height: auto;

      @media ${breakpoints.lg} {
        width: ${rem(265)};
      }
    }
  }

  > .e-wrap {
    padding-top: ${rem(160 / 2)};
    padding-bottom: ${rem(130 / 2)};
    background-color: ${color.main};
    text-align: center;

    @media ${breakpoints.lg} {
      padding-top: ${rem(160)};
      padding-bottom: ${rem(110)};
    }

    > .e-inner {
      width: ${rem(375 - 40)};
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;

      @media ${breakpoints.lg} {
        width: ${innerWidth.lg};
        padding-left: ${rem(innerPadding.lg + innerDummyPadding.lg)};
        padding-right: ${rem(innerPadding.lg + innerDummyPadding.lg)};
      }

      > .e-box {
        display: inline-block;
        padding: ${rem(30 / 2)} ${rem(40 / 2)} ${rem(48)};
        border: 2px solid #ffffff;
        border-radius: ${rem(20 / 2)};
        position: relative;

        @media ${breakpoints.lg} {
          padding: ${rem(16)} ${rem(64)} ${rem(66)};
          border-radius: ${rem(20)};
          border-width: 3px;
        }

        > .e-pop {
          font-size: ${rem(46 / 2)};
          font-family: ${fontFamily.zenKaku};
          font-weight: 700;
          letter-spacing: 0.1em;
          line-height: 1.6;
          white-space: nowrap;
          color: #fff;

          @media ${breakpoints.lg} {
            font-size: ${rem(40)};
          }

          .is-small {
            font-size: ${rem(34 / 2)};

            @media ${breakpoints.lg} {
              font-size: ${rem(30)};
            }
          }

          .is-line {
            position: relative;

            &::after {
              content: '';
              width: 100%;
              height: 1px;
              bottom: 0;
              left: 0;
              position: absolute;
              background: #fff;

              @media ${breakpoints.lg} {
                height: 2px;
              }
            }
          }
        }

        > .e-lead {
          margin-top: ${rem(14)};
          font-weight: 700;
          font-size: ${rem(34 / 2)};
          color: #fff000;
          white-space: nowrap;
          letter-spacing: 0.15em;
          line-height: 1;

          @media ${breakpoints.lg} {
            margin-top: ${rem(20)};
            font-size: ${rem(24)};
            letter-spacing: 0.4em;
          }

          > .is-em {
            position: relative;

            &::before {
              content: '';
              position: absolute;
              bottom: calc(100% + ${rem(-1)});
              left: 50%;
              margin-left: ${rem(-8 / 2 / 2)};
              border-radius: 50%;
              transform: translateX(-0.07em);
              width: ${rem(8 / 2)};
              height: ${rem(8 / 2)};
              background-color: currentColor;

              @media ${breakpoints.lg} {
                bottom: calc(100% + ${rem(-2)});
                margin-left: ${rem(-5 / 2)};
                width: ${rem(5)};
                height: ${rem(5)};
                transform: translateX(-0.2em);
              }
            }
          }
        }

        > .e-button {
          position: absolute;
          bottom: ${rem(-35)};
          left: 50%;
          transform: translateX(-50%);

          @media ${breakpoints.lg} {
            bottom: ${rem(-50)};
          }
        }
      }
    }
  }
`

export const CtaSection: (props: CtaSectionProps) => JSX.Element = StyledComponent

export default CtaSection
