import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { color, fontFamily, innerWidth, rem, breakpoints, innerPadding, innerDummyPadding } from 'variables/_index'
import Support2PointHead from './support2PointHead'

export interface Support2Point1SectionProps {
  readonly noProps?: string
}

type Support2Point1SectionPropsClassName = Support2Point1SectionProps & {
  readonly className: string
}

function Component({ className }: Support2Point1SectionPropsClassName) {
  return (
    <div className={className}>
      <Support2PointHead
        text="定期テスト対策"
        numberImageSVG={
          <svg height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg">
            <path
              d="m764.654 404.911c-2.249 1.174-4.6 2.641-6.944 3.032-5.966 1.174-6.064-4.694-12.91-3.325a37.287 37.287 0 0 0 -7.726 3.032 12.812 12.812 0 0 1 -3.325 1.271c-.978.2-2.249.2-2.249-1.174a7.84 7.84 0 0 1 .88-2.934c.2-.489 2.347-1.76 2.836-2.054a52 52 0 0 0 10.562-10.66l4.4-5.477c1.663-2.152 7.628-8.509 7.628-11.345 0-2.054-1.565-2.054-2.934-1.76-2.152.391-8.411 3.032-10.073 4.792a1.5 1.5 0 0 1 -.685.489c-1.076.2-2.054-1.076-2.054-2.249 0-1.369 1.174-1.956 2.152-2.249 3.325-1.271 6.259-3.423 9.78-4.108 2.738-.587 8.411-.88 8.411 3.227 0 2.934-2.641 6.846-4.3 9.1-1.565 2.054-13.79 16.626-13.79 17.408 0 .391 1.174.293 1.956.1l.685-.1a6.186 6.186 0 0 1 4.792.685c2.249 1.565 3.619 3.13 6.65 2.543 4.694-.978 11.051-7.433 12.029-7.628.685-.1 1.076.88 1.076 1.467-.101 3.516-4.501 6.646-6.847 7.917z"
              fill="#00c8ff"
              transform="translate(-731.5 -369.854)"
            />
            <path d="m0 0h40v40h-40z" fill="none" />
          </svg>
        }
      />
      <div className="e-image">
        <div className="e-image-student">
          <StaticImage
            src="../../../../../assets/images/course/high-school/support/support2/point2/student2.png"
            alt=""
            placeholder="none"
            loading="lazy"
          />
        </div>
        <div className="e-image-star1-1">
          <StaticImage
            src="../../../../../assets/images/course/high-school/support/support2/star-blue3.png"
            alt=""
            placeholder="none"
            loading="lazy"
          />
        </div>
        <div className="e-image-star1-2">
          <StaticImage src="../../../../../assets/images/course/high-school/support/common/star-blue1.png" alt="" placeholder="none" loading="lazy" />
        </div>
        <div className="e-image-star1-3">
          <StaticImage src="../../../../../assets/images/course/high-school/support/common/star-blue2.png" alt="" placeholder="none" loading="lazy" />
        </div>
        <div className="e-image-star1-4">
          <StaticImage src="../../../../../assets/images/course/high-school/support/common/star-blue4.png" alt="" placeholder="none" loading="lazy" />
        </div>
      </div>
      <div className="e-box">
        <div className="e-box-text">
          <span className="is-em">定期テストの1週間前からは、復習型授業にチェンジ。</span>
          <span className="is-em">テスト範囲すべての単元を復習</span>し苦手克服ができるから、テストの点数をあげることができます。
        </div>
        <div className="e-box-image">
          <StaticImage
            src="../../../../../assets/images/course/high-school/support/support2/point2/image2.png"
            alt=""
            placeholder="none"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<Support2Point1SectionPropsClassName>`
  padding-top: ${rem(60 / 2)};
  background: #edfbff;

  @media ${breakpoints.lg} {
    padding-top: ${rem(40)};
  }

  > .e-head {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  > .e-image {
    margin-top: ${rem(20 / 2)};
    position: relative;
    z-index: 2;

    @media ${breakpoints.lg} {
      margin-top: ${rem(20)};
    }

    > .e-image-student {
      text-align: center;

      img {
        width: ${rem(264.62 / 2)};

        @media ${breakpoints.lg} {
          width: ${rem(264.62)};
        }
      }
    }

    > .e-image-star1-1 {
      width: ${rem(30 / 2)};
      height: ${rem(30 / 2)};
      position: absolute;
      top: ${rem(56 / 2)};
      right: calc(50% + ${rem(84 / 2)});
      animation: highSchoolStar linear var(--star-speed) infinite forwards 0.7s;

      @media ${breakpoints.lg} {
        width: ${rem(24)};
        height: ${rem(24)};
        top: ${rem(56)};
        right: calc(50% + ${rem(84)});
      }
    }

    > .e-image-star1-2 {
      width: ${rem(20 / 2)};
      height: ${rem(20 / 2)};
      position: absolute;
      top: ${rem(32 / 2)};
      right: calc(50% + ${rem(42 / 2)});
      animation: highSchoolStar linear var(--star-speed) infinite forwards 0s;

      @media ${breakpoints.lg} {
        width: ${rem(20)};
        height: ${rem(20)};
        top: ${rem(32)};
        right: calc(50% + ${rem(42)});
      }
    }

    > .e-image-star1-3 {
      width: ${rem(20 / 2)};
      height: ${rem(20 / 2)};
      position: absolute;
      top: ${rem(74 / 2)};
      right: calc(50% + ${rem(4 / 2)});
      animation: highSchoolStar linear var(--star-speed) infinite forwards 1.4s;

      @media ${breakpoints.lg} {
        width: ${rem(20)};
        height: ${rem(20)};
        top: ${rem(74)};
        right: calc(50% + ${rem(4)});
      }
    }

    > .e-image-star1-4 {
      width: ${rem(30 / 2)};
      height: ${rem(30 / 2)};
      position: absolute;
      top: ${rem(24 / 2)};
      left: calc(50% + ${rem(106 / 2)});
      animation: highSchoolStar linear var(--star-speed) infinite forwards 2.1s;

      @media ${breakpoints.lg} {
        width: ${rem(24)};
        height: ${rem(24)};
        top: ${rem(24)};
        left: calc(50% + ${rem(106)});
      }
    }
  }

  > .e-box {
    margin-top: ${rem(-30 / 2)};
    background: #fff;
    border-radius: ${rem(10 / 2)};
    padding: ${rem(60 / 2)} ${rem(60 / 2)} ${rem(50 / 2)};

    @media ${breakpoints.lg} {
      display: flex;
      gap: ${rem(60)};
      align-items: center;
      margin-top: ${rem(-20)};
      border-radius: ${rem(10)};
      padding: ${rem(100)} ${rem(60)} ${rem(100)};
    }

    > .e-box-text {
      font-size: ${rem(32 / 2)};
      line-height: 2;
      text-align: justify;

      @media ${breakpoints.lg} {
        font-size: ${rem(18)};
      }

      .is-em {
        background: linear-gradient(transparent 82%, #b2eeff 0);
      }
    }

    > .e-box-image {
      text-align: center;
      margin-top: ${rem(40 / 2)};
      margin-left: ${rem(-25 / 2)};
      margin-right: ${rem(-25 / 2)};

      @media ${breakpoints.lg} {
        margin-top: ${rem(0)};
        margin-left: ${rem(0)};
        margin-right: ${rem(0)};
      }

      img {
        /* width: ${rem(600 / 2)}; */
        width: 100%;
      }
    }
  }
`

export const Support2Point1Section: (props: Support2Point1SectionProps) => JSX.Element = StyledComponent

export default Support2Point1Section
