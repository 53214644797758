import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { color, fontFamily, innerWidth, rem, breakpoints, innerPadding, innerDummyPadding } from 'variables/_index'
import Rellax from 'rellax'
import { useInView } from 'react-intersection-observer'
import classNames from 'classnames'
import Support1StepSection from './support1StepSection'
import SupportHeadSection from './supportHeadSection'

export interface Support1SectionProps {
  readonly noProps?: string
}

type Support1SectionPropsClassName = Support1SectionProps & {
  readonly className: string
}

function Component({ className }: Support1SectionPropsClassName) {
  const illustRef1 = useRef<HTMLDivElement>(null)
  const illustRef2 = useRef<HTMLDivElement>(null)
  const illustRef3 = useRef<HTMLDivElement>(null)
  const illustRef4 = useRef<HTMLDivElement>(null)

  const { ref, inView } = useInView({
    rootMargin: '-70px', // ref要素が現れてから100px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  useEffect(() => {
    if (illustRef1.current) {
      new Rellax(illustRef1.current, {
        speed: 0.8, // -10 ~ 10
        center: true,
      })
    }

    if (illustRef2.current) {
      new Rellax(illustRef2.current, {
        speed: 0.7, // -10 ~ 10
        center: true,
      })
    }

    if (illustRef3.current) {
      new Rellax(illustRef3.current, {
        speed: 0.6, // -10 ~ 10
        center: true,
      })
    }

    if (illustRef4.current) {
      new Rellax(illustRef4.current, {
        speed: 0.5, // -10 ~ 10
        center: true,
      })
    }
  }, [])

  return (
    <div className={className}>
      <div className="e-secret-head">
        <SupportHeadSection />
      </div>
      <div className="e-lead-section">
        <div className="e-support1-inner">
          <div ref={ref} className={classNames('c-section-head', inView && 'is-view')}>
            <div className="c-section-head__image">
              <div className="c-section-head__image-in">
                <StaticImage
                  src="../../../../../assets/images/course/high-school/support/support1/support1-text.png"
                  alt=""
                  placeholder="none"
                  loading="lazy"
                />
              </div>
            </div>
            <h3 className="c-section-head__markers">
              <div className={classNames('c-section-head__marker', 'c-section-head__marker1')}>
                <div className="c-section-head__marker-in">自分の個性を最大限生かせる</div>
              </div>
              <div className={classNames('c-section-head__marker', 'c-section-head__marker2')}>
                <div className="c-section-head__marker-in">大学・受験方式が見つかる</div>
              </div>
            </h3>
          </div>
          <div className="e-content">
            <div className="e-lead-text">
              <h4 className="e-lead-text-in">
                受験情報を知り抜く<span className="is-em">担任</span>が
                <br />
                1人ひとりにあわせて
                <br />
                コーチング
              </h4>
              <div className="e-lead-image-box">
                <div className="e-lead-image-human">
                  <StaticImage
                    src="../../../../../assets/images/course/high-school/support/support1/lead.png"
                    alt=""
                    placeholder="none"
                    loading="lazy"
                  />
                </div>
                <div className="e-lead-image-star1">
                  <StaticImage
                    src="../../../../../assets/images/course/high-school/support/support1/star3.png"
                    alt=""
                    placeholder="none"
                    loading="lazy"
                  />
                </div>
                <div className="e-lead-image-star2">
                  <StaticImage
                    src="../../../../../assets/images/course/high-school/support/support1/star2.png"
                    alt=""
                    placeholder="none"
                    loading="lazy"
                  />
                </div>
                <div className="e-lead-image-star3">
                  <StaticImage
                    src="../../../../../assets/images/course/high-school/support/support1/star1.png"
                    alt=""
                    placeholder="none"
                    loading="lazy"
                  />
                </div>
                <div className="e-lead-image-star4">
                  <StaticImage
                    src="../../../../../assets/images/course/high-school/support/support1/star4.png"
                    alt=""
                    placeholder="none"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            <div className="e-lead-content">
              全国の生徒を指導してきたから知っている、自分にぴったりの大学・学部・学科や
              <span className="is-em">その特色など、学校の先生や友達からは聞けない情報を持った受験のプロである担任が専属でつきます。</span>
            </div>
            <div className="e-lead-teacher">
              <div className="e-lead-teacher-head">
                <span className="is-text">実際の担任を一部紹介!</span>
              </div>
              <div className="e-lead-teacher-images">
                <div className="e-lead-teacher-image">
                  <StaticImage
                    src="../../../../../assets/images/course/high-school/support/support1/teacher1.png"
                    alt=""
                    placeholder="none"
                    loading="lazy"
                  />
                  <div className="e-lead-teacher-image-name">中村先生</div>
                </div>
                <div className="e-lead-teacher-image">
                  <StaticImage
                    src="../../../../../assets/images/course/high-school/support/support1/teacher2.png"
                    alt=""
                    placeholder="none"
                    loading="lazy"
                  />
                  <div className="e-lead-teacher-image-name">森先生</div>
                </div>
                <div className="e-lead-teacher-star1">
                  <StaticImage
                    src="../../../../../assets/images/course/high-school/support/support1/star3.png"
                    alt=""
                    placeholder="none"
                    loading="lazy"
                  />
                </div>
                <div className="e-lead-teacher-star2">
                  <StaticImage
                    src="../../../../../assets/images/course/high-school/support/support1/star4.png"
                    alt=""
                    placeholder="none"
                    loading="lazy"
                  />
                </div>
                <div className="e-lead-teacher-star3">
                  <StaticImage
                    src="../../../../../assets/images/course/high-school/support/support1/star3.png"
                    alt=""
                    placeholder="none"
                    loading="lazy"
                  />
                </div>
                <div className="e-lead-teacher-star4">
                  <StaticImage
                    src="../../../../../assets/images/course/high-school/support/support1/star3.png"
                    alt=""
                    placeholder="none"
                    loading="lazy"
                  />
                </div>
                <div className="e-lead-teacher-star5">
                  <StaticImage
                    src="../../../../../assets/images/course/high-school/support/support1/star4.png"
                    alt=""
                    placeholder="none"
                    loading="lazy"
                  />
                </div>
                <div className="e-lead-teacher-star6">
                  <StaticImage
                    src="../../../../../assets/images/course/high-school/support/support1/star3.png"
                    alt=""
                    placeholder="none"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="e-step-section">
        <Support1StepSection />
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<Support1SectionPropsClassName>`
  padding-top: ${rem(60 / 2)};
  padding-bottom: ${rem(150 / 2)};

  @media ${breakpoints.lg} {
    padding-top: ${rem(80)};
    padding-bottom: ${rem(80)};
  }

  @keyframes highSchoolSupport1Catch {
    0% {
      opacity: 0;
      translate: -101% 0;
    }

    100% {
      opacity: 1;
      translate: 0 0;
    }
  }

  @keyframes highSchoolSupport1CatchIn {
    0% {
      opacity: 0;
      translate: 101% 0;
    }

    100% {
      opacity: 1;
      translate: 0 0;
    }
  }

  .c-section-head {
    &__image {
      width: fit-content;
      overflow: hidden;
      margin-left: ${rem(-32 / 2)};
      translate: -100% 0;

      @media ${breakpoints.lg} {
        margin-left: ${rem(-32)};
      }
    }

    &__image-in {
      width: fit-content;
      translate: 100% 0;

      img {
        width: ${rem(250 / 2)};

        @media ${breakpoints.lg} {
          width: ${rem(250)};
        }
      }
    }

    &__markers {
      margin-top: ${rem(20 / 2)};

      @media ${breakpoints.lg} {
        margin-top: ${rem(20)};
      }
    }

    &__marker {
      width: fit-content;
      overflow: hidden;
      translate: -101% 0;
      margin-right: ${rem(10 / 2)};

      @media ${breakpoints.lg} {
        margin-right: ${rem(10)};
      }

      &:nth-child(n + 2) {
        margin-top: ${rem(24 / 2)};

        @media ${breakpoints.lg} {
          margin-top: ${rem(20)};
        }
      }
    }

    &__marker-in {
      width: fit-content;
      padding: ${rem(4)} ${rem(10)} ${rem(6)};
      background: ${color.main};
      color: #fff;
      font-size: ${rem(46 / 2)};
      letter-spacing: 0.05em;
      font-family: ${fontFamily.zenKaku};
      font-weight: 700;
      white-space: nowrap;
      translate: 101% 0;

      @media ${breakpoints.lg} {
        padding: ${rem(5)} ${rem(18)} ${rem(8)};
        font-size: ${rem(38)};
      }
    }

    &.is-view {
      .c-section-head__image {
        animation: highSchoolSupport1Catch linear 1s forwards 0s;

        .c-section-head__image-in {
          animation: highSchoolSupport1CatchIn linear 1s forwards 0s;
        }
      }

      .c-section-head__marker1 {
        animation: highSchoolSupport1Catch linear 0.6s forwards 0.6s;

        .c-section-head__marker-in {
          animation: highSchoolSupport1CatchIn linear 0.6s forwards 0.6s;
        }
      }

      .c-section-head__marker2 {
        animation: highSchoolSupport1Catch linear 0.6s forwards 1.2s;

        .c-section-head__marker-in {
          animation: highSchoolSupport1CatchIn linear 0.6s forwards 1.2s;
        }
      }
    }
  }

  > .e-lead-section {
    background: #edfbff;
    padding-top: ${rem(40 / 2)};
    padding-bottom: ${rem(160 / 2)};

    @media ${breakpoints.lg} {
      padding-top: ${rem(80)};
      padding-bottom: ${rem(160)};
    }

    > .e-support1-inner {
      width: ${innerWidth.sm};
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;

      @media ${breakpoints.lg} {
        width: ${rem(800)};
      }

      > .e-content {
        margin-top: ${rem(50 / 2)};

        @media ${breakpoints.lg} {
          background: #fff;
          border-radius: ${rem(20)};
          padding: ${rem(40)} ${rem(100)};
          margin-top: ${rem(28)};
        }

        > .e-lead-text {
          padding-bottom: ${rem(60 / 2)};
          position: relative;

          @media ${breakpoints.lg} {
            padding-bottom: ${rem(70)};
            line-height: 1.2;
          }

          > .e-lead-text-in {
            font-size: ${rem(42 / 2)};
            display: inline;
            font-weight: 700;
            line-height: 2;
            color: #00c8ff;
            letter-spacing: 0.05em;
            border-bottom: 1.5px solid currentColor;
            font-family: ${fontFamily.zenKaku};

            @media ${breakpoints.lg} {
              font-size: ${rem(32)};
              border-bottom-width: 2px;
            }

            .is-em {
              font-size: ${rem(52 / 2)};
              color: #ff8c00;

              @media ${breakpoints.lg} {
                font-size: ${rem(40)};
              }
            }
          }

          > .e-lead-image-box {
            position: absolute;
            right: 0;
            bottom: 0;

            @media ${breakpoints.lg} {
              bottom: 0;
            }

            > .e-lead-image-human {
              img {
                width: ${rem(246.85 / 2)};

                @media ${breakpoints.lg} {
                  width: ${rem(246.85)};
                }
              }
            }

            > .e-lead-image-star1 {
              position: absolute;
              left: ${rem(-68 / 2)};
              top: ${rem(92 / 2)};
              width: ${rem(30 / 2)};
              height: ${rem(30 / 2)};
              animation: highSchoolStar linear var(--star-speed) infinite forwards 0.7s;

              @media ${breakpoints.lg} {
                left: ${rem(-68)};
                top: ${rem(92)};
                width: ${rem(24)};
                height: ${rem(24)};
              }
            }

            > .e-lead-image-star2 {
              position: absolute;
              left: ${rem(-24 / 2)};
              top: ${rem(152 / 2)};
              width: ${rem(20 / 2)};
              height: ${rem(20 / 2)};
              animation: highSchoolStar linear var(--star-speed) infinite forwards 0s;

              @media ${breakpoints.lg} {
                left: ${rem(-24)};
                top: ${rem(152)};
                width: ${rem(16)};
                height: ${rem(16)};
              }
            }

            > .e-lead-image-star3 {
              position: absolute;
              left: ${rem(100 / 2)};
              bottom: ${rem(-4)};
              width: ${rem(25 / 2)};
              height: ${rem(25 / 2)};
              animation: highSchoolStar linear var(--star-speed) infinite forwards 1.4s;

              @media ${breakpoints.lg} {
                left: ${rem(100)};
                bottom: ${rem(-4)};
                width: ${rem(20)};
                height: ${rem(20)};
              }
            }

            > .e-lead-image-star4 {
              position: absolute;
              right: ${rem(0)};
              top: ${rem(20 / 2)};
              width: ${rem(20 / 2)};
              height: ${rem(20 / 2)};
              animation: highSchoolStar linear var(--star-speed) infinite forwards 2.1s;

              @media ${breakpoints.lg} {
                right: ${rem(0)};
                top: ${rem(20)};
                width: ${rem(16)};
                height: ${rem(16)};
              }
            }
          }
        }

        > .e-lead-content {
          margin-top: ${rem(40 / 2)};
          font-size: ${rem(32 / 2)};
          line-height: 2;
          text-align: justify;

          @media ${breakpoints.lg} {
            margin-top: ${rem(30)};
            font-size: ${rem(18)};
          }

          .is-em {
            background: linear-gradient(transparent 82%, #b2eeff 0);
          }
        }

        > .e-lead-teacher {
          margin-top: ${rem(60 / 2)};

          @media ${breakpoints.lg} {
            margin-top: ${rem(80)};
          }

          .e-lead-teacher-head {
            display: flex;
            justify-content: center;
            align-items: center;

            .is-text {
              color: #fff;
              background: #ff8c00;
              font-size: ${rem(40 / 2)};
              font-weight: 700;
              letter-spacing: 0.15em;
              text-indent: 0.15em;
              padding: ${rem(2)} ${rem(20 / 2)} ${rem(3)};
              display: inline-block;

              @media ${breakpoints.lg} {
                font-size: ${rem(28)};
                padding: ${rem(2)} ${rem(20)} ${rem(3)};
              }
            }

            &::before,
            &::after {
              content: '';
              height: ${rem(70 / 2)};
              width: 1.5px;
              background: #333;

              @media ${breakpoints.lg} {
                height: ${rem(42)};
                width: 3px;
              }
            }

            &::before {
              rotate: -20deg;
              margin-right: ${rem(24 / 2)};

              @media ${breakpoints.lg} {
                margin-right: ${rem(16)};
              }
            }

            &::after {
              rotate: 20deg;
              margin-left: calc(${rem(24 / 2)} - 0.05em);

              @media ${breakpoints.lg} {
                margin-left: calc(${rem(16)} - 0.05em);
              }
            }
          }

          > .e-lead-teacher-images {
            margin-top: ${rem(30 / 2)};
            display: flex;
            gap: ${rem(70 / 2)};
            justify-content: center;
            position: relative;

            @media ${breakpoints.lg} {
              margin-top: ${rem(30)};
              gap: ${rem(62)};
            }

            > .e-lead-teacher-image {
              img {
                width: ${rem(220 / 2)};

                @media ${breakpoints.lg} {
                  width: ${rem(170)};
                }
              }

              > .e-lead-teacher-image-name {
                margin-top: ${rem(2)};
                letter-spacing: 0.1em;
                text-indent: 0.1em;
                text-align: center;
                font-size: ${rem(24 / 2)};

                @media ${breakpoints.lg} {
                  margin-top: ${rem(4)};
                  font-size: ${rem(14)};
                }
              }
            }

            > .e-lead-teacher-star1 {
              position: absolute;
              top: ${rem(68 / 2)};
              right: calc(50% + ${rem(204 / 2)});
              width: ${rem(30 / 2)};
              height: ${rem(30 / 2)};
              animation: highSchoolStar linear var(--star-speed) infinite forwards 2.1s;

              @media ${breakpoints.lg} {
                top: ${rem(58)};
                right: calc(50% + ${rem(164)});
                width: ${rem(24)};
                height: ${rem(24)};
              }
            }

            > .e-lead-teacher-star2 {
              position: absolute;
              top: ${rem(50 / 2)};
              right: calc(50% + ${rem(68 / 2)});
              width: ${rem(20 / 2)};
              height: ${rem(20 / 2)};
              animation: highSchoolStar linear var(--star-speed) infinite forwards 0s;

              @media ${breakpoints.lg} {
                top: ${rem(50)};
                right: calc(50% + ${rem(52)});
                width: ${rem(16)};
                height: ${rem(16)};
              }
            }

            > .e-lead-teacher-star3 {
              position: absolute;
              top: ${rem(128 / 2)};
              right: calc(50% + ${rem(-4)});
              width: ${rem(30 / 2)};
              height: ${rem(30 / 2)};
              animation: highSchoolStar linear var(--star-speed) infinite forwards 1.4s;

              @media ${breakpoints.lg} {
                top: ${rem(108)};
                right: calc(50% + ${rem(-10)});
                width: ${rem(24)};
                height: ${rem(24)};
              }
            }

            > .e-lead-teacher-star4 {
              position: absolute;
              top: ${rem(66 / 2)};
              left: calc(50% + ${rem(58 / 2)});
              width: ${rem(30 / 2)};
              height: ${rem(30 / 2)};
              animation: highSchoolStar linear var(--star-speed) infinite forwards 0.7s;

              @media ${breakpoints.lg} {
                top: ${rem(66)};
                left: calc(50% + ${rem(48)});
                width: ${rem(24)};
                height: ${rem(24)};
              }
            }

            > .e-lead-teacher-star5 {
              position: absolute;
              top: ${rem(50 / 2)};
              left: calc(50% + ${rem(210 / 2)});
              width: ${rem(20 / 2)};
              height: ${rem(20 / 2)};
              animation: highSchoolStar linear var(--star-speed) infinite forwards 2.8s;

              @media ${breakpoints.lg} {
                top: ${rem(50)};
                left: calc(50% + ${rem(170)});
                width: ${rem(16)};
                height: ${rem(16)};
              }
            }

            > .e-lead-teacher-star6 {
              position: absolute;
              top: ${rem(128 / 2)};
              left: calc(50% + ${rem(274 / 2)});
              width: ${rem(30 / 2)};
              height: ${rem(30 / 2)};
              animation: highSchoolStar linear var(--star-speed) infinite forwards 1s;

              @media ${breakpoints.lg} {
                top: ${rem(108)};
                left: calc(50% + ${rem(218)});
                width: ${rem(24)};
                height: ${rem(24)};
              }
            }
          }
        }
      }
    }
  }
`

export const Support1Section: (props: Support1SectionProps) => JSX.Element = StyledComponent

export default Support1Section
