import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { breakpoints, rem } from 'variables/_index'
import HeadSection from 'components/section/course/high-school/headSection'
import AboutSection from 'components/section/course/high-school/aboutSection'
import VoiceSection from 'components/section/course/high-school/voiceSection'
import CtaSection from 'components/section/course/high-school/ctaSection'
import SupportSection from 'components/section/course/high-school/supportSection'
import OverviewSection from 'components/section/course/high-school/overviewSection'
import HowSection from 'components/section/course/high-school/howSection'

export interface HighCourseTemplateProps {
  readonly noProps?: string
}

type HighCourseTemplatePropsClassName = HighCourseTemplateProps & {
  readonly className: string
}

function Component({ className }: HighCourseTemplatePropsClassName) {
  return (
    <div className={className}>
      <HeadSection />
      <AboutSection />
      <VoiceSection />
      <CtaSection id="high-school-contact1" />
      <SupportSection />
      <CtaSection id="high-school-contact2" />
      <OverviewSection />
      <CtaSection id="high-school-contact3" />
      <HowSection />
    </div>
  )
}

const StyledComponent = styled(Component)<HighCourseTemplatePropsClassName>`
  padding-top: ${rem(100 / 2)};

  @media ${breakpoints.lg} {
    padding-top: ${rem(74)};
  }

  --star-speed: 2.8s; // もともと2s

  @keyframes highSchoolStar {
    0% {
      opacity: 0.8;
    }

    /* もともと80% */
    60% {
      opacity: 0;
    }

    100% {
      opacity: 0.8;
    }
  }

  @keyframes highSchoolShow {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`

export const HighCourseTemplate: (props: HighCourseTemplateProps) => JSX.Element = StyledComponent

export default HighCourseTemplate
