import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import Support1StepHead from './support1StepHead'
import Support1StepHeadImage from './support1StepHeadImage'
import { color, fontFamily, rem, breakpoints } from 'variables/_index'

export interface Support1Step1SectionProps {
  readonly noProps?: string
}

type Support1Step1SectionPropsClassName = Support1Step1SectionProps & {
  readonly className: string
}

function Component({ className }: Support1Step1SectionPropsClassName) {
  return (
    <div className={className}>
      <div className="e-step-box-head">
        <Support1StepHead
          text="行きたい大学・学部が見つかる"
          stepImage={
            <StaticImage
              src="../../../../../assets/images/course/high-school/support/support1/step/step1-text.png"
              alt=""
              placeholder="none"
              loading="lazy"
            />
          }
        />
      </div>
      <div className="e-step-head-image">
        <Support1StepHeadImage
          teacherImage={
            <StaticImage
              src="../../../../../assets/images/course/high-school/support/support1/step/step1-head.png"
              alt=""
              placeholder="none"
              loading="lazy"
            />
          }
        />
      </div>
      <div className="e-step-box-wrap">
        <div className="e-step-box-image">
          <StaticImage
            className="u-hidden-pc"
            src="../../../../../assets/images/course/high-school/support/support1/step/step1-image.png"
            alt=""
            placeholder="none"
            loading="lazy"
          />
          <StaticImage
            className="u-hidden-sp"
            src="../../../../../assets/images/course/high-school/support/support1/step/step1-image-pc.png"
            alt=""
            placeholder="none"
            loading="lazy"
          />
        </div>
        <div className="e-step-box-body">
          <div className="e-step-box-lead">
            <div className="e-step-box-lead-in">
              行きたい大学が
              <br />
              見つかっていなくても大丈夫!
            </div>
            <div className="e-step-illust1">
              <StaticImage
                src="../../../../../assets/images/course/high-school/support/support1/step/illust1.png"
                alt=""
                placeholder="none"
                loading="lazy"
              />
            </div>
          </div>
          <div className="e-step-box-content">
            オンライン上で1：1で話していく中で、興味があることや個性などをつかみ、ぴったりの大学や学部・学科を提案。
            <br />
            <span className="is-em">自分の個性や長所にあった大学を見つけること</span>で、大学選択の幅が広がる!
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<Support1Step1SectionPropsClassName>`
  background: #edfbff;
  border-radius: ${rem(10 / 2)};
  position: relative;

  > .e-step-box-head {
    position: absolute;
    bottom: calc(100% - ${rem(20 / 2)});
    z-index: 2;

    @media ${breakpoints.lg} {
      bottom: calc(100% - ${rem(30)});
    }
  }

  > .e-step-head-image {
    text-align: center;
    position: absolute;
    bottom: 100%;
    width: 100%;
  }

  > .e-step-box-wrap {
    @media ${breakpoints.lg} {
      display: flex;
    }

    > .e-step-box-image {
      @media ${breakpoints.lg} {
        width: ${rem(470)};
        flex-shrink: 0;
      }

      img {
        width: ${rem(610 / 2)};

        @media ${breakpoints.lg} {
          width: inherit;
        }
      }
    }

    > .e-step-box-body {
      padding: ${rem(50 / 2)} ${rem(60 / 2)};

      @media ${breakpoints.lg} {
        padding: ${rem(90)} ${rem(60)};
      }

      > .e-step-box-lead {
        font-size: ${rem(36 / 2)};
        font-family: ${fontFamily.zenKaku};
        line-height: 2;
        color: ${color.main};
        letter-spacing: 0.05em;
        font-weight: 700;
        position: relative;

        @media ${breakpoints.lg} {
          font-size: ${rem(24)};
        }

        > .e-step-box-lead-in {
          display: inline;
          border-bottom: 1px solid currentColor;
          white-space: nowrap;

          @media ${breakpoints.lg} {
            border-bottom-width: 2px;
          }
        }

        > .e-step-illust1 {
          position: absolute;
          right: 0;
          top: ${rem(-78 / 2)};

          @media ${breakpoints.lg} {
            top: ${rem(-78)};
            right: ${rem(0)};
          }

          img {
            width: ${rem(130 / 2)};

            @media ${breakpoints.lg} {
              width: ${rem(100)};
            }
          }
        }
      }

      > .e-step-box-content {
        margin-top: ${rem(40 / 2)};
        line-height: 2;
        font-size: ${rem(32 / 2)};
        text-align: justify;

        @media ${breakpoints.lg} {
          margin-top: ${rem(30)};
          font-size: ${rem(18)};
        }

        .is-em {
          background: linear-gradient(transparent 82%, #b2eeff 0);
        }
      }
    }
  }
`

export const Support1Step1Section: (props: Support1Step1SectionProps) => JSX.Element = StyledComponent

export default Support1Step1Section
