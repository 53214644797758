const globalTitle = (path: string): string => {
  if (path === '/') {
    return 'オンライン個別指導塾-Fit NET STUDY-フィットネットスタディ'
  } else if (path === '/summer/') {
    return ''
  }
  return '-オンライン個別指導塾-Fit NET STUDY'
}

export default globalTitle
