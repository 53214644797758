import React from 'react'
import styled from 'styled-components'
import { breakpoints, color, rem } from 'variables/_index'
import BlueIconImage from 'assets/images/course/high-school/voice/blue-icon.svg'

export interface VoiceCardProps {
  readonly university: string
  readonly faculty: string
  readonly name: string
  readonly imageElement: JSX.Element
}

type VoiceCardPropsClassName = VoiceCardProps & {
  readonly className: string
}

function Component({ className, university, imageElement, faculty, name }: VoiceCardPropsClassName) {
  return (
    <div className={className}>
      <p className="e-title">{university}</p>
      <p className="e-meta">{faculty}</p>
      <div className="e-image">{imageElement}</div>
      <p className="e-name">{name}</p>
    </div>
  )
}

const StyledComponent = styled(Component)<VoiceCardPropsClassName>`
  background-color: #fff;
  border-radius: ${rem(20 / 2)};
  border: 1.5px solid #00c8ff;
  position: relative;
  padding: ${rem(12)} ${rem(30 / 2)} ${rem(10)};

  @media ${breakpoints.lg} {
    border-radius: ${rem(20)};
    border-width: 2px;
    padding: ${rem(26)} ${rem(26)} ${rem(40)};
    border-width: 2px;
  }

  &::before {
    content: '';
    position: absolute;
    top: ${rem(-6)};
    left: ${rem(10)};
    width: ${rem(40 / 2)};
    aspect-ratio: 40 / 58;
    background: url(${BlueIconImage}) no-repeat;
    background-size: cover;

    @media ${breakpoints.lg} {
      width: ${rem(35.65)};
      left: ${rem(24)};
      top: ${rem(-10)};
    }
  }

  > .e-title {
    margin: 0;
    padding-bottom: ${rem(2)};
    text-align: center;
    font-weight: 700;
    font-size: ${rem(50 / 2)};
    text-align: center;
    color: #0028be;
    border-bottom: 1.5px solid #00c8ff;

    @media ${breakpoints.lg} {
      font-size: ${rem(38)};
      padding-bottom: ${rem(5)};
    }
  }

  > .e-meta {
    margin: ${rem(5)} 0 0;
    font-weight: 500;
    font-size: ${rem(28 / 2)};
    letter-spacing: 0.06em;
    text-align: center;

    @media ${breakpoints.lg} {
      margin-top: ${rem(10)};
      font-size: ${rem(20)};
    }
  }

  > .e-image {
    margin: ${rem(12)} 0 0;
    text-align: center;

    @media ${breakpoints.lg} {
      margin-top: ${rem(16)};
    }

    img {
      width: ${rem(270 / 2)};

      @media ${breakpoints.lg} {
        width: ${rem(240)};
      }
    }
  }

  > .e-name {
    margin: ${rem(6)} 0 0;
    font-weight: 500;
    font-size: ${rem(28 / 2)};
    letter-spacing: 0.06em;
    text-align: center;

    @media ${breakpoints.lg} {
      font-size: ${rem(18)};
      margin-top: ${rem(6)};
    }
  }
`

export const VoiceCard: (props: VoiceCardProps) => JSX.Element = StyledComponent

export default VoiceCard
