import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import Support1StepHead from './support1StepHead'
import Support1StepHeadImage from './support1StepHeadImage'
import { color, fontFamily, rem, breakpoints } from 'variables/_index'

export interface Support1Step2SectionProps {
  readonly noProps?: string
}

type Support1Step2SectionPropsClassName = Support1Step2SectionProps & {
  readonly className: string
}

function Component({ className }: Support1Step2SectionPropsClassName) {
  return (
    <div className={className}>
      <div className="e-step-box-head">
        <Support1StepHead
          text="合格しやすい受験方式が見つかる"
          stepImage={
            <StaticImage
              src="../../../../../assets/images/course/high-school/support/support1/step/step2-text.png"
              alt=""
              placeholder="none"
              loading="lazy"
            />
          }
        />
      </div>
      <div className="e-step-head-image">
        <Support1StepHeadImage
          teacherImage={
            <StaticImage
              src="../../../../../assets/images/course/high-school/support/support1/step/step2-head.png"
              alt=""
              placeholder="none"
              loading="lazy"
            />
          }
        />
      </div>
      <div className="e-step-box-wrap">
        <div className="e-step-box-type">
          <div className="e-step-box-type-head">
            大学入試は大きく分けて
            <span className="is-dot">2</span>
            <span className="is-dot">種</span>
            <span className="is-dot">類</span>
          </div>
          <div className="e-step-box-items">
            <div className="e-step-box-item">
              <div className="e-step-box-item-head">
                <div className="e-step-box-item-head-image">
                  <StaticImage
                    src="../../../../../assets/images/course/high-school/support/support1/step/type1-head.png"
                    alt=""
                    placeholder="none"
                    loading="lazy"
                  />
                </div>
                <div className="e-step-box-item-head-illust">
                  <StaticImage
                    src="../../../../../assets/images/course/high-school/support/support1/step/illust2.png"
                    alt=""
                    placeholder="none"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="e-step-box-item-body">
                <div className="e-step-box-item-text">共通テストや大学独自の一般選抜試験の学力試験が中心。</div>
              </div>
            </div>
            <div className="e-step-box-item">
              <div className="e-step-box-item-head">
                <div className="e-step-box-item-head-image">
                  <StaticImage
                    src="../../../../../assets/images/course/high-school/support/support1/step/type2-head.png"
                    alt=""
                    placeholder="none"
                    loading="lazy"
                  />
                </div>
                <div className="e-step-box-item-head-illust">
                  <StaticImage
                    src="../../../../../assets/images/course/high-school/support/support1/step/illust3.png"
                    alt=""
                    placeholder="none"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="e-step-box-item-body">
                <div className="e-step-box-item-text">
                  書類選考・面接・小論文がメインの選考。大学によって、学力試験が必要な場合もある。受験方法によっては、受験条件に評定平均が一定程度必要になる。
                </div>
                <div className="e-step-box-item-type2-1">
                  <StaticImage
                    src="../../../../../assets/images/course/high-school/support/support1/step/type2-1.png"
                    alt=""
                    placeholder="none"
                    loading="lazy"
                  />
                </div>
                <div className="e-step-box-item-type2-2">
                  <StaticImage
                    src="../../../../../assets/images/course/high-school/support/support1/step/type2-2.png"
                    alt=""
                    placeholder="none"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="e-step-box-body">
          <div className="e-step-box-lead">
            <div className="e-step-box-lead-in">
              行きたい大学の受験方式の中から
              <br />
              自分の個性にあった
              <br className="u-hidden-pc" />
              受験方式を提案!
            </div>
          </div>
          <div className="e-step-box-content">
            一般選抜は通常の学力試験なので、基礎学力アップが重要。
            <br />
            推薦系選抜は多様な試験形態が特色。
            <span className="is-em">1人ひとりの個性にあった志望先を一緒に選ぶことで、合格の可能性が高まります。</span>
          </div>
          <div className="e-step-box-image">
            <StaticImage
              src="../../../../../assets/images/course/high-school/support/support1/step/step2-body-image.png"
              alt=""
              placeholder="none"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<Support1Step2SectionPropsClassName>`
  background: #edfbff;
  padding-top: ${rem(60 / 2)};
  padding-bottom: ${rem(50 / 2)};
  border-radius: ${rem(10 / 2)};
  position: relative;

  @media ${breakpoints.lg} {
    padding-top: ${rem(90)};
    padding-bottom: ${rem(100)};
    border-radius: ${rem(10)};
  }

  > .e-step-box-head {
    padding-left: ${rem(0)};
    position: absolute;
    bottom: calc(100% - ${rem(20 / 2)});
    z-index: 2;

    @media ${breakpoints.lg} {
      bottom: calc(100% - ${rem(30)});
    }
  }

  > .e-step-head-image {
    text-align: center;
    position: absolute;
    bottom: 100%;
    width: 100%;
  }

  > .e-step-box-wrap {
    padding-left: ${rem(60 / 2)};
    padding-right: ${rem(60 / 2)};

    @media ${breakpoints.lg} {
      padding-left: ${rem(60)};
      padding-right: ${rem(60)};
      display: flex;
      gap: ${rem(60)};
    }

    .e-step-box-type {
      > .e-step-box-type-head {
        text-align: center;
        font-size: ${rem(32 / 2)};
        font-weight: 700;
        letter-spacing: 0.15em;
        text-indent: 0.15em;
        color: ${color.main};

        @media ${breakpoints.lg} {
          font-size: ${rem(24)};
        }

        .is-dot {
          position: relative;

          &::after {
            content: '';
            position: absolute;
            width: ${rem(6 / 2)};
            height: ${rem(6 / 2)};
            background-color: currentColor;
            border-radius: 50%;
            top: ${rem(-2)};
            left: 50%;
            transform: translate(-50%);

            @media ${breakpoints.lg} {
              width: ${rem(4)};
              height: ${rem(4)};
              top: ${rem(-2)};
            }
          }
        }
      }

      > .e-step-box-items {
        margin-top: ${rem(40 / 2)};

        @media ${breakpoints.lg} {
          margin-top: ${rem(20)};
        }

        > .e-step-box-item {
          &:nth-child(n + 2) {
            margin-top: ${rem(38 / 2)};

            @media ${breakpoints.lg} {
              margin-top: ${rem(30)};
            }
          }

          > .e-step-box-item-head {
            position: relative;

            > .e-step-box-item-head-image {
              img {
                width: ${rem(554 / 2)};

                @media ${breakpoints.lg} {
                  width: ${rem(410)};
                }
              }
            }

            > .e-step-box-item-head-illust {
              position: absolute;
              top: ${rem(-4)};
              right: ${rem(28 / 2)};

              @media ${breakpoints.lg} {
                top: ${rem(-4)};
                right: ${rem(20)};
              }

              img {
                width: ${rem(110 / 2)};

                @media ${breakpoints.lg} {
                  width: ${rem(80)};
                }
              }
            }
          }

          > .e-step-box-item-body {
            margin-top: ${rem(18 / 2)};
            background: #ffffdc;
            padding: ${rem(32 / 2)} ${rem(40 / 2)} ${rem(36 / 2)};
            font-size: ${rem(26 / 2)};
            line-height: 1.8;

            @media ${breakpoints.lg} {
              margin-top: ${rem(10)};
              padding: ${rem(20)} ${rem(20)} ${rem(20)};
              font-size: ${rem(16)};
            }

            > .e-step-box-item-text {
              text-align: justify;
            }

            > .e-step-box-item-type2-1 {
              margin-top: ${rem(36 / 2)};

              img {
                width: ${rem(470 / 2)};

                @media ${breakpoints.lg} {
                  width: ${rem(370)};
                }
              }
            }

            > .e-step-box-item-type2-2 {
              margin-top: ${rem(20 / 2)};

              @media ${breakpoints.lg} {
                margin-top: ${rem(20)};
              }

              img {
                width: ${rem(470 / 2)};

                @media ${breakpoints.lg} {
                  width: ${rem(370)};
                }
              }
            }
          }
        }
      }
    }

    > .e-step-box-body {
      > .e-step-box-lead {
        margin-top: ${rem(50 / 2)};
        font-size: ${rem(36 / 2)};
        font-family: ${fontFamily.zenKaku};
        line-height: 2;
        color: ${color.main};
        letter-spacing: 0.05em;
        font-weight: 700;
        position: relative;

        @media ${breakpoints.lg} {
          margin-top: ${rem(0)};
          font-size: ${rem(24)};
        }

        > .e-step-box-lead-in {
          display: inline;
          border-bottom: 1px solid currentColor;
          white-space: nowrap;

          @media ${breakpoints.lg} {
            border-bottom-width: 2px;
          }
        }
      }

      > .e-step-box-content {
        margin-top: ${rem(40 / 2)};
        line-height: 2;
        font-size: ${rem(32 / 2)};
        text-align: justify;

        @media ${breakpoints.lg} {
          margin-top: ${rem(30)};
          font-size: ${rem(18)};
        }

        .is-em {
          background: linear-gradient(transparent 82%, #b2eeff 0);
        }
      }

      > .e-step-box-image {
        margin-top: ${rem(46)};
        display: none;

        @media ${breakpoints.lg} {
          display: block;
          text-align: center;
          margin-top: ${rem(64)};
        }

        img {
          width: ${rem(410)};
        }
      }
    }
  }
`

export const Support1Step2Section: (props: Support1Step2SectionProps) => JSX.Element = StyledComponent

export default Support1Step2Section
