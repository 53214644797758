import React from 'react'
import styled from 'styled-components'
import { color, fontFamily, innerWidth, rem, breakpoints, innerPadding, innerDummyPadding } from 'variables/_index'

export interface Support2PointHeadProps {
  readonly text: string
  readonly numberImageSVG: JSX.Element
}

type Support2PointHeadPropsClassName = Support2PointHeadProps & {
  readonly className: string
}

function Component({ className, text, numberImageSVG }: Support2PointHeadPropsClassName) {
  return (
    <h5 className={className}>
      <span className="e-line1"></span>
      <span className="e-line2"></span>
      <div className="e-head-number">{numberImageSVG}</div>
      <div className="e-head-text">{text}</div>
      <span className="e-line3"></span>
      <span className="e-line4"></span>
    </h5>
  )
}

const StyledComponent = styled(Component)<Support2PointHeadPropsClassName>`
  position: relative;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  > .e-head-number {
    width: ${rem(40 / 2)};
    height: ${rem(40 / 2)};
    margin-right: ${rem(20 / 2)};

    @media ${breakpoints.lg} {
      width: ${rem(38)};
      height: ${rem(38)};
      margin-right: ${rem(20)};
    }

    svg {
      width: inherit;
      height: inherit;
    }
  }

  > .e-head-text {
    font-size: ${rem(46 / 2)};
    font-weight: 700;
    font-family: ${fontFamily.zenKaku};
    letter-spacing: 0.1em;
    text-indent: 0.1em;
    color: ${color.main};

    @media ${breakpoints.lg} {
      font-size: ${rem(36)};
    }
  }

  > .e-line1,
  > .e-line2,
  > .e-line3,
  > .e-line4 {
    width: 1.5px;
    height: ${rem(24 / 2)};
    background: ${color.main};
    border-radius: 3px;
    position: absolute;
    rotate: -22deg;

    @media ${breakpoints.lg} {
      width: 2px;
      height: ${rem(20)};
    }
  }

  > .e-line1 {
    top: ${rem(-10 / 2)};
    left: ${rem(-46 / 2)};

    @media ${breakpoints.lg} {
      top: ${rem(-10)};
      left: ${rem(-42)};
    }
  }

  > .e-line2 {
    top: ${rem(-10 / 2)};
    left: ${rem(-30 / 2)};

    @media ${breakpoints.lg} {
      top: ${rem(-10)};
      left: ${rem(-30)};
    }
  }

  > .e-line3 {
    bottom: ${rem(-10 / 2)};
    right: ${rem(-26 / 2)};

    @media ${breakpoints.lg} {
      bottom: ${rem(-10)};
      right: ${rem(-26)};
    }
  }

  > .e-line4 {
    bottom: ${rem(-10 / 2)};
    right: ${rem(-42 / 2)};

    @media ${breakpoints.lg} {
      bottom: ${rem(-10)};
      right: ${rem(-38)};
    }
  }
`

export const Support2PointHead: (props: Support2PointHeadProps) => JSX.Element = StyledComponent

export default Support2PointHead
