import classNames from 'classnames'
import React from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { color, fontFamily, rem, breakpoints } from 'variables/_index'

export interface Support1StepHeadProps {
  readonly text: string
  readonly stepImage?: JSX.Element
}

type Support1StepHeadPropsClassName = Support1StepHeadProps & {
  readonly className: string
}

function Component({ className, text, stepImage }: Support1StepHeadPropsClassName) {
  const { ref, inView } = useInView({
    rootMargin: '-100px', // ref要素が現れてから100px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  return (
    <div ref={ref} className={classNames(className, inView && 'is-view')}>
      <div className="e-step-head-text">
        <div className="e-step-head-text-in">{stepImage}</div>
      </div>
      <h6 className="e-step-head-label">
        <div className="e-step-head-label-in">{text}</div>
      </h6>
    </div>
  )
}

const StyledComponent = styled(Component)<Support1StepHeadPropsClassName>`
  position: relative;

  @keyframes highSchoolStepLabel {
    0% {
      opacity: 0;
      translate: -101% 0;
    }

    100% {
      opacity: 1;
      translate: 0 0;
    }
  }

  @keyframes highSchoolStepLabelIn {
    0% {
      opacity: 0;
      translate: 101% 0;
    }

    100% {
      opacity: 1;
      translate: 0 0;
    }
  }

  > .e-step-head-text {
    position: absolute;
    bottom: ${rem(80 / 2)};
    width: fit-content;
    overflow: hidden;
    translate: -101% 0;

    @media ${breakpoints.lg} {
      bottom: ${rem(80)};
    }

    img {
      width: ${rem(160 / 2)};

      @media ${breakpoints.lg} {
        width: ${rem(140)};
      }
    }

    > .e-step-head-text-in {
      width: fit-content;
      translate: 101% 0;
    }
  }

  > .e-step-head-label {
    width: fit-content;
    overflow: hidden;
    translate: -101% 0;

    > .e-step-head-label-in {
      width: fit-content;
      padding: ${rem(4)} ${rem(10)} ${rem(5)};
      border: 1px solid ${color.main};
      background: #fff;
      color: ${color.main};
      font-size: ${rem(36 / 2)};
      letter-spacing: 0.05em;
      text-indent: 0.05em;
      font-family: ${fontFamily.zenKaku};
      font-weight: 700;
      white-space: nowrap;
      translate: 101% 0;

      @media ${breakpoints.lg} {
        padding: ${rem(7)} ${rem(20)} ${rem(9)};
        font-size: ${rem(36)};
        border-width: 2px;
      }
    }
  }

  &.is-view {
    > .e-step-head-text {
      animation: highSchoolStepLabel linear 0.4s forwards 0s;

      > .e-step-head-text-in {
        animation: highSchoolStepLabelIn linear 0.4s forwards 0s;
      }
    }

    > .e-step-head-label {
      animation: highSchoolStepLabel linear 0.6s forwards 0.4s;

      > .e-step-head-label-in {
        animation: highSchoolStepLabelIn linear 0.6s forwards 0.4s;
      }
    }
  }
`

export const Support1StepHead: (props: Support1StepHeadProps) => JSX.Element = StyledComponent

export default Support1StepHead
