import React from 'react'
import Seo from 'components/seo'
import Layout from 'layout/base'
import HighCourseTemplate from 'templates/highCourseTemplate'
import { TypeBreadcrumb } from 'types/breadcrumb'
import { siteStructure } from 'utils/site'
import globalTitle from 'utils/globalTitle'

interface CourseJuniorPageProps {
  pageContext: TypeBreadcrumb
  location: Location
}

const CourseJuniorPage = ({ pageContext, location }: CourseJuniorPageProps): JSX.Element => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  // TODO : 全ページで作っているので共通化したい
  const breadcrumbItems = crumbs
    .filter(crumb => {
      return crumb.crumbLabel !== 'course'
    })
    .map(crumb => {
      return crumb.crumbLabel in siteStructure
        ? {
            ...crumb,
            ...{ crumbLabel: siteStructure[crumb.crumbLabel]?.name },
          }
        : { ...crumb }
    })

  return (
    <Layout pageType="pt0-page" breadcrumbItems={breadcrumbItems} ctaType="type-high-school">
      <Seo
        title={`高校生コース${globalTitle(location.pathname)}`}
        description="受験のプロによるコーチングと基礎学力が身につく定期テスト対策で、行きたい大学の合格を目指します。高1・高2対象。"
        path={location.pathname}
      />
      <HighCourseTemplate />
    </Layout>
  )
}

export default CourseJuniorPage
