import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import Support1StepHead from './support1StepHead'
import Support1StepHeadImage from './support1StepHeadImage'
import { color, fontFamily, rem, breakpoints } from 'variables/_index'

export interface Support1Step3SectionProps {
  readonly noProps?: string
}

type Support1Step3SectionPropsClassName = Support1Step3SectionProps & {
  readonly className: string
}

function Component({ className }: Support1Step3SectionPropsClassName) {
  return (
    <div className={className}>
      <div className="e-step-box-head">
        <Support1StepHead
          text="受験までに「今」やることが明確に"
          stepImage={
            <StaticImage
              src="../../../../../assets/images/course/high-school/support/support1/step/step3-text.png"
              alt=""
              placeholder="none"
              loading="lazy"
            />
          }
        />
      </div>
      <div className="e-step-head-image">
        <Support1StepHeadImage
          teacherImage={
            <StaticImage
              src="../../../../../assets/images/course/high-school/support/support1/step/step3-head.png"
              alt=""
              placeholder="none"
              loading="lazy"
            />
          }
        />
      </div>
      <div className="e-step-box-body">
        <div className="e-step-box-dummy-wrap">
          <div className="e-step-box-lead">
            <div className="e-step-box-lead-in">
              最適化した受験対策を
              <br className="u-hidden-pc" />
              教えてくれる!
            </div>
          </div>
          <div className="e-step-box-content">
            受験までのスケジュールや試験形態を把握している担任が、
            <span className="is-em">「今」どの教科に力を入れて勉強するべきか、いつどんな対策を始めるべきかなどを教えます。</span>
            <br />
            自分の今やるべきことが明確にわかるので受験対策に迷うことがありません。
          </div>
          <div className="e-step-box-image">
            <StaticImage
              src="../../../../../assets/images/course/high-school/support/support1/step/step3-image.png"
              alt=""
              placeholder="none"
              loading="lazy"
            />
          </div>
        </div>
        <div className="e-step-box-voice">
          <div className="e-step-box-voice-illust">
            <StaticImage
              src="../../../../../assets/images/course/high-school/support/support1/step/illust4.png"
              alt=""
              placeholder="none"
              loading="lazy"
            />
          </div>
          <div className="e-step-box-voice-text1">例えば…</div>
          <div className="e-step-box-voice-images">
            <div className="e-step-box-voice-image">
              <svg height="146.899" viewBox="0 0 551.6 146.899" width="551.6" xmlns="http://www.w3.org/2000/svg">
                <g transform="translate(-407.5 -310.7)">
                  <g>
                    <path
                      d="m949.1 436h-520a10.029 10.029 0 0 1 -10-10v-93.6a10.029 10.029 0 0 1 10-10h520a10.029 10.029 0 0 1 10 10v93.6a10.029 10.029 0 0 1 -10 10z"
                      fill="#ffffdc"
                    />
                    <g fill="#00c8ff">
                      <path d="m939 427.3h-468.1a1.5 1.5 0 0 1 0-3h468.1a8.493 8.493 0 0 0 8.5-8.5v-93.6a8.493 8.493 0 0 0 -8.5-8.5h-520a8.493 8.493 0 0 0 -8.5 8.5v78a1.5 1.5 0 0 1 -3 0v-78a11.566 11.566 0 0 1 11.5-11.5h520a11.566 11.566 0 0 1 11.5 11.5v93.6a11.438 11.438 0 0 1 -11.5 11.5z" />
                      <path d="m417.2 457.6a8.187 8.187 0 0 1 -4-1c-2.1-1.2-5.6-4.4-5.6-12.9v-23.2a1.5 1.5 0 0 1 3 0v23.2c0 5.1 1.5 8.8 4.1 10.3a6.119 6.119 0 0 0 6.1-.4c7.1-4.3 28.9-28.3 29.1-28.5a1.487 1.487 0 0 1 2.2 2c-.9 1-22.3 24.5-29.8 29.1a10.894 10.894 0 0 1 -5.1 1.4z" />
                    </g>
                  </g>
                  <g fill="#00c8ff">
                    <path d="m469.7 349.6a15.31 15.31 0 0 1 -5.5 6.3 34.49 34.49 0 0 0 9.2 2.2 13.046 13.046 0 0 0 -1.5 2.3 29.927 29.927 0 0 1 -10.1-3 36.365 36.365 0 0 1 -10.3 3 7.443 7.443 0 0 0 -1.4-2.2 32.516 32.516 0 0 0 9.3-2.3 16.525 16.525 0 0 1 -4.7-4.8l.5-.2h-1.2v-2.2h13.7l.4-.1zm-16.9-.1h-2.3v-5.4h3.9a14.677 14.677 0 0 0 -1.5-3.1l2.1-.6a16.825 16.825 0 0 1 1.7 3.2l-1.4.4h5.6a12.209 12.209 0 0 0 -1.2-3.7l2.2-.5a14.3 14.3 0 0 1 1.3 3.6l-2.2.5h5.2a23.537 23.537 0 0 0 2.1-4.2l2.5.7a29.831 29.831 0 0 1 -2.2 3.5h4.1v5.4h-2.4v-3.2h-17.6v3.4zm18.8-11a150.128 150.128 0 0 1 -20.2 2 8.018 8.018 0 0 0 -.5-2.1c6.7-.3 14.5-.9 19.1-1.9zm-14.4 12.5a13.409 13.409 0 0 0 4.6 3.8 13.244 13.244 0 0 0 4.3-3.8z" />
                    <path d="m479.4 358.4c.9-1.3 1.2-3.5 1.3-5.7l1.4.3c-.1 2.3-.4 4.7-1.4 6.1zm19.3-5.1a9.811 9.811 0 0 0 5.1 5.3 7.005 7.005 0 0 0 -1.5 1.9 10.635 10.635 0 0 1 -5.2-5.8 11.085 11.085 0 0 1 -6 5.9 5.212 5.212 0 0 0 -1.4-1.6c-.1.3-.2.6-.4.7a2.024 2.024 0 0 1 -1.4.8 14.836 14.836 0 0 1 -2.3.1 5.918 5.918 0 0 0 -.5-1.9 14.374 14.374 0 0 0 1.8.1.908.908 0 0 0 .7-.3 8.191 8.191 0 0 0 .6-2.8l-.9.3a14.607 14.607 0 0 0 -1.3-3.4l1-.3a22.069 22.069 0 0 1 1.2 2.7 33.63 33.63 0 0 0 .2-3.6h-7.6v-13.6h9.3v2h-3.1v1.9h2.6v1.6a17.393 17.393 0 0 0 5.9-6.5h2.1a18.032 18.032 0 0 0 6.2 6.1 10.5 10.5 0 0 0 -1.2 2.1 23.068 23.068 0 0 1 -1.9-1.4v1.5h-2.9v1.8h4.8v6.6h-3.9zm-15-.3a27.1 27.1 0 0 1 .4 4.7l-1.2.2a27.453 27.453 0 0 0 -.3-4.8zm-.8-13.3v1.9h2.2v-1.9zm0 3.8v2h2.2v-2zm0 5.9h2.2v-2h-2.2zm2.4 7.7a24.459 24.459 0 0 0 -.9-4.2l1.1-.2a17.417 17.417 0 0 1 1 4.1zm5.3-7.7v.9c-.2 4.8-.4 7.3-.8 8.6a9.523 9.523 0 0 0 5.4-5.5h-4v-6.6h4.6v-1.8h-2.9v-1.5c-.7.6-1.4 1.1-2.1 1.6a6.7 6.7 0 0 0 -1.1-1.8v.2h-2.6v2h2.6v1.9h-2.6v2zm5 2.1a19.418 19.418 0 0 0 .2-2.4v-.5h-2.6v2.9zm4.8-8.4a18.42 18.42 0 0 1 -3.7-4.3 21.316 21.316 0 0 1 -3.4 4.3zm-2.5 5.5v.5a19.267 19.267 0 0 1 -.1 2.4h2.8v-2.9z" />
                    <path d="m519.7 340.6a46.557 46.557 0 0 0 7.6-.8v2.4a74.856 74.856 0 0 1 -8.6.7 27.663 27.663 0 0 1 -2 3.4 7.639 7.639 0 0 1 3.3-.8 3.546 3.546 0 0 1 3.5 2.2c1.6-.8 2.9-1.3 4.2-1.9.9-.4 1.6-.7 2.5-1.1l1.1 2.4a14.766 14.766 0 0 0 -2.7 1c-1.3.5-3 1.2-4.8 2 0 1.5.1 3.6.1 4.8h-2.5c.1-.9.1-2.4.1-3.7-2.2 1.2-3.6 2.4-3.6 4 0 1.8 1.7 2.1 4.3 2.1a46.093 46.093 0 0 0 7.2-.7l-.1 2.6c-1.9.2-4.9.5-7.2.5-3.9 0-6.9-.9-6.9-4.1s3.1-5.2 6.1-6.8a2.083 2.083 0 0 0 -2.1-1.3 6.323 6.323 0 0 0 -4.3 1.9 24.489 24.489 0 0 0 -2.4 2.8l-2.2-1.7a28.488 28.488 0 0 0 5.9-7.6h-.8c-1.1 0-2.9-.1-4.3-.2v-2.4a35.169 35.169 0 0 0 4.5.3h1.5a20.95 20.95 0 0 0 .6-3.1l2.7.2a13.341 13.341 0 0 1 -.7 2.9z" />
                    <path d="m546.5 353.1c-.6-.8-1.7-2.1-2.7-3.2v10.6h-2.4v-9.5a27.3 27.3 0 0 1 -3.3 2.8 7.761 7.761 0 0 0 -1.2-2.2 20.253 20.253 0 0 0 7.3-8h-6.4v-2.2h3.6v-4.8h2.4v4.8h1.7l.4-.1 1.4.9a23.5 23.5 0 0 1 -2.9 5.4c1.1 1 3.1 2.9 3.7 3.4zm12.4 5.2c.5 0 .6-.4.7-3a6.106 6.106 0 0 0 2 .9c-.2 3.3-.8 4.1-2.4 4.1h-2.2c-2 0-2.4-.6-2.4-2.8v-5.1h-1.8c-.4 3.7-1.7 6.5-5.8 8.1a8.466 8.466 0 0 0 -1.4-1.9c3.5-1.2 4.5-3.3 4.9-6.2h-1.9v-14.4h11.1v14.5h-2.9v5.1c0 .6.1.7.6.7zm-8.1-16.1h6.5v-2.3h-6.5zm0 4.1h6.5v-2.3h-6.5zm0 4.2h6.5v-2.3h-6.5z" />
                    <path d="m574.9 356.4 5.1-.6.1 2.1c-4.4.7-9.2 1.3-12.5 1.8l-.3-2.4c1.4-.2 3.2-.4 5.3-.6v-3.4h-4.4v-2.2h4.4v-2.6h-4.4v-10.5h11.3v10.6h-4.5v2.6h4.5v2.2h-4.5v3zm-4.7-14.1h2.6v-2.4h-2.6zm0 4.3h2.6v-2.4h-2.6zm7.2-6.7h-2.6v2.4h2.6zm0 4.3h-2.6v2.4h2.6zm14 2.4a52.446 52.446 0 0 1 -2 6.3l-2-.5c.4-1 .8-2.4 1.2-3.8h-2.4v9.2c0 1.2-.2 2-1.1 2.3a11.492 11.492 0 0 1 -4.2.4 10.883 10.883 0 0 0 -.7-2.4 15.12 15.12 0 0 0 3.1 0c.4 0 .5-.1.5-.4v-9.2h-3.8v-2.3h6.2a30.179 30.179 0 0 0 -5.1-3.4l1.5-1.5c.8.4 1.6.8 2.4 1.3a14.925 14.925 0 0 0 2.3-2.7h-7v-1.9h8.7l.5-.1 1.6 1.1a25.329 25.329 0 0 1 -4.3 5 24.566 24.566 0 0 1 2.2 1.7l-.6.6h1.1l.4-.1z" />
                    <path d="m602.6 340.8a66.608 66.608 0 0 0 -1.3 9.8c0 .9.1 1.8.2 2.8.4-1.1 1-2.3 1.4-3.3l1.3 1a47.52 47.52 0 0 0 -1.8 6.1 2.553 2.553 0 0 0 -.1 1.2 3.752 3.752 0 0 0 .1 1l-2.4.2a33.623 33.623 0 0 1 -1-8.4 73.666 73.666 0 0 1 1.1-10.4 14.982 14.982 0 0 0 .2-2.2l2.9.3a10.566 10.566 0 0 0 -.6 1.9zm6.2 13c0 1.2.9 2.1 4.1 2.1a28.148 28.148 0 0 0 6.5-.7l.1 2.8a36.878 36.878 0 0 1 -6.6.5c-4.4 0-6.4-1.5-6.4-4.1a13.877 13.877 0 0 1 .4-3.2l2.3.2a7.961 7.961 0 0 0 -.4 2.4zm9.8-13.1v2.7a66.121 66.121 0 0 1 -10.9 0v-2.7a56.685 56.685 0 0 0 10.9 0z" />
                    <path d="m639.7 338.2c0 4.7.6 14.4 10.5 20.2a20.32 20.32 0 0 0 -2 2.1 22.391 22.391 0 0 1 -10-13.7c-1.9 6.3-5.2 10.8-10 13.7a19.992 19.992 0 0 0 -2.2-1.9c5.6-2.9 8.7-8 10.2-15.1l1.3.2a17.965 17.965 0 0 1 -.3-3.1h-6.2v-2.4z" />
                    <path d="m662.6 337.7 3 .1c-.1.7-.3 2.1-.5 3.8h.1l.9 1.1c-.3.5-.8 1.2-1.2 1.9 0 .4-.1.7-.1 1.1 2.1-1.8 5.3-4.2 7.8-4.2a3.444 3.444 0 0 1 3.8 3.7c0 2.6-.9 6.8-.9 9.5 0 .8.4 1.4 1.2 1.4a5.716 5.716 0 0 0 3.7-2l.4 2.8a6.66 6.66 0 0 1 -4.8 2.1c-1.8 0-3-1.2-3-3.6 0-2.9 1-6.9 1-9.4a1.827 1.827 0 0 0 -2-2.1c-2 0-5 2.4-7.3 4.9a24.375 24.375 0 0 0 -.1 2.7c0 2.3 0 4.1.1 6.5 0 .5.1 1.2.1 1.8h-2.8a10.286 10.286 0 0 0 .1-1.7c0-2.4 0-3.9.1-6.8-1.2 1.8-2.8 4-3.8 5.5l-1.6-2.3c1.4-1.8 4.3-5.6 5.7-7.7.1-.8.1-1.5.2-2.3-1.2.2-3.4.4-4.9.6l-.3-2.7h1.9c.8-.1 2.2-.2 3.4-.4.1-1.1.1-2 .1-2.3a12 12 0 0 1 -.3-2z" />
                    <path d="m695.6 340.1c-.1.4-.2 1-.3 1.5a35.506 35.506 0 0 0 4.7-.9v2.6a43.388 43.388 0 0 1 -5.2.9c-.3 1.1-.5 2.3-.8 3.4a76.7 76.7 0 0 1 -4.1 12l-2.8-1a65.262 65.262 0 0 0 4.4-11.6c.2-.8.4-1.7.6-2.5-.8 0-1.5.1-2.1.1a29.12 29.12 0 0 1 -3.1-.1l-.1-2.5a18.875 18.875 0 0 0 3.2.2 23.482 23.482 0 0 0 2.6-.1c.1-.8.3-1.5.3-2a9.4 9.4 0 0 0 .1-2.3l3.1.2a20.941 20.941 0 0 0 -.5 2.1zm3.1 14.4c0 1.2.7 2.2 4 2.2a30.519 30.519 0 0 0 5.2-.5l-.1 2.6a36.439 36.439 0 0 1 -5.1.4c-4.2 0-6.5-1.4-6.5-4a18.587 18.587 0 0 1 .4-3.3l2.4.2c-.1.9-.3 1.7-.3 2.4zm4.3-8.9c1.5 0 3.1.1 4.6.2l-.1 2.5a30.426 30.426 0 0 0 -4.4-.3 39.082 39.082 0 0 0 -4.9.3v-2.4a35.284 35.284 0 0 1 4.8-.3z" />
                    <path d="m734.5 343.3a28.063 28.063 0 0 1 -6 10 23.045 23.045 0 0 0 10.2 4.8 9.581 9.581 0 0 0 -1.7 2.4 24.382 24.382 0 0 1 -10.5-5.4 28.821 28.821 0 0 1 -10.3 5.4 15.761 15.761 0 0 0 -1.5-2.4 28.22 28.22 0 0 0 10-4.8 29.406 29.406 0 0 1 -5.8-10.1h-3.9v-2.3h10.4v-4.3h2.5v4.3h10.5v2.3h-3.9zm-13.1 0a22.939 22.939 0 0 0 5.2 8.3 23.782 23.782 0 0 0 5.1-8.3z" />
                    <path d="m753.2 355.2c-2.8 1-5.9 2-8.2 2.8l-.6-2.5c.9-.3 2.1-.6 3.3-1v-6.5h-2.7v-2.2h2.8v-5.2h-3.1v-2.2h8.3v2.2h-2.9v5.2h2.5v2.2h-2.5v5.9l2.7-.9zm15.4 2.3v2.2h-16.6v-2.2h7.5v-3.1h-5.6v-2.2h5.6v-2.7h-5.4v-11.7h13.3v11.7h-5.4v2.7h5.8v2.2h-5.8v3.1zm-12.3-14.8h3.4v-2.8h-3.4zm0 4.8h3.4v-2.8h-3.4zm8.8-7.6h-3.3v2.8h3.3zm0 4.8h-3.3v2.8h3.3z" />
                    <path d="m779.9 355.1c1.1 1.8 3.3 2.7 6.1 2.8 3 .1 9.2.1 12.5-.2a12.518 12.518 0 0 0 -.7 2.3c-3.1.1-8.7.2-11.8.1-3.3-.1-5.5-1-7.1-3a35.332 35.332 0 0 1 -3.4 3.2l-1.2-2.3a35.838 35.838 0 0 0 3.3-2.7v-6.3h-3v-2.2h5.2v8.3zm-1.6-12.2a18.028 18.028 0 0 0 -3.7-4.4l1.9-1.2a16.557 16.557 0 0 1 3.8 4.3zm19.8 10.3h-17.3v-1.8h4.4v-2.1h-3.6v-1.8h3.6v-1.7h2.3v1.7h3.7v-1.7h2.3v1.7h3.7v1.8h-3.7v2.1h4.5v1.8zm-11.6-14h-5.3v-1.6h7.3v4.7h-4.9v1c0 .5.1.6.9.6h2.5c.6 0 .7-.1.8-1.1a4.924 4.924 0 0 0 1.7.6c-.2 1.7-.8 2.2-2.2 2.2h-3c-2.1 0-2.7-.5-2.7-2.2v-2.6h4.8v-1.6zm1.8 14.8a19.982 19.982 0 0 1 -5.2 3.3 14.286 14.286 0 0 0 -1.8-1.5 16.6 16.6 0 0 0 4.8-2.5zm-.7-2.7h3.7v-2.1h-3.7zm7.3-12.1h-5.3v-1.6h7.4v4.7h-4.9v1c0 .5.1.6.9.6h2.6c.6 0 .7-.2.8-1.2a8.653 8.653 0 0 0 1.7.7c-.2 1.7-.8 2.2-2.3 2.2h-3.2c-2.1 0-2.7-.5-2.7-2.3v-2.6h4.8v-1.5zm.5 18.1a26.2 26.2 0 0 0 -4.6-3l1.7-1a28.529 28.529 0 0 1 5.3 3.1z" />
                    <path d="m814.1 350.1c-1.2.3-2.4.6-3.5.9v6.8c0 1.3-.3 2-1.1 2.3a12.368 12.368 0 0 1 -4 .4 9.354 9.354 0 0 0 -.7-2.2 13.3 13.3 0 0 0 2.9 0c.4 0 .5-.1.5-.5v-6.2c-1.2.3-2.4.6-3.5.8l-.6-2.3c1.1-.2 2.5-.5 4-.8v-5.3h-3.6v-2.2h3.6v-5.1h2.3v5.1h3.4v2.2h-3.4v4.7c1.1-.2 2.2-.5 3.3-.8zm8.5-1.3c1 4.3 2.9 8 6.2 9.6a11.184 11.184 0 0 0 -1.7 2.1c-3.8-2.2-5.8-6.4-6.9-11.8h-2.7c-.2 3.9-1 8.6-4 11.7a8.633 8.633 0 0 0 -1.8-1.6c3.2-3.3 3.5-8.2 3.5-12v-8.8h12.2v10.7h-4.8zm-5.1-8.4v6.1h7.4v-6.1z" />
                    <path d="m839.8 341.4 1.4 2.8c3.1-1.4 6.5-2.7 9.2-2.7 3.8 0 6.1 2.2 6.1 5.1 0 3.3-2.2 5.6-6.2 5.6a14.377 14.377 0 0 1 -4-.7l.1-2.6a9.107 9.107 0 0 0 3.6.9c2.5 0 3.7-1.4 3.7-3.2 0-1.5-1.2-2.7-3.5-2.7-2.2 0-5.2 1.2-8.1 2.5.3.7.6 1.3.9 1.9 1.3 3.1 3.1 8.2 4 11.1l-2.8.7a78.423 78.423 0 0 0 -3.5-10.7c-.3-.6-.6-1.3-.9-1.9-2 1-3.7 1.9-4.8 2.4l-1.2-2.6a11.812 11.812 0 0 0 2.3-.8 21.4 21.4 0 0 0 2.8-1.2l-1.3-2.6a9.022 9.022 0 0 0 -1.4-2l2.7-1.1a14.962 14.962 0 0 0 .9 1.8zm3-2.8 1.9-1.4c.9.7 2.8 2.4 3.8 3.3l-1.9 1.5a40.205 40.205 0 0 0 -3.8-3.4z" />
                    <path d="m455.4 395.1c1.1 1.8 3.2 2.6 6.1 2.7s9 .1 12.3-.2a13.492 13.492 0 0 0 -.8 2.4c-3 .1-8.5.2-11.5.1-3.2-.1-5.5-1-7-3-1.1 1.1-2.2 2.1-3.5 3.2l-1.2-2.4a36.333 36.333 0 0 0 3.4-2.7v-6.2h-3.1v-2.2h5.4v8.3zm-1.3-12.1a17.643 17.643 0 0 0 -4-4.3l1.8-1.4a20.17 20.17 0 0 1 4.1 4.2zm4.4 1.9a17.046 17.046 0 0 1 -1.9 1.9 16.177 16.177 0 0 0 -1.8-1.9 19.6 19.6 0 0 0 5.9-8.1l2.4.7a29.617 29.617 0 0 1 -1.5 3.1h3.6a31.675 31.675 0 0 0 1.3-3.6l2.6.5c-.5 1-1 2.1-1.5 3.1h5.1v2.1h-5.5v2.3h4.8v2h-4.8v2.3h4.7v2h-4.7v2.3h5.9v2.1h-14.6zm6.4-2.3h-4v2.3h4zm0 4.3h-4v2.3h4zm0 4.2h-4v2.3h4z" />
                    <path d="m501.9 379.8a19.05 19.05 0 0 1 -3.7 6.9 17.993 17.993 0 0 0 5.7 3.4 7.491 7.491 0 0 0 -1.5 2l-.5-.2v8.5h-2.3v-.8h-5.8v.9h-2.2v-8.5a9.741 9.741 0 0 1 -1.1.5c-.2-.3-.4-.7-.7-1.1h-3v4.1l3.2-.8.2 2.1c-3.6.9-7.3 1.8-10.1 2.5l-.4-2.3a5.019 5.019 0 0 1 1.2-.2v-8.8h2v8.3l1.7-.4v-10.1h-3.6v-8.2h8.6v6.7a18.933 18.933 0 0 0 4.5-7.7l2.3.6a11.92 11.92 0 0 1 -.8 2h4.4l.4-.1zm-18.9 4h4.2v-4h-4.2zm6.7 6.6a17.768 17.768 0 0 0 5.5-3.7 27.3 27.3 0 0 1 -2.1-3 15.911 15.911 0 0 1 -2 2.4 12.7 12.7 0 0 0 -1.7-1.4v1.2h-2.8v3.4h3v1.1zm10.6.7a16.548 16.548 0 0 1 -3.6-2.8 19.347 19.347 0 0 1 -3.6 2.8zm-.7 6.4v-4.3h-5.8v4.3zm-5-16.2-.2.5a16.466 16.466 0 0 0 2.3 3.3 16.035 16.035 0 0 0 2.3-3.8z" />
                    <path d="m514.9 395.1c1.1 1.8 3.3 2.7 6.1 2.8 3 .1 9.2.1 12.5-.2a12.518 12.518 0 0 0 -.7 2.3c-3.1.1-8.7.2-11.8.1-3.3-.1-5.5-1-7.1-3a35.339 35.339 0 0 1 -3.4 3.2l-1.2-2.3a35.843 35.843 0 0 0 3.3-2.7v-6.3h-3v-2.2h5.2v8.3zm-1.5-12.2a18.028 18.028 0 0 0 -3.7-4.4l1.9-1.2a16.558 16.558 0 0 1 3.8 4.3zm19.8 10.3h-17.3v-1.8h4.4v-2.1h-3.6v-1.8h3.6v-1.7h2.3v1.7h3.7v-1.7h2.3v1.7h3.7v1.8h-3.7v2.1h4.5v1.8zm-11.6-14h-5.3v-1.6h7.3v4.7h-4.9v1c0 .5.1.6.9.6h2.5c.6 0 .7-.1.8-1.1a4.924 4.924 0 0 0 1.7.6c-.2 1.7-.8 2.2-2.2 2.2h-3c-2.1 0-2.7-.5-2.7-2.2v-2.6h4.8v-1.6zm1.8 14.8a19.982 19.982 0 0 1 -5.2 3.3 14.286 14.286 0 0 0 -1.8-1.5 16.6 16.6 0 0 0 4.8-2.5zm-.8-2.7h3.7v-2.1h-3.7zm7.4-12.1h-5.3v-1.6h7.4v4.7h-4.9v1c0 .5.1.6.9.6h2.6c.6 0 .7-.2.8-1.2a8.653 8.653 0 0 0 1.7.7c-.2 1.7-.8 2.2-2.3 2.2h-3.2c-2.1 0-2.7-.5-2.7-2.3v-2.6h4.8v-1.5zm.5 18.1a26.2 26.2 0 0 0 -4.6-3l1.7-1a28.529 28.529 0 0 1 5.3 3.1z" />
                    <path d="m549.2 390.1c-1.2.3-2.4.6-3.5.9v6.8c0 1.3-.3 2-1.1 2.3a12.368 12.368 0 0 1 -4 .4 9.354 9.354 0 0 0 -.7-2.2 13.3 13.3 0 0 0 2.9 0c.4 0 .5-.1.5-.5v-6.2c-1.2.3-2.4.6-3.5.8l-.6-2.3c1.1-.2 2.5-.5 4-.8v-5.3h-3.6v-2.2h3.6v-5.1h2.3v5.1h3.4v2.2h-3.4v4.7c1.1-.2 2.2-.5 3.3-.8zm8.4-1.3c1 4.3 2.9 8 6.2 9.6a11.184 11.184 0 0 0 -1.7 2.1c-3.8-2.2-5.8-6.4-6.9-11.8h-2.7c-.2 3.9-1 8.6-4 11.7a8.633 8.633 0 0 0 -1.8-1.6c3.2-3.3 3.5-8.2 3.5-12v-8.8h12.2v10.7h-4.8zm-5-8.4v6.1h7.4v-6.1z" />
                    <path d="m591 382a25.692 25.692 0 0 1 -6.4 7.2l-2.2-1.7a14.991 14.991 0 0 0 5.3-5.4h-14.2c-1 0-2 .1-3.1.2v-2.9a30.283 30.283 0 0 0 3.1.2h14.5a14.978 14.978 0 0 0 2.2-.2l1.6 1.5c-.2.3-.6.8-.8 1.1zm-16.9 17.7-2.4-1.9a9.981 9.981 0 0 0 2.5-1.3c3.7-2.4 4.3-5.7 4.3-10a7.844 7.844 0 0 0 -.2-2.1h3c0 6.8-.4 11.8-7.2 15.3z" />
                    <path d="m601 381.4a28.242 28.242 0 0 0 -.2-2.9h3.2a28.259 28.259 0 0 0 -.2 2.9v4.1a81.668 81.668 0 0 1 11 4.2l-1.1 2.8a64.108 64.108 0 0 0 -9.9-4v8c0 .8.1 2.4.2 3.3h-3.2c.1-.9.2-2.3.2-3.3zm12.1 2-1.8.8a26.039 26.039 0 0 0 -2.3-4l1.7-.8c.7 1.1 1.8 2.8 2.4 4zm3.3-1.4-1.8.9a28.846 28.846 0 0 0 -2.4-3.9l1.7-.8a20.828 20.828 0 0 1 2.5 3.8z" />
                    <path d="m628 380.8 2.9.6a17.41 17.41 0 0 0 -.5 2.2 54.048 54.048 0 0 1 -2.3 8 38.9 38.9 0 0 1 -3.8 7.3l-2.8-1.2a33.7 33.7 0 0 0 4-7.1 45.459 45.459 0 0 0 2.5-9.8zm6.5.6 2.7-.9a81.462 81.462 0 0 1 3.8 8.3c1 2.5 2.4 6.6 3.1 9l-3 1a93.729 93.729 0 0 0 -2.8-9 67.2 67.2 0 0 0 -3.8-8.4zm7.7.2-1.7.7a33.545 33.545 0 0 0 -2.1-3.6l1.7-.7a33.545 33.545 0 0 1 2.1 3.6zm3-1.2-1.7.8c-.6-1.1-1.4-2.6-2.1-3.6l1.7-.7c.7 1 1.6 2.6 2.1 3.5z" />
                    <path d="m661.7 384a31.622 31.622 0 0 0 7.2-6.2l2.2 2.1a44.515 44.515 0 0 1 -7 5.7v10.8a24.046 24.046 0 0 0 .2 3.1h-3.2a24.057 24.057 0 0 0 .2-3.1v-9a49.422 49.422 0 0 1 -9.6 4l-1.3-2.6a42.639 42.639 0 0 0 11.3-4.8z" />
                    <path d="m695.3 382.3a31.987 31.987 0 0 1 -4.3 7.4 64.6 64.6 0 0 1 7.4 7.6l-2.3 2a58.592 58.592 0 0 0 -6.9-7.6 35 35 0 0 1 -10 7.5l-2.1-2.2a29.25 29.25 0 0 0 10.7-7.6 19.989 19.989 0 0 0 4.1-6.9h-9.5a18 18 0 0 0 -2.8.2v-2.9a21.576 21.576 0 0 0 2.8.2h9.6a6.714 6.714 0 0 0 2.3-.3l1.7 1.2a9.141 9.141 0 0 1 -.7 1.4z" />
                  </g>
                </g>
              </svg>
            </div>
            <div className="e-step-box-voice-image">
              <svg height="146.899" viewBox="0 0 551.5 146.899" width="551.5" xmlns="http://www.w3.org/2000/svg">
                <g transform="translate(-407.6 -310.7)">
                  <path
                    d="m429.1 436h520a10.029 10.029 0 0 0 10-10v-93.6a10.029 10.029 0 0 0 -10-10h-520a10.029 10.029 0 0 0 -10 10v93.6a10.029 10.029 0 0 0 10 10z"
                    fill="#ffffdc"
                  />
                  <g fill="#00c8ff">
                    <path d="m887.2 427.3h-468.1a11.566 11.566 0 0 1 -11.5-11.5v-93.6a11.566 11.566 0 0 1 11.5-11.5h520a11.566 11.566 0 0 1 11.5 11.5v78a1.5 1.5 0 0 1 -3 0v-78a8.493 8.493 0 0 0 -8.5-8.5h-520a8.493 8.493 0 0 0 -8.5 8.5v93.6a8.493 8.493 0 0 0 8.5 8.5h468.1a1.5 1.5 0 0 1 0 3z" />
                    <path d="m941 457.6a9.984 9.984 0 0 1 -5.1-1.5c-7.5-4.6-28.9-28.1-29.8-29.1a1.487 1.487 0 1 1 2.2-2c.2.2 22 24.2 29.1 28.5a5.887 5.887 0 0 0 6.1.4c2.6-1.5 4.1-5.2 4.1-10.3v-23.2a1.5 1.5 0 0 1 3 0v23.2c0 8.5-3.5 11.7-5.6 12.9a7.336 7.336 0 0 1 -4 1.1z" />
                    <path d="m473.2 357.5v2.3h-23.1v-2.3h10.3v-6h-7.4v-2.3h7.4v-5.2h-5.8a21.288 21.288 0 0 1 -2.6 4c-.5-.4-1.6-1.1-2.2-1.5 2.1-2.2 3.7-5.8 4.7-9.4l2.5.6c-.4 1.3-.9 2.7-1.4 3.9h4.7v-4.8h2.5v4.8h9v2.4h-9v5.2h8.1v2.3h-7.9v6z" />
                    <path d="m487.4 343a30.239 30.239 0 0 1 -2.1 3.3v14.5h-2.3v-11.8a27.748 27.748 0 0 1 -2.5 2.4 12.664 12.664 0 0 0 -1.3-2.2 22.493 22.493 0 0 0 6.1-6.9zm-.5-5.3a29.913 29.913 0 0 1 -6.1 6.2 20.277 20.277 0 0 0 -1.3-1.8 22.211 22.211 0 0 0 5.3-5.3zm9.7 20.3a19.418 19.418 0 0 0 2.4.2h4.7a19.451 19.451 0 0 0 -.8 2.2h-4.2c-3.9 0-6.8-.8-8.8-4.1a11.127 11.127 0 0 1 -2.6 4.5 13.932 13.932 0 0 0 -1.9-1.5c2.2-2.1 3.1-5.6 3.4-9.8l2.3.2c-.1 1.3-.3 2.5-.5 3.7a6.73 6.73 0 0 0 3.5 4v-9.8h-7.2v-2.1h6.9v-3.3h-5.7v-2.2h5.7v-3.2h2.3v3.2h5.8v2.1h-5.8v3.3h7v2.1h-6.8v3.5h5.7v2.1h-5.7v4.9z" />
                    <path d="m514.9 340.9a66.608 66.608 0 0 0 -1.3 9.8c0 .9.1 1.8.2 2.8.4-1.1 1-2.3 1.4-3.3l1.3 1a47.52 47.52 0 0 0 -1.8 6.1 2.553 2.553 0 0 0 -.1 1.2 3.752 3.752 0 0 0 .1 1l-2.4.2a33.623 33.623 0 0 1 -1-8.4 73.666 73.666 0 0 1 1.1-10.4 14.982 14.982 0 0 0 .2-2.2l2.9.3a6.031 6.031 0 0 0 -.6 1.9zm6.2 13c0 1.2.9 2.1 4.1 2.1a28.148 28.148 0 0 0 6.5-.7l.1 2.8a36.878 36.878 0 0 1 -6.6.5c-4.4 0-6.4-1.5-6.4-4.1a13.877 13.877 0 0 1 .4-3.2l2.3.2a7.961 7.961 0 0 0 -.4 2.4zm9.8-13.1v2.7a66.121 66.121 0 0 1 -10.9 0v-2.7a44.163 44.163 0 0 0 10.9 0z" />
                    <path d="m551.4 336.9a29.081 29.081 0 0 0 11.3 9.1 8.88 8.88 0 0 0 -1.6 2.2 31.916 31.916 0 0 1 -4.2-2.6v1.8h-12.9v-1.8a37.421 37.421 0 0 1 -4.2 2.7 8.96 8.96 0 0 0 -1.5-1.9 30.223 30.223 0 0 0 10.7-9.4h2.4zm-8.9 13.4h16v10.5h-2.5v-1h-11.2v1h-2.4v-10.5zm13.9-5.1a28.7 28.7 0 0 1 -6.1-5.9 25.3 25.3 0 0 1 -5.9 5.9zm-11.6 7.3v5.1h11.2v-5.1z" />
                    <path d="m574.8 337.8 3 .1a20.539 20.539 0 0 0 -.5 3.7l.9 1.1c-.3.5-.8 1.2-1.2 1.9 0 .4-.1.9-.1 1.3a12.915 12.915 0 0 1 8-3 6.728 6.728 0 0 1 7 6.8c0 5.3-3.6 8.2-9.6 9.3l-1.2-2.5c4.5-.7 8.1-2.6 8.1-6.9a4.428 4.428 0 0 0 -4.7-4.3c-2.9 0-5.5 1.4-7.8 3.5a26.238 26.238 0 0 0 -.1 2.7c0 2.3 0 4.1.1 6.5 0 .5.1 1.2.1 1.8h-2.8c0-.5.1-1.3.1-1.7 0-2.4 0-3.9.1-6.8-1.2 1.8-2.8 4-3.8 5.5l-1.6-2.3c1.5-1.8 4.3-5.6 5.7-7.7.1-.7.1-1.5.2-2.2-1.2.2-3.4.4-4.9.6l-.3-2.7h1.9c.8-.1 2.2-.2 3.4-.4.1-1.1.1-2 .1-2.3a14.079 14.079 0 0 0 -.1-2z" />
                    <path d="m616.7 340.5c0 1.1 0 2.2-.1 3.3.9-.1 1.7-.1 2.5-.2a14.978 14.978 0 0 0 2.2-.2v2.5h-2.3c-.7 0-1.5.1-2.5.1-.1 2-.2 4.2-.4 5.4a1.83 1.83 0 0 1 -2.1 1.6 35.966 35.966 0 0 1 -3.6-.4l.1-2.3a13.811 13.811 0 0 0 2.5.3c.6 0 .9-.1.9-.7.1-.8.2-2.3.3-3.8-2.5.2-5.4.5-7.8.7 0 2.5-.1 5.1 0 6.2.1 2.7.2 3.5 4.4 3.5a52.369 52.369 0 0 0 7.7-.6l-.1 2.8c-1.7.2-4.9.4-7.5.4-6.3 0-7-1.4-7.1-5.7v-6.3c-1 .1-1.8.2-2.3.3-.8.1-2.2.3-2.8.4l-.3-2.7c.8 0 1.8-.1 2.9-.2.6-.1 1.4-.1 2.5-.2v-3.2a19.474 19.474 0 0 0 -.2-2.5h2.9a16.991 16.991 0 0 0 -.1 2.4v3.1c2.4-.2 5.2-.5 7.8-.7v-3.4a12.478 12.478 0 0 0 -.2-2.1h2.8c0 .6-.1 1.6-.1 2.2z" />
                    <path d="m637 340.3c-.1.4-.2 1-.3 1.5a35.506 35.506 0 0 0 4.7-.9v2.6a43.388 43.388 0 0 1 -5.2.9c-.3 1.1-.5 2.3-.8 3.4a76.7 76.7 0 0 1 -4.1 12l-2.8-1a65.262 65.262 0 0 0 4.4-11.6c.2-.8.4-1.7.6-2.5-.8 0-1.5.1-2.1.1a29.12 29.12 0 0 1 -3.1-.1l-.1-2.5a18.875 18.875 0 0 0 3.2.2 23.482 23.482 0 0 0 2.6-.1c.1-.8.3-1.5.3-2a9.4 9.4 0 0 0 .1-2.3l3.1.2a15.969 15.969 0 0 0 -.5 2.1zm3.1 14.3c0 1.2.7 2.2 4 2.2a30.519 30.519 0 0 0 5.2-.5l-.1 2.6a36.439 36.439 0 0 1 -5.1.4c-4.2 0-6.5-1.4-6.5-4a18.587 18.587 0 0 1 .4-3.3l2.4.2a12.9 12.9 0 0 0 -.3 2.4zm4.3-8.9c1.5 0 3.1.1 4.6.2l-.1 2.5a30.426 30.426 0 0 0 -4.4-.3 39.082 39.082 0 0 0 -4.9.3v-2.4a34.25 34.25 0 0 1 4.8-.3z" />
                    <path d="m678.2 345.7a29.2 29.2 0 0 1 -4.5 8.8c.9 2.2 1.9 3.6 3 3.6.7 0 1-1.3 1.1-4.7a7.013 7.013 0 0 0 2.1 1.4c-.4 4.6-1.3 5.8-3.4 5.8-1.9 0-3.4-1.5-4.6-3.9a23.217 23.217 0 0 1 -4.9 4 16.243 16.243 0 0 0 -1.8-2 19.579 19.579 0 0 0 5.7-4.6 50.6 50.6 0 0 1 -1.8-10.7h-8.3v3.1h6.5v.9c-.1 5.6-.3 7.9-.8 8.6a2.289 2.289 0 0 1 -1.7.8 26.343 26.343 0 0 1 -3.1.1 6.761 6.761 0 0 0 -.7-2.3c1.1.1 2.1.1 2.5.1a.984.984 0 0 0 .8-.3c.3-.4.4-1.8.5-5.8h-4.2c0 3.7-.5 8.9-2.8 12.2a10.616 10.616 0 0 0 -2-1.7c2.1-3.1 2.3-7.6 2.3-10.9v-7.2h10.7c-.1-1.4-.1-2.8-.1-4.1h2.5c0 1.4 0 2.8.1 4.1h5a20.806 20.806 0 0 0 -4.1-2.7l1.5-1.5a26.913 26.913 0 0 1 4.5 2.9l-1.2 1.3h2.7v2.4h-8.2a54.34 54.34 0 0 0 1.3 8.2 29.908 29.908 0 0 0 3-6.5z" />
                    <path d="m689.1 352a22.659 22.659 0 0 1 -1.7 6.6 10.085 10.085 0 0 0 -1.8-.9 20.972 20.972 0 0 0 1.5-6.1zm2.9-2.1v10.8h-2.1v-10.7c-1.4.1-2.8.2-4 .2l-.1-2.1 1.8-.1c.4-.6.9-1.2 1.4-1.9a26.448 26.448 0 0 0 -3.1-3.7l1.2-1.6a4.62 4.62 0 0 1 .7.7 37.9 37.9 0 0 0 2.1-4.7l2.1.8a52.668 52.668 0 0 1 -3 5.3c.4.5.8 1 1.1 1.4a42.909 42.909 0 0 0 2.6-4.5l2 .9c-1.5 2.3-3.3 5.1-5 7.2 1-.1 2.1-.1 3.2-.2-.3-.7-.7-1.4-1-2l1.7-.7a20.878 20.878 0 0 1 2.4 5.4l-1.9.8a11.306 11.306 0 0 0 -.4-1.5zm1.8 6.8a18.068 18.068 0 0 0 -1.2-4.7l1.7-.5a26.051 26.051 0 0 1 1.4 4.6zm14.6-.2h-8.7l1.8 1.1a21.2 21.2 0 0 1 -6.2 3.1c-.4-.5-1-1.1-1.5-1.6a18.257 18.257 0 0 0 5.6-2.7h-2.8v-10.3h11.7v10.4zm1.4-11.4h-14.8v-1.6h6.1v-1.1h-4.8v-1.5h4.8v-1.1h-5.4v-1.6h5.4v-1.3h2.4v1.3h5.7v1.6h-5.7v1.1h5v1.5h-5v1.1h6.2v1.6zm-3.7 2.6h-7.3v1.4h7.3zm0 2.9h-7.3v1.4h7.3zm0 2.9h-7.3v1.4h7.3zm-1 3c1.7 1 3.7 2.2 4.9 3l-2.1 1.2c-1-.8-2.8-2-4.4-3z" />
                    <path d="m724.3 348.8a3.009 3.009 0 0 1 -3 3 3 3 0 0 1 0-6 3.009 3.009 0 0 1 3 3z" />
                    <path d="m459.6 383h-9.9v-1.9h9.9zm-.9 16.3h-5.7v1.1h-2.1v-8.7h7.8zm-7.8-14.6h7.8v1.9h-7.8zm0 3.4h7.8v1.9h-7.8zm7.7-8.5h-7.6v-1.9h7.6zm-5.7 14v3.7h3.6v-3.7zm20.7-2h-6v9.1h-2.4v-9.1h-6.1v-2.3h6.1v-8.9h-5.1v-2.2h13v2.2h-5.5v8.9h6zm-11.1-9.9a31.944 31.944 0 0 1 1.4 6.2l-2.1.5a29.4 29.4 0 0 0 -1.2-6.2zm6.4 6.1a41.479 41.479 0 0 0 1.6-6.3l2.3.5c-.6 2.2-1.4 4.7-2 6.3z" />
                    <path d="m492.8 397.7a21.748 21.748 0 0 0 3.7.2c1.1 0 5.5 0 7-.1a6.99 6.99 0 0 0 -.9 2.4h-6.2c-5.1 0-8.8-.9-11.2-4.9a14.053 14.053 0 0 1 -3.7 5.4 17.724 17.724 0 0 0 -2-1.7c2.9-2.1 4.2-5.8 4.7-10.3l2.5.2c-.2 1.2-.4 2.3-.6 3.4a7.713 7.713 0 0 0 4.2 4.6v-9.9h-6v-2.3h14.6v2.3h-6.1v3.8h7.4v2.3h-7.4zm-9.7-12.1h-2.4v-6h9.5v-2.8h2.6v2.8h9.6v6h-2.5v-3.7h-16.9v3.7z" />
                    <path d="m531.1 382.2a25.692 25.692 0 0 1 -6.4 7.2l-2.2-1.7a15.866 15.866 0 0 0 5.3-5.4h-14.2c-1 0-2 .1-3.1.2v-2.9a30.282 30.282 0 0 0 3.1.2h14.5a14.978 14.978 0 0 0 2.2-.2l1.6 1.5c-.1.2-.5.7-.8 1.1zm-16.8 17.6-2.4-1.9a9.981 9.981 0 0 0 2.5-1.3c3.7-2.4 4.3-5.7 4.3-10a7.844 7.844 0 0 0 -.2-2.1h3c0 6.8-.4 11.8-7.2 15.3z" />
                    <path d="m542.8 390.7-2.5.9a40.264 40.264 0 0 0 -2.4-5.9l2.4-.9a30.745 30.745 0 0 1 2.5 5.9zm12.7-4a21.749 21.749 0 0 1 -3.6 7.9 19.574 19.574 0 0 1 -8.6 6.3l-2.2-2.2a17.2 17.2 0 0 0 8.5-5.5 18.169 18.169 0 0 0 3.5-8.9l2.9.9a13.123 13.123 0 0 0 -.5 1.5zm-6.9 2.4-2.4.9a43.741 43.741 0 0 0 -2.1-5.7l2.4-.8a53.655 53.655 0 0 1 2.1 5.6z" />
                    <path d="m581.9 376.8a3 3 0 0 1 0 6 1.7 1.7 0 0 1 -.7-.1c0 .2-.1.3-.1.5-.6 2.7-1.8 6.8-3.9 9.5a19.6 19.6 0 0 1 -10.3 7l-2.2-2.4a16.257 16.257 0 0 0 13.4-14.2h-12.8c-1.1 0-2.1.1-2.8.1v-2.9a24.582 24.582 0 0 0 2.8.2h12.6a4.484 4.484 0 0 0 1.1-.1 1.7 1.7 0 0 1 -.1-.7 2.99 2.99 0 0 1 3-2.9zm0 4.6a1.58 1.58 0 0 0 1.6-1.6 1.645 1.645 0 0 0 -1.6-1.6 1.721 1.721 0 0 0 -1.6 1.6 1.579 1.579 0 0 0 1.6 1.6z" />
                    <path d="m602.3 399.6-1.5-2.4a12.05 12.05 0 0 0 2.3-.4c3.3-.8 6.3-3.2 6.3-7.4a6.95 6.95 0 0 0 -6.3-7.3 50.466 50.466 0 0 1 -1.6 7.3c-1.6 5.3-3.7 8.2-6.3 8.2s-4.9-2.9-4.9-7.2c0-5.8 5.1-10.8 11.6-10.8 6.2 0 10.2 4.4 10.2 9.8 0 5.6-3.4 9.3-9.8 10.2zm-7-5c1.2 0 2.4-1.7 3.6-5.7a36.19 36.19 0 0 0 1.4-6.7c-4.7.8-7.4 4.8-7.4 8 .1 3.1 1.3 4.4 2.4 4.4z" />
                    <path d="m628.4 380.3c-.1.4-.2 1-.3 1.5a35.506 35.506 0 0 0 4.7-.9v2.6a43.388 43.388 0 0 1 -5.2.9c-.3 1.1-.5 2.3-.8 3.4a76.7 76.7 0 0 1 -4.1 12l-2.8-1a65.262 65.262 0 0 0 4.4-11.6c.2-.8.4-1.7.6-2.5-.8 0-1.5.1-2.1.1a29.12 29.12 0 0 1 -3.1-.1l-.1-2.5a18.875 18.875 0 0 0 3.2.2 23.482 23.482 0 0 0 2.6-.1c.1-.8.3-1.5.3-2a9.4 9.4 0 0 0 .1-2.3l3.1.2a15.969 15.969 0 0 0 -.5 2.1zm3.1 14.3c0 1.2.7 2.2 4 2.2a30.519 30.519 0 0 0 5.2-.5l-.1 2.6a36.439 36.439 0 0 1 -5.1.4c-4.2 0-6.5-1.4-6.5-4a18.587 18.587 0 0 1 .4-3.3l2.4.2a12.9 12.9 0 0 0 -.3 2.4zm4.3-8.9c1.5 0 3.1.1 4.6.2l-.1 2.5a30.426 30.426 0 0 0 -4.4-.3 39.082 39.082 0 0 0 -4.9.3v-2.4a34.25 34.25 0 0 1 4.8-.3z" />
                    <path d="m663.9 380.7c-.1.5-.2.9-.3 1.4a8.381 8.381 0 0 1 6.9 8.6c0 5.3-3.8 8.5-9.3 9.3l-1.6-2.3c5.6-.5 8.2-3.4 8.2-7a6.064 6.064 0 0 0 -4.9-6.2 30.96 30.96 0 0 1 -3.7 8.6c.5.6 1 1.2 1.5 1.7l-2.1 1.7a12.662 12.662 0 0 1 -1.1-1.3c-1.7 2-3.2 3-5.1 3-2.1 0-3.8-1.7-3.8-4.9a11.219 11.219 0 0 1 3.9-8.1 20.16 20.16 0 0 1 -.7-2.3 18.606 18.606 0 0 0 -1-2.5l2.7-.8a20.473 20.473 0 0 1 .5 2.4c.2.6.3 1.2.5 1.8a12.545 12.545 0 0 1 6.4-1.7c.1-.5.2-1.1.3-1.6a16.356 16.356 0 0 0 .2-2.2l2.8.3a17.711 17.711 0 0 1 -.3 2.1zm-11.1 14.8c1.1 0 2.3-1.1 3.4-2.5-.1-.2-.2-.3-.3-.5a24.446 24.446 0 0 1 -2.4-5.1 8.535 8.535 0 0 0 -2.4 5.6c0 1.5.6 2.5 1.7 2.5zm4.9-4.7a35.318 35.318 0 0 0 2.8-6.5 11.79 11.79 0 0 0 -5 1.6 34.186 34.186 0 0 0 2 4.5c0 .1.1.2.2.4z" />
                    <path d="m689.8 399.6-1.5-2.4a12.05 12.05 0 0 0 2.3-.4c3.3-.8 6.3-3.2 6.3-7.4a6.95 6.95 0 0 0 -6.3-7.3 50.466 50.466 0 0 1 -1.6 7.3c-1.6 5.3-3.7 8.2-6.3 8.2s-4.9-2.9-4.9-7.2c0-5.8 5.1-10.8 11.6-10.8 6.2 0 10.2 4.4 10.2 9.8 0 5.6-3.5 9.3-9.8 10.2zm-7-5c1.2 0 2.4-1.7 3.6-5.7a36.19 36.19 0 0 0 1.4-6.7c-4.7.8-7.4 4.8-7.4 8 0 3.1 1.3 4.4 2.4 4.4z" />
                    <path d="m727.9 382.2a25.692 25.692 0 0 1 -6.4 7.2l-2.2-1.7a15.866 15.866 0 0 0 5.3-5.4h-14.2c-1 0-2 .1-3.1.2v-2.9a30.283 30.283 0 0 0 3.1.2h14.5a14.978 14.978 0 0 0 2.2-.2l1.6 1.5a5.221 5.221 0 0 0 -.8 1.1zm-16.9 17.6-2.4-1.9a9.981 9.981 0 0 0 2.5-1.3c3.7-2.4 4.3-5.7 4.3-10a7.844 7.844 0 0 0 -.2-2.1h3c0 6.8-.4 11.8-7.2 15.3z" />
                    <path d="m737.9 381.6a28.242 28.242 0 0 0 -.2-2.9h3.2a28.259 28.259 0 0 0 -.2 2.9v4.1a81.668 81.668 0 0 1 11 4.2l-1.1 2.8a64.108 64.108 0 0 0 -9.9-4v8c0 .8.1 2.4.2 3.3h-3.2c.1-.9.2-2.3.2-3.3zm12 2-1.8.8a26.039 26.039 0 0 0 -2.3-4l1.7-.8a23.556 23.556 0 0 1 2.4 4zm3.4-1.5-1.8.9a28.846 28.846 0 0 0 -2.4-3.9l1.7-.8a31.1 31.1 0 0 1 2.5 3.8z" />
                    <path d="m764.9 381 2.9.6a17.41 17.41 0 0 0 -.5 2.2 54.048 54.048 0 0 1 -2.3 8 38.9 38.9 0 0 1 -3.8 7.3l-2.8-1.2a33.7 33.7 0 0 0 4-7.1 47 47 0 0 0 2.5-9.8zm6.5.5 2.7-.9a81.462 81.462 0 0 1 3.8 8.3c1 2.5 2.4 6.6 3.1 9l-3 1a93.729 93.729 0 0 0 -2.8-9c-1.1-2.7-2.7-6.4-3.8-8.4zm7.7.2-1.7.7a33.545 33.545 0 0 0 -2.1-3.6l1.7-.7a33.535 33.535 0 0 1 2.1 3.6zm3-1.1-1.7.8c-.6-1.1-1.4-2.6-2.1-3.6l1.7-.7c.6.9 1.6 2.6 2.1 3.5z" />
                    <path d="m798.6 384.2a31.622 31.622 0 0 0 7.2-6.2l2.2 2.1a44.515 44.515 0 0 1 -7 5.7v10.8a24.046 24.046 0 0 0 .2 3.1h-3.2a24.057 24.057 0 0 0 .2-3.1v-9a49.422 49.422 0 0 1 -9.6 4l-1.3-2.6a46.2 46.2 0 0 0 11.3-4.8z" />
                    <path d="m832.2 382.5a31.987 31.987 0 0 1 -4.3 7.4 64.6 64.6 0 0 1 7.4 7.6l-2.3 2a58.592 58.592 0 0 0 -6.9-7.6 35 35 0 0 1 -10 7.5l-2.1-2.2a29.25 29.25 0 0 0 10.7-7.6 19.989 19.989 0 0 0 4.1-6.9h-9.5a18 18 0 0 0 -2.8.2v-2.9a21.576 21.576 0 0 0 2.8.2h9.6a6.714 6.714 0 0 0 2.3-.3l1.7 1.2c-.1.3-.5 1-.7 1.4z" />
                  </g>
                </g>
              </svg>
            </div>
            <div className="e-step-box-voice-image">
              <svg height="146.899" viewBox="0 0 551.6 146.899" width="551.6" xmlns="http://www.w3.org/2000/svg">
                <g transform="translate(-407.5 -310.7)">
                  <g>
                    <path
                      d="m949.1 436h-520a10.029 10.029 0 0 1 -10-10v-93.6a10.029 10.029 0 0 1 10-10h520a10.029 10.029 0 0 1 10 10v93.6a10.029 10.029 0 0 1 -10 10z"
                      fill="#ffffdc"
                    />
                    <g fill="#00c8ff">
                      <path d="m939 427.3h-468.1a1.5 1.5 0 0 1 0-3h468.1a8.493 8.493 0 0 0 8.5-8.5v-93.6a8.493 8.493 0 0 0 -8.5-8.5h-520a8.493 8.493 0 0 0 -8.5 8.5v78a1.5 1.5 0 0 1 -3 0v-78a11.566 11.566 0 0 1 11.5-11.5h520a11.566 11.566 0 0 1 11.5 11.5v93.6a11.438 11.438 0 0 1 -11.5 11.5z" />
                      <path d="m417.2 457.6a8.187 8.187 0 0 1 -4-1c-2.1-1.2-5.6-4.4-5.6-12.9v-23.2a1.5 1.5 0 0 1 3 0v23.2c0 5.1 1.5 8.8 4.1 10.3a6.119 6.119 0 0 0 6.1-.4c7.1-4.3 28.9-28.3 29.1-28.5a1.487 1.487 0 0 1 2.2 2c-.9 1-22.3 24.5-29.8 29.1a10.894 10.894 0 0 1 -5.1 1.4z" />
                    </g>
                  </g>
                  <g fill="#00c8ff">
                    <path d="m469.7 369.9a15.31 15.31 0 0 1 -5.5 6.3 34.49 34.49 0 0 0 9.2 2.2 13.046 13.046 0 0 0 -1.5 2.3 29.927 29.927 0 0 1 -10.1-3 36.365 36.365 0 0 1 -10.3 3 7.443 7.443 0 0 0 -1.4-2.2 32.516 32.516 0 0 0 9.3-2.3 16.525 16.525 0 0 1 -4.7-4.8l.5-.2h-1.2v-2.2h13.7l.4-.1zm-16.9-.1h-2.3v-5.4h3.9a14.677 14.677 0 0 0 -1.5-3.1l2.1-.6a16.825 16.825 0 0 1 1.7 3.2l-1.4.4h5.6a12.209 12.209 0 0 0 -1.2-3.7l2.2-.5a14.3 14.3 0 0 1 1.3 3.6l-2.2.5h5.2a23.537 23.537 0 0 0 2.1-4.2l2.5.7a29.831 29.831 0 0 1 -2.2 3.5h4.1v5.4h-2.4v-3.2h-17.6v3.4zm18.8-11a150.128 150.128 0 0 1 -20.2 2 8.018 8.018 0 0 0 -.5-2.1c6.7-.3 14.5-.9 19.1-1.9zm-14.4 12.4a13.409 13.409 0 0 0 4.6 3.8 13.244 13.244 0 0 0 4.3-3.8z" />
                    <path d="m479.4 378.7c.9-1.3 1.2-3.5 1.3-5.7l1.4.3c-.1 2.3-.4 4.7-1.4 6.1zm19.3-5.1a9.811 9.811 0 0 0 5.1 5.3 7.005 7.005 0 0 0 -1.5 1.9 10.635 10.635 0 0 1 -5.2-5.8 11.085 11.085 0 0 1 -6 5.9 5.212 5.212 0 0 0 -1.4-1.6c-.1.3-.2.6-.4.7a2.024 2.024 0 0 1 -1.4.8 14.836 14.836 0 0 1 -2.3.1 5.918 5.918 0 0 0 -.5-1.9 14.374 14.374 0 0 0 1.8.1.908.908 0 0 0 .7-.3 8.191 8.191 0 0 0 .6-2.8l-.9.3a14.607 14.607 0 0 0 -1.3-3.4l1-.3a22.069 22.069 0 0 1 1.2 2.7 33.63 33.63 0 0 0 .2-3.6h-7.6v-13.7h9.3v2h-3.1v1.9h2.6v1.6a17.393 17.393 0 0 0 5.9-6.5h2.1a18.032 18.032 0 0 0 6.2 6.1 10.5 10.5 0 0 0 -1.2 2.1 23.068 23.068 0 0 1 -1.9-1.4v1.5h-2.9v1.8h4.8v6.6h-3.9zm-15-.3a27.1 27.1 0 0 1 .4 4.7l-1.2.2a27.453 27.453 0 0 0 -.3-4.8zm-.8-13.3v1.9h2.2v-1.9zm0 3.8v2h2.2v-2zm0 5.9h2.2v-2h-2.2zm2.4 7.7a24.459 24.459 0 0 0 -.9-4.2l1.1-.2a17.417 17.417 0 0 1 1 4.1zm5.3-7.7v.9c-.2 4.8-.4 7.3-.8 8.6a9.523 9.523 0 0 0 5.4-5.5h-4v-6.6h4.6v-1.8h-2.9v-1.5c-.7.6-1.4 1.1-2.1 1.6a6.7 6.7 0 0 0 -1.1-1.8v.2h-2.6v2h2.6v1.9h-2.6v2zm5 2.1a19.418 19.418 0 0 0 .2-2.4v-.5h-2.6v2.9zm4.8-8.4a18.42 18.42 0 0 1 -3.7-4.3 21.316 21.316 0 0 1 -3.4 4.3zm-2.5 5.5v.5a19.267 19.267 0 0 1 -.1 2.4h2.8v-2.9z" />
                    <path d="m529.3 378.4c1.7 0 1.9-.4 2.1-2.6a7.607 7.607 0 0 0 2.1.8c-.4 3.1-1.1 3.9-4.1 3.9h-7.4c-3.8 0-4.8-.7-4.8-3.2v-6.1h-1.6c-.4 4-1.4 7.7-5 9.8a6.549 6.549 0 0 0 -1.5-1.8c3.2-1.7 4-4.7 4.3-8h-2.4v-6.1l-.1.1a4.28 4.28 0 0 0 -1.6-1.4 17.689 17.689 0 0 0 4.8-6.9l2.2.4c-.2.5-.4 1-.7 1.6h3.2l.3-.1 1.4.9a31.249 31.249 0 0 1 -1.9 3.6h3.9v-1.1h2.9c0-1.5.1-3.1.1-4.9h2.3c0 1.7 0 3.4-.1 4.9h5.2v1c-.2 8.3-.5 11.3-1.2 12.2a2.7 2.7 0 0 1 -1.7 1 20.177 20.177 0 0 1 -2.7.1 8.647 8.647 0 0 0 -.6-2.3 20.34 20.34 0 0 0 2.2.1.984.984 0 0 0 .8-.3c.4-.6.7-3 .9-9.4h-3c-.4 5.7-1.5 9.9-5 12.6a5.175 5.175 0 0 0 -1.6-1.7c3.1-2.3 4-6 4.3-10.9h-2.7v6.7h-3v6.1c0 1 .4 1.2 2.6 1.2h7.1zm-14.8-17.7a14.37 14.37 0 0 1 -1.8 2.5h3.5c.5-.8 1-1.7 1.4-2.5zm-1.4 8.5h2.5v-4h-2.5zm7.2-4h-2.6v4h2.6z" />
                    <path d="m547.5 369.1a4.593 4.593 0 0 1 -.1 1c-.3 6.4-.6 8.9-1.3 9.8a2.43 2.43 0 0 1 -1.8.9 16.767 16.767 0 0 1 -3.2 0 7.282 7.282 0 0 0 -.7-2.4c1.2.1 2.4.1 2.8.1a1.222 1.222 0 0 0 .9-.3c.4-.5.7-2.3 1-7h-3.3l-.2 1.2-2.1-.3c.3-2.1.7-5.5.8-8.1h4.7v-3.5h-5v-2.2h7.4v7.8h-5.1c-.1 1-.2 2-.3 2.9h5.5zm14.1 11.9a12.633 12.633 0 0 0 -.8-1.8c-4.6.4-9.4.8-12.8 1.1l-.3-2.4c1.8-.1 4-.2 6.5-.4v-3.8h-5.5v-7.2h5.5v-2.4l-5.8.4-.3-2.3 2.2-.1a28.885 28.885 0 0 0 2.6-5.3l2.6.7c-.8 1.5-1.7 3.1-2.6 4.5 1.9-.1 4-.2 6.2-.3a15.954 15.954 0 0 0 -1.6-1.9l2-.9a20.878 20.878 0 0 1 4 5.4l-2.2 1a8.464 8.464 0 0 0 -.9-1.5l-3.9.3v2.6h5.6v7.2h-5.6v3.7l3-.2c-.5-.7-1-1.5-1.5-2.1l2-.9a22.842 22.842 0 0 1 3.7 5.9zm-7.3-9.3v-3.1h-3.3v3.1zm2.3-3.1v3.1h3.3v-3.1z" />
                    <path d="m581.9 379.8-1.5-2.4a12.05 12.05 0 0 0 2.3-.4c3.3-.8 6.3-3.2 6.3-7.4a6.95 6.95 0 0 0 -6.3-7.3 50.466 50.466 0 0 1 -1.6 7.3c-1.6 5.3-3.7 8.2-6.3 8.2s-4.9-2.9-4.9-7.2c0-5.8 5.1-10.8 11.6-10.8 6.2 0 10.2 4.4 10.2 9.8 0 5.5-3.5 9.3-9.8 10.2zm-7-5c1.2 0 2.4-1.7 3.6-5.7a36.19 36.19 0 0 0 1.4-6.7c-4.7.8-7.4 4.8-7.4 8 0 3 1.3 4.4 2.4 4.4z" />
                    <path d="m603.8 361.6 1.4 2.8c3.1-1.4 6.5-2.7 9.2-2.7 3.8 0 6.1 2.2 6.1 5.1 0 3.3-2.2 5.6-6.2 5.6a14.377 14.377 0 0 1 -4-.7l.1-2.6a9.107 9.107 0 0 0 3.6.9c2.5 0 3.7-1.4 3.7-3.2 0-1.5-1.2-2.7-3.5-2.7-2.2 0-5.2 1.2-8.1 2.5.3.7.6 1.3.9 1.9 1.3 3.1 3.1 8.2 4 11.1l-2.8.7a78.423 78.423 0 0 0 -3.5-10.7c-.3-.6-.6-1.3-.9-1.9-1.9 1-3.7 1.9-4.8 2.4l-1.2-2.6a11.812 11.812 0 0 0 2.3-.8 21.4 21.4 0 0 0 2.8-1.2l-1.3-2.6a9.022 9.022 0 0 0 -1.4-2l2.7-1.1a7.573 7.573 0 0 0 .9 1.8zm3-2.8 1.9-1.4c.9.7 2.8 2.4 3.8 3.3l-1.9 1.5c-1-.9-2.7-2.6-3.8-3.4z" />
                    <path d="m631.9 364.5c1.1-2.4 3.6-5.1 6.8-5.1 3.5 0 6.2 3.5 6.2 9.1 0 7.3-4.5 10.6-11.2 11.8l-1.6-2.4c5.7-.9 10-3 10-9.4 0-4.1-1.4-6.7-3.8-6.7-3.2 0-6.1 4.7-6.3 8.1a10.43 10.43 0 0 0 .1 3l-2.5.2a38.938 38.938 0 0 1 -.3-4.2 59.681 59.681 0 0 1 .8-8.4 22.863 22.863 0 0 0 .2-2.5l2.8.1a47.968 47.968 0 0 0 -1.2 6.4z" />
                    <path d="m662.9 363.6c-.1 1.3-.2 2.6-.3 3.8h10.1a5.386 5.386 0 0 1 -.1 1.1c-.5 7.2-1 10.1-2 11.2a3.492 3.492 0 0 1 -2.4 1 31.7 31.7 0 0 1 -4.4 0 5.686 5.686 0 0 0 -.8-2.4 40.023 40.023 0 0 0 4.3.2 1.923 1.923 0 0 0 1.2-.3c.7-.6 1.2-3 1.6-8.5h-7.9c-.9 4.5-3 8.7-8.3 11.2a10.614 10.614 0 0 0 -1.7-2c7.1-3.2 7.8-9.6 8.1-15.4h-7.7v-2.3h10.2v-4.4h2.5v4.4h10.5v2.3h-12.9z" />
                    <path d="m691.3 370.2-3.2.9v7c0 1.2-.3 1.9-1 2.3a8.627 8.627 0 0 1 -3.6.4 9.354 9.354 0 0 0 -.7-2.2 9.858 9.858 0 0 0 2.5 0c.4 0 .5-.1.5-.5v-6.3c-1.1.3-2.2.6-3.1.9l-.6-2.3c1-.3 2.3-.5 3.8-.9v-5.2h-3.4v-2.3h3.4v-5h2.4v5h2.8v2.2h-3.1v4.5c1-.3 2-.5 2.9-.8zm1.1-.9h12.3v11.3h-2.4v-1h-7.6v1.2h-2.3zm9.5-4c1.4 0 1.6-.5 1.7-3.1a8.487 8.487 0 0 0 2.2.9c-.3 3.5-1 4.4-3.8 4.4h-5.6c-3.3 0-4.1-.7-4.1-3.2v-7.3h2.4v3.7a35.165 35.165 0 0 0 7.7-2.4l1.8 1.8a43.977 43.977 0 0 1 -9.5 2.5v1.6c0 .9.3 1.1 1.9 1.1zm-7.3 6v2.2h7.6v-2.2zm7.7 6.4v-2.3h-7.6v2.3z" />
                    <path d="m730 373.8h5.6v1.9h-5.6v2.7c0 1.2-.2 1.8-1.1 2.1a15.581 15.581 0 0 1 -4.3.3 6.05 6.05 0 0 0 -.8-2.1 17.057 17.057 0 0 0 3.3 0c.4 0 .5-.1.5-.4v-2.6h-9a19.109 19.109 0 0 1 3.3 2.7l-1.8 1.5a19.192 19.192 0 0 0 -3.6-3.1l1.3-1.1h-5.7v-1.9h15.5v-1.4c-1.9 0-3.7 0-4.9-.1-2.7-.1-4.5-.8-5.9-2.3-1.2.8-2.5 1.7-3.9 2.6l-.9-2c1-.5 2.3-1.2 3.4-1.9v-3.4h-3.2v-1.8h5.4v4.9c.9 1.5 2.7 2.1 5 2.2 3 .1 9.8 0 13.2-.2a10.561 10.561 0 0 0 -.6 1.9c-1.4.1-3.3.1-5.3.1v1.4zm-13.5-11.8a15.964 15.964 0 0 0 -3.5-3.3l1.6-1.3a16.368 16.368 0 0 1 3.6 3.2zm8.9 0c.1-.3.2-.7.3-1.1h-6.9v-1.7h4.1c-.3-.6-.7-1.3-1-1.8l2-.4a10.889 10.889 0 0 1 1.4 2.3h4a13.385 13.385 0 0 0 1.3-2.3l2.3.4a14.019 14.019 0 0 1 -1.3 1.8h4.2v1.7h-7.6a7.6 7.6 0 0 0 -.4 1.1h6v7.6h-12.7v-7.6zm5.7 1.2h-8.1v1h8.1zm0 2.1h-8.1v1h8.1zm0 2.1h-8.1v1h8.1z" />
                  </g>
                </g>
              </svg>
            </div>
            <div className="e-step-box-voice-image">
              <svg height="146.899" viewBox="0 0 551.5 146.899" width="551.5" xmlns="http://www.w3.org/2000/svg">
                <g transform="translate(-407.6 -310.7)">
                  <path
                    d="m429.1 436h520a10.029 10.029 0 0 0 10-10v-93.6a10.029 10.029 0 0 0 -10-10h-520a10.029 10.029 0 0 0 -10 10v93.6a10.029 10.029 0 0 0 10 10z"
                    fill="#ffffdc"
                  />
                  <g fill="#00c8ff">
                    <path d="m887.2 427.3h-468.1a11.566 11.566 0 0 1 -11.5-11.5v-93.6a11.566 11.566 0 0 1 11.5-11.5h520a11.566 11.566 0 0 1 11.5 11.5v78a1.5 1.5 0 0 1 -3 0v-78a8.493 8.493 0 0 0 -8.5-8.5h-520a8.493 8.493 0 0 0 -8.5 8.5v93.6a8.493 8.493 0 0 0 8.5 8.5h468.1a1.5 1.5 0 0 1 0 3z" />
                    <path d="m941 457.6a9.984 9.984 0 0 1 -5.1-1.5c-7.5-4.6-28.9-28.1-29.8-29.1a1.487 1.487 0 1 1 2.2-2c.2.2 22 24.2 29.1 28.5a5.887 5.887 0 0 0 6.1.4c2.6-1.5 4.1-5.2 4.1-10.3v-23.2a1.5 1.5 0 0 1 3 0v23.2c0 8.5-3.5 11.7-5.6 12.9a7.336 7.336 0 0 1 -4 1.1z" />
                    <path d="m454.1 341.1h14.2a16.412 16.412 0 0 0 2.3-.2v2.9a17.7 17.7 0 0 0 -2.3-.1h-5.9v11.3h7.3a16.412 16.412 0 0 0 2.3-.2v3a18.483 18.483 0 0 0 -2.3-.1h-17.2a19.267 19.267 0 0 0 -2.4.1v-3a21.156 21.156 0 0 0 2.4.2h7v-11.4h-5.5a18.482 18.482 0 0 0 -2.3.1v-2.9a11.21 11.21 0 0 0 2.4.3z" />
                    <path d="m489.7 353a25.039 25.039 0 0 0 9-9.8l1.6 2.8a27.071 27.071 0 0 1 -9.1 9.4 29.829 29.829 0 0 1 -10 3.9l-1.7-2.7a25.358 25.358 0 0 0 10.2-3.6zm-.9-8.3-2.1 2.1a60.548 60.548 0 0 0 -6.5-5.5l1.9-2a58.589 58.589 0 0 1 6.7 5.4z" />
                    <path d="m509.1 341.2c0-.8-.1-2-.2-2.9h3.2a28.25 28.25 0 0 0 -.2 2.9v4.1a76.8 76.8 0 0 1 11 4.2l-1.2 2.8a75.346 75.346 0 0 0 -9.8-4.1v8c0 .8.1 2.4.2 3.3h-3.2c.1-.9.2-2.3.2-3.3z" />
                    <path d="m534.9 340.9v8.7c0 .7.1 1.6.1 2.1h-3c0-.4.1-1.4.1-2.1v-8.7a12.1 12.1 0 0 0 -.1-1.9h3a10.32 10.32 0 0 0 -.1 1.9zm12 .2v4.6c0 6-.9 8.2-2.9 10.3a13.778 13.778 0 0 1 -6.2 3.8l-2.2-2.3a11.5 11.5 0 0 0 6.2-3.4c1.8-2.1 2.2-4.1 2.2-8.7v-4.5a16.586 16.586 0 0 0 -.2-2.4h3.1a22.544 22.544 0 0 1 0 2.6z" />
                    <path d="m559.8 347.2h14.2a19.532 19.532 0 0 0 2.9-.2v3.2c-.6 0-1.8-.1-2.9-.1h-14.1c-1.4 0-3 .1-3.9.1v-3.2a36.253 36.253 0 0 0 3.8.2z" />
                    <path d="m592.6 347.8-1.5 2.3a47.39 47.39 0 0 0 -5.8-3.6l1.5-2.2c1.6.8 4.6 2.7 5.8 3.5zm2.6 6.2a28.025 28.025 0 0 0 10-9.6l1.6 2.8a30.7 30.7 0 0 1 -10.2 9.3 30.385 30.385 0 0 1 -8.6 3.2l-1.5-2.7a32.112 32.112 0 0 0 8.7-3zm1.3-11.9-1.5 2.2a62.282 62.282 0 0 0 -5.7-3.7l1.5-2.2c1.6.9 4.5 2.8 5.7 3.7z" />
                    <path d="m617.3 347.2h14.1a19.532 19.532 0 0 0 2.9-.2v3.2c-.6 0-1.8-.1-2.9-.1h-14.1c-1.4 0-3 .1-3.9.1v-3.2c.9.1 2.5.2 3.9.2z" />
                    <path d="m645.2 341.2c0-.8-.1-2-.2-2.9h3.2a28.259 28.259 0 0 0 -.2 2.9v4.1a76.8 76.8 0 0 1 11 4.2l-1.2 2.8a75.346 75.346 0 0 0 -9.8-4.1v8c0 .8.1 2.4.2 3.3h-3.2c.1-.9.2-2.3.2-3.3z" />
                    <path d="m674 348.8a3.009 3.009 0 0 1 -3 3 3 3 0 0 1 0-6 3.009 3.009 0 0 1 3 3z" />
                    <path d="m695.5 340.7c-.3 1-.6 2-.9 2.9h10.1v17.1h-2.5v-1.4h-15.8v1.4h-2.3v-17.1h8c.2-.9.4-1.9.6-2.9h-9.7v-2.3h23v2.3zm-9.1 16.4h3.2v-11.3h-3.2zm10.3-11.3h-4.8v2.4h4.8zm0 4.4h-4.8v2.5h4.8zm-4.8 6.9h4.8v-2.6h-4.8zm10.4-11.3h-3.4v11.3h3.4z" />
                    <path d="m728.3 347.5c-.3.7-.7 1.5-1 2.2h8.8v2.1h-3a10.314 10.314 0 0 1 -2.1 4.6 33.4 33.4 0 0 1 4.7 2.5l-1.6 1.8a33.964 33.964 0 0 0 -4.9-2.8c-2 1.4-4.9 2.2-8.8 2.8a9.263 9.263 0 0 0 -.9-2 21.027 21.027 0 0 0 7.4-1.8c-.9-.4-1.8-.7-2.7-1l-.3.6-2.1-.7c.6-1.1 1.3-2.5 2.1-4h-3.5v-1.4a17.228 17.228 0 0 0 -2.1.7v6.9c0 1.3-.3 2-1 2.3a8.319 8.319 0 0 1 -3.6.5 10.1 10.1 0 0 0 -.7-2.3h2.5c.3 0 .5-.1.5-.5v-6.2l-3.2.9-.5-2.4c1-.2 2.3-.6 3.8-1v-5.2h-3.3v-2.2h3.3v-5h2.3v5h2.3v2.2h-2.3v4.6l2.2-.6.2 1.7h4.1c.3-.8.7-1.5 1-2.2h-5.6v-2.1h4.2a18.072 18.072 0 0 0 -.9-3.7l.9-.2h-3.2v-2.1h6v-2.7h2.4v2.7h6.1v2.1h-2.2c-.5 1.4-1 2.8-1.5 3.9h4.4v2.1h-8.2zm-2 4.3-1.2 2.4a27.2 27.2 0 0 1 3.7 1.3 8.68 8.68 0 0 0 1.9-3.7zm-.7-10.3a15.334 15.334 0 0 1 1 3.8l-.4.1h3.5c.4-1.1 1-2.7 1.3-3.9z" />
                    <path d="m750.7 348.8a3.009 3.009 0 0 1 -3 3 3 3 0 0 1 0-6 3.009 3.009 0 0 1 3 3z" />
                    <path d="m765.9 343.6c-.9 3.8-2.3 8.5-4.6 11.4-.6-.4-1.7-1-2.3-1.3 2.2-2.7 3.6-7.2 4.2-10.6zm6.6-6.4v20.3c0 1.6-.4 2.3-1.4 2.7a14.254 14.254 0 0 1 -5.2.4 13.043 13.043 0 0 0 -1-2.5c1.8.1 3.7.1 4.2.1s.7-.2.7-.7v-20.3zm6 5.7a43.188 43.188 0 0 1 5 11.5l-2.6 1c-.5-3.1-2.6-7.9-4.7-11.6z" />
                    <path d="m798 343h-8.9v-1.9h8.9zm-.7 16.2h-5.1v1.1h-2v-8.7h7.1zm-.1-12.7h-7v-1.9h7zm-7 1.6h7v1.9h-7zm7-8.5h-6.8v-1.9h6.8zm-5.1 14v3.7h3.1v-3.7zm14.1-16.7a21.524 21.524 0 0 0 7.1 6.7 7.434 7.434 0 0 0 -1.3 2.1 16.293 16.293 0 0 1 -2.2-1.7v1.8h-8.9v-1.7a18.758 18.758 0 0 1 -2.4 1.8 13.238 13.238 0 0 0 -1.2-2 19.718 19.718 0 0 0 6.8-7zm5.6 21.6c0 .9-.2 1.5-.8 1.8a5.158 5.158 0 0 1 -2.4.3 6.97 6.97 0 0 0 -.6-1.9h1.4a.265.265 0 0 0 .3-.3v-3.4h-1.9v5.3h-1.7v-5.3h-1.8v5.3h-1.6v-5.3h-1.7v5.6h-2.1v-12.8h12.9zm-9.1-5.5v-3.3h-1.7v3.3zm6.8-9.2a27.009 27.009 0 0 1 -4.3-4.8 27.451 27.451 0 0 1 -4 4.8zm-3.3 9.2v-3.3h-1.8v3.3zm3.5 0v-3.3h-1.9v3.3z" />
                    <path d="m838.8 343.4a28.063 28.063 0 0 1 -6 10 23.045 23.045 0 0 0 10.2 4.8 9.581 9.581 0 0 0 -1.7 2.4 24.382 24.382 0 0 1 -10.5-5.4 28.821 28.821 0 0 1 -10.3 5.4 15.761 15.761 0 0 0 -1.5-2.4 28.22 28.22 0 0 0 10-4.8 29.406 29.406 0 0 1 -5.8-10.1h-3.8v-2.3h10.4v-4.3h2.5v4.3h10.5v2.3h-4zm-13.1 0a22.939 22.939 0 0 0 5.2 8.3 23.782 23.782 0 0 0 5.1-8.3z" />
                    <path d="m859.3 344.1a34.055 34.055 0 0 1 -2.2 7.8 30.609 30.609 0 0 1 2.8 4.3l-2 1.6c-.5-1-1.2-2.2-2-3.5a17.243 17.243 0 0 1 -5.6 6.1 13.451 13.451 0 0 0 -1.6-1.9 16.017 16.017 0 0 0 5.8-6.3c-1.4-1.9-3-3.8-4.4-5.4l1.7-1.4c1.2 1.3 2.5 2.8 3.8 4.3a33.061 33.061 0 0 0 1.4-6zm13.4 1h-2.9v12.4c0 1.5-.3 2.2-1.2 2.6a12.2 12.2 0 0 1 -4.5.5 9.61 9.61 0 0 0 -.8-2.6 17.959 17.959 0 0 0 3.4 0c.4 0 .6-.1.6-.6v-12.4h-6.8v-2h-11.3v-2h4.7v-4.1h2.3v4.1h5v1.8h6v-6h2.4v6h2.9v2.3zm-9.3 9.1a22.3 22.3 0 0 0 -2.7-5.7l2-.9a19.548 19.548 0 0 1 2.8 5.6z" />
                    <path d="m892.2 352.5a21.947 21.947 0 0 0 10.6 5.2 10.091 10.091 0 0 0 -1.5 2.1 22.482 22.482 0 0 1 -9.1-4.8v5.7h-2.6v-5.7a22.328 22.328 0 0 1 -9.2 5 9.509 9.509 0 0 0 -1.5-2c4.3-.9 8.5-3.1 10.7-5.7v-2.1h-5.8v4.8h-2.5v-7h8.3v-1.7h-10.1v-2.1h10.1v-1.2a9.218 9.218 0 0 0 -.8-.5 19.2 19.2 0 0 0 1.5-1.4h-4a11.2 11.2 0 0 1 1.1 2.2l-2.2.8a14.466 14.466 0 0 0 -1.3-2.9h-.9a17.214 17.214 0 0 1 -2.3 2.8 17.3 17.3 0 0 0 -2-1.4 16.24 16.24 0 0 0 4-5.8l2.3.6a13.021 13.021 0 0 1 -.8 1.7h6.2v1.9a13.489 13.489 0 0 0 2.4-4.2l2.4.5a12.2 12.2 0 0 1 -.7 1.7h7.8v2.1h-4.8a22.039 22.039 0 0 1 1.1 2.1l-2.2.7a14.607 14.607 0 0 0 -1.4-2.8h-1.7c-.4.6-.8 1.1-1.2 1.7h.3v1.5h9.6v2.1h-9.6v1.7h8.2v4.6c0 1.1-.2 1.6-1 1.9a10.262 10.262 0 0 1 -3.3.3 10.839 10.839 0 0 0 -.8-1.9 8.4 8.4 0 0 0 2.3 0c.3 0 .4-.1.4-.3v-2.5h-5.8v2.3z" />
                    <path d="m470.2 379.4.8.8-8.6 8.6 8.6 8.6-.8.8-8.6-8.6-8.6 8.6-.8-.8 8.6-8.6-8.6-8.6.8-.8 8.6 8.6zm-16 11.3a2 2 0 1 1 2-2 2.006 2.006 0 0 1 -2 2zm5.5-9.4a2.006 2.006 0 0 1 2-2 1.923 1.923 0 0 1 1.9 2 1.951 1.951 0 1 1 -3.9 0zm3.9 14.9a1.951 1.951 0 1 1 -1.9-2 1.988 1.988 0 0 1 1.9 2zm5.5-9.4a1.923 1.923 0 0 1 1.9 2 1.951 1.951 0 1 1 -1.9-2z" />
                    <path d="m502.8 379.1v2.1h-22.4v-2.1h9.9v-2.3h2.5v2.3zm-.9 18.9c0 1.2-.3 1.9-1.1 2.3a12.11 12.11 0 0 1 -4.3.4 5.838 5.838 0 0 0 -.8-2.2h-6.9v1.2h-2.1v-6.9h9.8v5.6h2.4c.4 0 .5-.1.5-.4v-6.6h-15.7v9.3h-2.4v-11.4h20.5v8.7zm-3.4-15.5v5.4h-14v-5.4zm-2.4 1.7h-9.2v2.1h9.2zm-1.8 10.3h-5.6v2.2h5.6z" />
                    <path d="m509.2 396.4 1.5-1.9a6.211 6.211 0 0 0 4.5 2.1c2.2 0 3.6-1.2 3.6-3 0-2.1-1.4-3.4-5.7-3.4v-2.2c3.8 0 5-1.4 5-3.2a2.58 2.58 0 0 0 -2.9-2.7 5.274 5.274 0 0 0 -3.8 1.8l-1.6-1.9a7.877 7.877 0 0 1 5.5-2.3c3.4 0 5.9 1.8 5.9 4.8a4.386 4.386 0 0 1 -3.3 4.3v.1a4.829 4.829 0 0 1 4 4.7c0 3.4-2.9 5.3-6.4 5.3a7.723 7.723 0 0 1 -6.3-2.5z" />
                    <path d="m551.8 397.5v2.3h-23.1v-2.3h10.3v-6h-7.4v-2.3h7.4v-5.2h-5.8a21.289 21.289 0 0 1 -2.6 4c-.5-.4-1.6-1.1-2.2-1.5 2.1-2.2 3.7-5.8 4.7-9.4l2.5.6c-.4 1.3-.9 2.7-1.4 3.9h4.7v-4.8h2.5v4.8h9v2.4h-9v5.2h8.1v2.3h-8.1v6z" />
                    <path d="m570.7 399.6-1.5-2.4a12.05 12.05 0 0 0 2.3-.4c3.3-.8 6.3-3.2 6.3-7.4a6.95 6.95 0 0 0 -6.3-7.3 50.466 50.466 0 0 1 -1.6 7.3c-1.6 5.3-3.7 8.2-6.3 8.2s-4.9-2.9-4.9-7.2c0-5.8 5.1-10.8 11.6-10.8 6.2 0 10.2 4.4 10.2 9.8 0 5.6-3.5 9.3-9.8 10.2zm-7-5c1.2 0 2.4-1.7 3.6-5.7a36.19 36.19 0 0 0 1.4-6.7c-4.7.8-7.4 4.8-7.4 8 0 3.1 1.3 4.4 2.4 4.4z" />
                    <path d="m593.7 379.4c1.4 0 4.6-.2 5.7-.2.5-.1 1.1-.2 1.5-.3l1.6 1.3a3.916 3.916 0 0 0 -.8 1 57.307 57.307 0 0 0 -2.8 5.9 21.454 21.454 0 0 1 6.5 1.9c.1-.5.1-1 .2-1.5a11.3 11.3 0 0 0 0-2.6l2.7.3c-.1 1.8-.3 3.5-.5 4.9a32.249 32.249 0 0 1 3 1.9l-1.2 2.8c-.8-.6-1.6-1.3-2.5-1.9a12.392 12.392 0 0 1 -6.7 7.4l-2.2-1.8a11.076 11.076 0 0 0 6.7-7 19.39 19.39 0 0 0 -6.9-2.3 70.625 70.625 0 0 1 -3.1 6.6 4.123 4.123 0 0 1 -3.6 2 3.521 3.521 0 0 1 -3.6-3.8c0-3.9 3.6-6.9 8.7-7.1.9-1.9 1.7-3.9 2.4-5.4-1 0-3.8.2-5 .2a15.028 15.028 0 0 0 -2 .2l-.1-2.7a6.8 6.8 0 0 0 2 .2zm-2.4 15.8a1.777 1.777 0 0 0 1.5-.9 30.331 30.331 0 0 0 2.5-5.1c-3.4.4-5.4 2.4-5.4 4.4a1.425 1.425 0 0 0 1.4 1.6z" />
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <div className="e-step-box-voice-text2">など、合格までをしっかりサポート!</div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<Support1Step3SectionPropsClassName>`
  background: #edfbff;
  border-radius: ${rem(10 / 2)};
  position: relative;
  padding-top: ${rem(60 / 2)};
  padding-bottom: ${rem(0)};

  @media ${breakpoints.lg} {
    border-radius: ${rem(10)};
    padding-top: ${rem(90)};
    padding-bottom: ${rem(100)};
  }

  > .e-step-box-head {
    padding-left: ${rem(0)};
    position: absolute;
    bottom: calc(100% - ${rem(20 / 2)});
    z-index: 2;

    @media ${breakpoints.lg} {
      bottom: calc(100% - ${rem(30)});
    }
  }

  > .e-step-head-image {
    text-align: center;
    position: absolute;
    bottom: 100%;
    width: 100%;
  }

  > .e-step-box-body {
    padding-left: ${rem(60 / 2)};
    padding-right: ${rem(60 / 2)};
    display: flex;
    flex-direction: column;

    @media ${breakpoints.lg} {
      padding-left: ${rem(60)};
      padding-right: ${rem(60)};
      display: flex;
      gap: ${rem(60)};
      flex-direction: row;
    }

    > .e-step-box-dummy-wrap {
      display: contents;

      @media ${breakpoints.lg} {
        width: calc(50% - ${rem(60 / 2)});
        display: flex;
        flex-direction: column;
      }

      > .e-step-box-lead {
        font-size: ${rem(36 / 2)};
        font-family: ${fontFamily.zenKaku};
        line-height: 2;
        color: ${color.main};
        letter-spacing: 0.05em;
        font-weight: 700;
        position: relative;

        @media ${breakpoints.lg} {
          font-size: ${rem(24)};
        }

        > .e-step-box-lead-in {
          display: inline;
          border-bottom: 1px solid currentColor;
          white-space: nowrap;

          @media ${breakpoints.lg} {
            border-bottom-width: 2px;
          }
        }
      }

      > .e-step-box-content {
        line-height: 2;
        font-size: ${rem(32 / 2)};
        order: 1;
        text-align: justify;
        margin-top: ${rem(30 / 2)};

        @media ${breakpoints.lg} {
          font-size: ${rem(18)};
          margin-top: ${rem(30)};
        }

        .is-em {
          background: linear-gradient(transparent 82%, #b2eeff 0);
        }
      }

      > .e-step-box-image {
        margin-top: ${rem(40 / 2)};
        margin-left: ${rem(-20 / 2)};
        margin-right: ${rem(-20 / 2)};
        order: 3;

        @media ${breakpoints.lg} {
          margin-top: auto;
          margin-left: ${rem(0)};
          margin-right: ${rem(0)};
        }

        img {
          width: ${rem(590 / 2)};

          @media ${breakpoints.lg} {
            width: ${rem(410)};
          }
        }
      }
    }

    > .e-step-box-voice {
      position: relative;
      margin-top: ${rem(60 / 2)};
      order: 2;

      @media ${breakpoints.lg} {
        margin-top: ${rem(0)};
        width: calc(50% - ${rem(60 / 2)});
      }

      > .e-step-box-voice-illust {
        position: absolute;
        right: 0;
        top: ${rem(-50 / 2)};

        @media ${breakpoints.lg} {
          right: ${rem(0)};
          top: ${rem(-50)};
        }

        img {
          width: ${rem(90 / 2)};

          @media ${breakpoints.lg} {
            width: ${rem(64)};
          }
        }
      }

      > .e-step-box-voice-text1,
      > .e-step-box-voice-text2 {
        color: ${color.main};
        font-size: ${rem(32 / 2)};
        letter-spacing: 0.05em;
        text-indent: 0.05em;

        @media ${breakpoints.lg} {
          font-size: ${rem(18)};
        }
      }

      > .e-step-box-voice-text2 {
        margin-top: ${rem(20 / 2)};

        @media ${breakpoints.lg} {
          margin-top: ${rem(10)};
        }
      }

      > .e-step-box-voice-images {
        margin-top: ${rem(30 / 2)};

        @media ${breakpoints.lg} {
          margin-top: ${rem(10)};
        }

        > .e-step-box-voice-image {
          svg,
          img {
            image-rendering: -webkit-optimize-contrast;
            width: ${rem(551.6 / 2)};
            height: ${rem(146.9 / 2)};

            @media ${breakpoints.lg} {
              width: ${rem(410)};
              height: ${rem(104.7)};
            }
          }

          &:nth-child(n + 2) {
            margin-top: ${rem(10 / 2)};

            @media ${breakpoints.lg} {
              margin-top: ${rem(10)};
            }
          }
        }
      }
    }
  }
`

export const Support1Step3Section: (props: Support1Step3SectionProps) => JSX.Element = StyledComponent

export default Support1Step3Section
