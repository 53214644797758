import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { color, fontFamily, innerWidth, rem, breakpoints, innerPadding, innerDummyPadding } from 'variables/_index'
import Rellax from 'rellax'
import classNames from 'classnames'
import { useInView } from 'react-intersection-observer'
import SupportHeadSection from './supportHeadSection'
import Support2Point1Section from './support2Point1Section'
import Support2Point2Section from './support2Point2Section'
import Support2Point3Section from './support2Point3Section'
import Support2SectionStudent from './support2SectionStudent'

export interface Support2SectionProps {
  readonly noProps?: string
}

type Support2SectionPropsClassName = Support2SectionProps & {
  readonly className: string
}

function Component({ className }: Support2SectionPropsClassName) {
  const illustRef1 = useRef<HTMLDivElement>(null)
  const illustRef2 = useRef<HTMLDivElement>(null)
  const illustRef3 = useRef<HTMLDivElement>(null)
  const illustRef4 = useRef<HTMLDivElement>(null)

  const { ref, inView } = useInView({
    rootMargin: '-70px', // ref要素が現れてから100px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  useEffect(() => {
    if (illustRef1.current) {
      new Rellax(illustRef1.current, {
        speed: 0.8, // -10 ~ 10
        center: true,
      })
    }

    if (illustRef2.current) {
      new Rellax(illustRef2.current, {
        speed: 0.7, // -10 ~ 10
        center: true,
      })
    }

    if (illustRef3.current) {
      new Rellax(illustRef3.current, {
        speed: 0.6, // -10 ~ 10
        center: true,
      })
    }

    if (illustRef4.current) {
      new Rellax(illustRef4.current, {
        speed: 0.5, // -10 ~ 10
        center: true,
      })
    }
  }, [])

  return (
    <div className={className}>
      <div className="e-secret-head">
        <SupportHeadSection />
      </div>
      <div className="e-lead-section">
        <div className="e-support2-inner">
          <div ref={ref} className={classNames('c-section-head', inView && 'is-view')}>
            <div className="c-section-head__image">
              <div className="c-section-head__image-in">
                <StaticImage
                  src="../../../../../assets/images/course/high-school/support/support2/support2-text.png"
                  alt=""
                  placeholder="none"
                  loading="lazy"
                />
              </div>
            </div>
            <h3 className="c-section-head__markers">
              <div className={classNames('c-section-head__marker', 'c-section-head__marker1')}>
                <div className="c-section-head__marker-in">どの受験方式でも必要な</div>
              </div>
              <div className={classNames('c-section-head__marker', 'c-section-head__marker2')}>
                <div className="c-section-head__marker-in">基礎学力と定期テスト対策</div>
              </div>
            </h3>
          </div>
          <div className="e-content">
            <div className="e-lead-text">
              <h4 className="e-lead-text-in">
                評定平均アップ!
                <br />
                受験選択の幅が広がる
              </h4>
              <div className="e-lead-image-box">
                <div className="e-lead-image-teacher">
                  <StaticImage
                    src="../../../../../assets/images/course/high-school/support/support2/teacher.png"
                    alt=""
                    placeholder="none"
                    loading="lazy"
                  />
                </div>
                <div className="e-lead-image-star1">
                  <StaticImage
                    src="../../../../../assets/images/course/high-school/support/support2/star3.png"
                    alt=""
                    placeholder="none"
                    loading="lazy"
                  />
                </div>
                <div className="e-lead-image-star2">
                  <StaticImage
                    src="../../../../../assets/images/course/high-school/support/support2/star1.png"
                    alt=""
                    placeholder="none"
                    loading="lazy"
                  />
                </div>
                <div className="e-lead-image-star3">
                  <StaticImage
                    src="../../../../../assets/images/course/high-school/support/support2/star2.png"
                    alt=""
                    placeholder="none"
                    loading="lazy"
                  />
                </div>
                <div className="e-lead-image-star4">
                  <StaticImage
                    src="../../../../../assets/images/course/high-school/support/support2/star1.png"
                    alt=""
                    placeholder="none"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            <div className="e-lead-content">
              Fitでは、<span className="is-em">わからない問題は演習をくり返すから苦手がなくなり、</span>
              基礎学力が身につくから、学校の授業がよくわかるように!
            </div>
            <div className="e-step1">
              <StaticImage
                src="../../../../../assets/images/course/high-school/support/support2/step1.png"
                alt=""
                placeholder="none"
                loading="lazy"
              />
            </div>
            <div className="e-arrow">
              <StaticImage
                src="../../../../../assets/images/course/high-school/support/support2/arrow.png"
                alt=""
                placeholder="none"
                loading="lazy"
              />
            </div>
            <div className="e-step2">だから定期テストの点数がアップ!</div>
            <div className="e-arrow">
              <StaticImage
                src="../../../../../assets/images/course/high-school/support/support2/arrow.png"
                alt=""
                placeholder="none"
                loading="lazy"
              />
            </div>
            <div className="e-step3">
              <span className="is-em">評定平均3.0以上</span>が目指せる!
            </div>
            <div className="e-student">
              <Support2SectionStudent />
            </div>
          </div>
        </div>
      </div>
      <div className="e-point-inner">
        <div className="e-point1">
          <div className="e-point1-wrap">
            <div className="e-point1-in">
              <Support2Point1Section />
            </div>
          </div>
        </div>
        <div className="e-point2">
          <div className="e-point2-wrap">
            <div className="e-point2-in">
              <Support2Point2Section />
            </div>
          </div>
        </div>
        <div className="e-point3">
          <div className="e-point3-wrap">
            <div className="e-point3-in">
              <Support2Point3Section />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<Support2SectionPropsClassName>`
  padding-top: ${rem(60 / 2)};
  padding-bottom: ${rem(60 / 2)};

  @media ${breakpoints.lg} {
    padding-top: ${rem(60)};
    padding-bottom: ${rem(80)};
  }

  @keyframes highSchoolSupport1Catch {
    0% {
      opacity: 0;
      translate: -101% 0;
    }

    100% {
      opacity: 1;
      translate: 0 0;
    }
  }

  @keyframes highSchoolSupport1CatchIn {
    0% {
      opacity: 0;
      translate: 101% 0;
    }

    100% {
      opacity: 1;
      translate: 0 0;
    }
  }

  .c-section-head {
    &__image {
      width: fit-content;
      overflow: hidden;
      margin-left: ${rem(-32 / 2)};
      translate: -100% 0;

      @media ${breakpoints.lg} {
        margin-left: ${rem(-32)};
      }
    }

    &__image-in {
      width: fit-content;
      translate: 100% 0;

      img {
        width: ${rem(250 / 2)};

        @media ${breakpoints.lg} {
          width: ${rem(250)};
        }
      }
    }

    &__markers {
      margin-top: ${rem(20 / 2)};

      @media ${breakpoints.lg} {
        margin-top: ${rem(22)};
      }
    }

    &__marker {
      width: fit-content;
      overflow: hidden;
      translate: -101% 0;
      margin-right: ${rem(10 / 2)};

      @media ${breakpoints.lg} {
        margin-right: ${rem(10)};
      }

      &:nth-child(n + 2) {
        margin-top: ${rem(24 / 2)};

        @media ${breakpoints.lg} {
          margin-top: ${rem(20)};
        }
      }
    }

    &__marker-in {
      width: fit-content;
      padding: ${rem(4)} ${rem(10)} ${rem(6)};
      background: ${color.main};
      color: #fff;
      font-size: ${rem(46 / 2)};
      letter-spacing: 0.05em;
      font-family: ${fontFamily.zenKaku};
      font-weight: 700;
      white-space: nowrap;
      translate: 101% 0;

      @media ${breakpoints.lg} {
        padding: ${rem(5)} ${rem(20)} ${rem(7)};
        font-size: ${rem(38)};
      }
    }

    &.is-view {
      .c-section-head__image {
        animation: highSchoolSupport1Catch linear 1s forwards 0s;

        .c-section-head__image-in {
          animation: highSchoolSupport1CatchIn linear 1s forwards 0s;
        }
      }

      .c-section-head__marker1 {
        animation: highSchoolSupport1Catch linear 0.6s forwards 0.6s;

        .c-section-head__marker-in {
          animation: highSchoolSupport1CatchIn linear 0.6s forwards 0.6s;
        }
      }

      .c-section-head__marker2 {
        animation: highSchoolSupport1Catch linear 0.6s forwards 1.2s;

        .c-section-head__marker-in {
          animation: highSchoolSupport1CatchIn linear 0.6s forwards 1.2s;
        }
      }
    }
  }

  > .e-lead-section {
    background: #edfbff;
    padding-top: ${rem(40 / 2)};

    @media ${breakpoints.lg} {
      padding-top: ${rem(80)};
    }

    > .e-support2-inner {
      width: ${innerWidth.sm};
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;

      @media ${breakpoints.lg} {
        width: ${rem(800)};
        padding-bottom: ${rem(80)};
      }

      > .e-content {
        margin-top: ${rem(65 / 2)};

        @media ${breakpoints.lg} {
          margin-top: ${rem(40)};
          background: #fff;
          border-radius: ${rem(20)};
          padding: ${rem(100)} ${rem(100)} ${rem(40)};
          margin-top: ${rem(28)};
        }

        > .e-lead-text {
          padding-bottom: ${rem(26 / 2)};
          padding-left: ${rem(190 / 2)};
          position: relative;

          @media ${breakpoints.lg} {
            padding-bottom: ${rem(0)};
            padding-left: ${rem(210)};
          }

          > .e-lead-text-in {
            font-size: ${rem(42 / 2)};
            display: inline;
            font-weight: 700;
            line-height: 2;
            color: #00c8ff;
            letter-spacing: 0.05em;
            border-bottom: 1.5px solid currentColor;
            font-family: ${fontFamily.zenKaku};

            @media ${breakpoints.lg} {
              font-size: ${rem(36)};
              border-bottom-width: 2px;
            }

            .is-em {
              font-size: ${rem(52 / 2)};
              color: #ff8c00;
            }
          }

          > .e-lead-image-box {
            position: absolute;
            left: 0;
            bottom: 0;

            @media ${breakpoints.lg} {
              bottom: auto;
              top: ${rem(-24)};
            }

            > .e-lead-image-teacher {
              img {
                width: ${rem(165.76 / 2)};

                @media ${breakpoints.lg} {
                  width: ${rem(165.76)};
                }
              }
            }

            .e-lead-image-star1 {
              position: absolute;
              left: ${rem(-6 / 2)};
              top: ${rem(40 / 2)};
              width: ${rem(20 / 2)};
              height: ${rem(20 / 2)};
              animation: highSchoolStar linear var(--star-speed) infinite forwards 0.7s;

              @media ${breakpoints.lg} {
                left: ${rem(-6)};
                top: ${rem(40)};
                width: ${rem(16)};
                height: ${rem(16)};
              }
            }

            > .e-lead-image-star2 {
              position: absolute;
              right: ${rem(24 / 2)};
              top: ${rem(-10 / 2)};
              width: ${rem(30 / 2)};
              height: ${rem(30 / 2)};
              animation: highSchoolStar linear var(--star-speed) infinite forwards 0s;

              @media ${breakpoints.lg} {
                right: ${rem(24)};
                top: ${rem(-10)};
                width: ${rem(24)};
                height: ${rem(24)};
              }
            }

            > .e-lead-image-star3 {
              position: absolute;
              right: ${rem(-16 / 2)};
              top: ${rem(-24 / 2)};
              width: ${rem(20 / 2)};
              height: ${rem(20 / 2)};
              animation: highSchoolStar linear var(--star-speed) infinite forwards 1.4s;

              @media ${breakpoints.lg} {
                right: ${rem(-16)};
                top: ${rem(-24)};
                width: ${rem(16)};
                height: ${rem(16)};
              }
            }

            > .e-lead-image-star4 {
              position: absolute;
              right: ${rem(-4 / 2)};
              bottom: ${rem(18 / 2)};
              width: ${rem(30 / 2)};
              height: ${rem(30 / 2)};
              animation: highSchoolStar linear var(--star-speed) infinite forwards 2.1s;

              @media ${breakpoints.lg} {
                right: ${rem(-4)};
                bottom: ${rem(18)};
                width: ${rem(24)};
                height: ${rem(24)};
              }
            }
          }
        }

        > .e-lead-content {
          margin-top: ${rem(40 / 2)};
          font-size: ${rem(32 / 2)};
          line-height: 2;
          text-align: justify;

          @media ${breakpoints.lg} {
            margin-top: ${rem(60)};
            font-size: ${rem(18)};
          }

          .is-em {
            background: linear-gradient(transparent 82%, #b2eeff 0);
          }
        }

        > .e-step1,
        > .e-step2,
        > .e-step3 {
          border-radius: ${rem(20 / 2)};
          background: #ffffdc;
          position: relative;
          width: ${rem(620 / 2)};
          max-width: 100%;
          margin-left: auto;
          margin-right: auto;
          color: ${color.main};
          font-size: ${rem(32 / 2)};
          letter-spacing: 0.05em;
          text-indent: 0.05em;
          text-align: center;
          padding: ${rem(26 / 2)} ${rem(2)} ${rem(36 / 2)};
          text-indent: ${rem(-10 / 2)};
          white-space: nowrap;

          @media ${breakpoints.lg} {
            border-radius: ${rem(20)};
            width: ${rem(590)};
            font-size: ${rem(24)};
            padding: ${rem(24)} ${rem(2)} ${rem(30)};
            text-indent: ${rem(-10 / 2)};
          }

          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: ${rem(-10 / 2)};
            top: ${rem(-10 / 2)};
            border: 1.5px solid ${color.main};
            border-radius: inherit;

            @media ${breakpoints.lg} {
              left: ${rem(-6)};
              top: ${rem(-6)};
              border-width: 3px;
            }
          }

          &.is-small {
            width: ${rem(550 / 2)};

            @media ${breakpoints.lg} {
              width: ${rem(550)};
            }
          }

          .is-em {
            font-weight: 700;
          }
        }

        > .e-step1 {
          margin-top: ${rem(80 / 2)};
          text-align: center;

          @media ${breakpoints.lg} {
            margin-top: ${rem(90)};
          }

          img {
            width: ${rem(562.42 / 2)};

            @media ${breakpoints.lg} {
              width: ${rem(440)};
            }
          }
        }

        > .e-arrow {
          margin-top: ${rem(25 / 2)};
          margin-bottom: ${rem(25 / 2)};
          text-align: center;

          @media ${breakpoints.lg} {
            margin-top: ${rem(20)};
            margin-bottom: ${rem(25)};
          }

          img {
            width: ${rem(150 / 2)};

            @media ${breakpoints.lg} {
              width: ${rem(80)};
            }
          }
        }

        > .e-step2 {
        }

        > .e-step3 {
        }

        > .e-student {
          margin-top: ${rem(30 / 2)};

          @media ${breakpoints.lg} {
            margin-top: ${rem(20)};
          }
        }
      }
    }
  }

  > .e-point-inner {
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${innerWidth.lg};
      padding-left: ${rem(innerPadding.lg + innerDummyPadding.lg)};
      padding-right: ${rem(innerPadding.lg + innerDummyPadding.lg)};
    }

    > .e-point1 {
      border-radius: ${rem(10 / 2)} ${rem(10 / 2)} 0 0;
      background: linear-gradient(to bottom, #edfbff ${rem(120 / 2)}, #fff 0);
      width: 100vw;
      margin-left: calc(50% - 50vw);
      margin-right: calc(50% - 50vw);
      padding-left: calc(50vw - 50%);
      /* padding-left: ${rem(40 / 2)}; */

      @media ${breakpoints.lg} {
        border-radius: ${rem(10)} ${rem(10)} 0 0;
        background: linear-gradient(to bottom, #edfbff ${rem(120)}, #fff 0);
        /* padding-left: ${rem(40)}; */
      }

      > .e-point1-wrap {
        background: #fff;

        @media ${breakpoints.lg} {
          border-radius: ${rem(20)} 0 0 0;
        }

        > .e-point1-in {
          padding-bottom: ${rem(80 / 2)};
          padding-right: ${rem(40 / 2)};

          @media ${breakpoints.lg} {
            width: ${rem(1000)};
            max-width: 100%;
            padding-right: ${rem(0)};
            padding-bottom: ${rem(80)};
          }
        }
      }
    }

    > .e-point2 {
      border-radius: ${rem(10 / 2)} ${rem(10 / 2)} 0 0;
      background: linear-gradient(to bottom, #fff ${rem(120 / 2)}, #edfbff 0);
      width: 100vw;
      margin-left: calc(50% - 50vw);
      margin-right: calc(50% - 50vw);
      padding-left: calc(50vw - 50%);
      /* padding-left: ${rem(40 / 2)}; */

      @media ${breakpoints.lg} {
        border-radius: ${rem(10)} ${rem(10)} 0 0;
        background: linear-gradient(to bottom, #fff ${rem(120)}, #edfbff 0);
        /* padding-left: ${rem(40)}; */
      }

      > .e-point2-wrap {
        background: #edfbff;

        @media ${breakpoints.lg} {
          border-radius: ${rem(20)} 0 0 0;
        }

        > .e-point2-in {
          padding-right: ${rem(40 / 2)};
          padding-bottom: ${rem(80 / 2)};

          @media ${breakpoints.lg} {
            width: ${rem(1000)};
            max-width: 100%;
            padding-right: ${rem(0)};
            padding-bottom: ${rem(80)};
          }
        }
      }
    }

    > .e-point3 {
      border-radius: ${rem(10 / 2)} ${rem(10 / 2)} 0 0;
      background: linear-gradient(to bottom, #edfbff ${rem(120 / 2)}, #fff 0);
      width: 100vw;
      margin-left: calc(50% - 50vw);
      margin-right: calc(50% - 50vw);
      padding-left: calc(50vw - 50%);
      /* padding-left: ${rem(40 / 2)}; */

      @media ${breakpoints.lg} {
        border-radius: ${rem(10)} ${rem(10)} 0 0;
        background: linear-gradient(to bottom, #edfbff ${rem(120)}, #fff 0);
        /* padding-left: ${rem(40)}; */
      }

      > .e-point3-wrap {
        background: #fff;

        @media ${breakpoints.lg} {
          border-radius: ${rem(20)} 0 0 0;
        }

        > .e-point3-in {
          padding-right: ${rem(40 / 2)};

          @media ${breakpoints.lg} {
            width: ${rem(1000)};
            max-width: 100%;
            padding-right: ${rem(0)};
          }
        }
      }
    }
  }
`

export const Support2Section: (props: Support2SectionProps) => JSX.Element = StyledComponent

export default Support2Section
