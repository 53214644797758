import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { color, fontFamily, innerWidth, rem, breakpoints, innerPadding, innerDummyPadding } from 'variables/_index'

export interface HowSectionProps {
  readonly noProps?: string
}

type HowSectionPropsClassName = HowSectionProps & {
  readonly className: string
}

function Component({ className }: HowSectionPropsClassName) {
  return (
    <div className={className}>
      <div className="e-inner">
        <h2 className="e-head">受講方法</h2>
        <div className="e-image">
          <StaticImage src="../../../../assets/images/course/high-school/how/illustration104.png" alt="" placeholder="none" />
        </div>
        <div className="e-lead">
          インターネット環境があれば
          <br className="u-hidden-pc" />
          ご受講が可能です。
        </div>
        <div className="e-boxes">
          <div className="e-box">
            <h3 className="e-box-head">｜学習システムの使い方｜</h3>
            <div className="e-box1-content">
              <div className="e-box1-left">
                <div className="c-use-portal-card">
                  <div className="c-use-portal-card__head">1</div>
                  <div className="c-use-portal-card__lead">ログインして授業の予定を確認</div>
                  <div className="c-use-portal-card__image">
                    <StaticImage src="../../../../assets/images/course/high-school/how/how1-1.png" alt="" placeholder="none" />
                  </div>
                </div>
              </div>
              <div className="e-box1-right">
                <div className="c-use-portal-card">
                  <div className="c-use-portal-card__head">2</div>
                  <div className="c-use-portal-card__lead">授業の入室ボタンをクリック</div>
                  <div className="c-use-portal-card__image">
                    <StaticImage src="../../../../assets/images/course/high-school/how/how1-2.png" alt="" placeholder="none" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="e-box">
            <h3 className="e-box-head">｜利用可能端末｜</h3>
            <div className="e-box2-content">
              <div className="e-box2-left">
                <StaticImage src="../../../../assets/images/course/high-school/how/how2-1.png" alt="" placeholder="none" />
              </div>
              <div className="e-box2-right">
                スマホ以外、ほぼすべての端末が利用できます。（無料体験はスマホもOK!）
                <div className="e-devices">
                  <div className="e-device">
                    タブレット
                    <span className="is-small">(アンドロイドまたはiPad)</span>
                  </div>
                  <div className="e-device">
                    PC
                    <span className="is-small">（WindowsまたはMac）</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="e-box">
            <h3 className="e-box-head">｜端末のレンタル｜</h3>
            <div className="e-box3-content">
              <div className="e-box3-left">
                <StaticImage src="../../../../assets/images/course/high-school/how/how3-1.png" alt="" placeholder="none" />
              </div>
              <div className="e-box3-right">
                タブレットのレンタルもございます。無料体験時にご相談ください。
                <div className="e-box3-illust">
                  <StaticImage src="../../../../assets/images/course/high-school/how/how3-2.png" alt="" placeholder="none" />
                </div>
              </div>
            </div>
          </div>
          <div className="e-illust-head">
            <StaticImage src="../../../../assets/images/course/high-school/how/head.png" alt="" placeholder="none" />
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<HowSectionPropsClassName>`
  padding-top: ${rem(100 / 2)};
  padding-bottom: ${rem(60 / 2)};

  @media ${breakpoints.lg} {
    padding-top: ${rem(80)};
    padding-bottom: ${rem(80)};
  }

  .c-use-portal-card {
    &__head {
      margin-left: auto;
      margin-right: auto;
      font-family: ${fontFamily.zenKaku};
      font-size: ${rem(80 / 2)};
      font-weight: 500;
      line-height: 1;
      text-align: center;
      color: ${color.main};

      @media ${breakpoints.lg} {
        font-size: ${rem(64)};
      }
    }

    &__lead {
      margin-top: ${rem(20 / 2)};
      font-size: ${rem(28 / 2)};
      line-height: 1;
      text-align: center;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      background: #fff;
      padding: ${rem(4)} ${rem(20 / 2)};
      letter-spacing: 0.1em;

      @media ${breakpoints.lg} {
        padding-top: ${rem(10)};
        padding-bottom: ${rem(10)};
        margin-top: ${rem(10)};
        font-size: ${rem(18)};
        width: 100%;
      }
    }

    &__image {
      margin-top: ${rem(30 / 2)};
      text-align: center;

      @media ${breakpoints.lg} {
        margin-top: ${rem(24)};
      }

      img {
        width: ${rem(550 / 2)};
        max-width: 100%;
        height: auto;

        @media ${breakpoints.lg} {
          width: ${rem(380)};
        }
      }
    }
  }

  > .e-inner {
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${innerWidth.lg};
      padding-left: ${rem(innerPadding.lg + innerDummyPadding.lg)};
      padding-right: ${rem(innerPadding.lg + innerDummyPadding.lg)};
    }

    > .e-head {
      margin-top: ${rem(10 / 2)};
      text-align: center;
      font-size: ${rem(50 / 2)};
      font-weight: 700;
      font-family: ${fontFamily.zenKaku};
      letter-spacing: 0.3em;
      text-indent: 0.3em;
      color: ${color.main};

      @media ${breakpoints.lg} {
        margin-top: ${rem(10)};
        font-size: ${rem(46)};
      }
    }

    > .e-image {
      margin-top: ${rem(60 / 2)};
      text-align: center;

      @media ${breakpoints.lg} {
        margin-top: ${rem(60)};
      }

      img {
        width: ${rem(549.61 / 2)};

        @media ${breakpoints.lg} {
          width: ${rem(525)};
        }
      }
    }

    > .e-lead {
      margin-top: ${rem(30 / 2)};
      font-size: ${rem(36 / 2)};
      text-align: center;
      line-height: 1.58;
      letter-spacing: 0.1em;

      @media ${breakpoints.lg} {
        margin-top: ${rem(30)};
        font-size: ${rem(24)};
      }
    }

    > .e-boxes {
      margin-top: ${rem(50 / 2)};
      padding-top: ${rem(60 / 2)};
      border-radius: ${rem(30 / 2)};
      position: relative;
      background: #edfbff;

      @media ${breakpoints.lg} {
        padding-top: ${rem(40)};
        padding-left: ${rem(60)};
        padding-right: ${rem(60)};
        margin-top: ${rem(60)};
      }

      > .e-illust-head {
        position: absolute;
        top: ${rem(-30 / 2)};
        left: 50%;
        transform: translateX(-50%);
        text-align: center;

        @media ${breakpoints.lg} {
          top: ${rem(-40)};
        }

        img {
          width: ${rem(200 / 2)};

          @media ${breakpoints.lg} {
            width: ${rem(176)};
          }
        }
      }

      > .e-box {
        border-top: 1px solid ${color.main};
        padding: ${rem(80 / 2)} ${rem(36 / 2)};

        @media ${breakpoints.lg} {
          border-width: 1px;
          padding-top: ${rem(80)};
          padding-bottom: ${rem(80)};
          padding-left: 0;
          padding-right: 0;
        }

        > .e-box-head {
          text-align: center;
          font-family: ${fontFamily.zenKaku};
          line-height: 1.2;
          font-size: ${rem(40 / 2)};
          letter-spacing: 0.2em;
          font-weight: 700;
          color: ${color.main};

          @media ${breakpoints.lg} {
            font-size: ${rem(38)};
          }
        }

        > .e-box1-content {
          display: flex;
          flex-wrap: wrap;
          margin-top: ${rem(30 / 2)};
          /* padding: ${rem(60 / 2)} ${rem(60 / 2)} ${rem(80 / 2)}; */

          @media ${breakpoints.lg} {
            margin-top: ${rem(30)};
            /* padding: ${rem(50)} ${rem(40)}; */
          }

          > .e-box1-left,
          > .e-box1-right {
            width: 100%;
            padding-left: ${rem(0)};
            padding-right: ${rem(0)};

            @media ${breakpoints.lg} {
              width: 50%;
              padding-left: ${rem(40)};
              padding-right: ${rem(40)};
            }
          }

          > .e-box1-left {
          }

          > .e-box1-right {
            margin-top: ${rem(40 / 2)};

            @media ${breakpoints.lg} {
              margin-top: ${rem(0)};
            }
          }
        }

        > .e-box2-content {
          display: flex;
          flex-wrap: wrap;
          margin-top: ${rem(30 / 2)};
          /* padding: ${rem(60 / 2)} ${rem(60 / 2)} ${rem(80 / 2)}; */

          @media ${breakpoints.lg} {
            margin-top: ${rem(30)};
            /* padding: ${rem(50)} ${rem(60)}; */
          }

          > .e-box2-left,
          > .e-box2-right {
            width: 100%;
            padding-left: ${rem(0)};
            padding-right: ${rem(0)};
            text-align: justify;

            @media ${breakpoints.lg} {
              width: 50%;
              padding-left: ${rem(20)};
              padding-right: ${rem(20)};
            }
          }

          > .e-box2-left {
            text-align: center;

            img {
              width: ${rem(400)};
              max-width: 100%;
              height: auto;
            }
          }

          > .e-box2-right {
            font-size: ${rem(28 / 2)};
            margin-top: ${rem(30 / 2)};
            line-height: 1.6;

            @media ${breakpoints.lg} {
              margin-top: ${rem(0)};
              font-size: ${rem(18)};
            }

            > .e-devices {
              margin-top: ${rem(30 / 2)};

              @media ${breakpoints.lg} {
                margin-top: ${rem(36)};
              }

              > .e-device {
                padding: ${rem(6)} ${rem(5 / 2)} ${rem(18 / 2)};
                border-radius: ${rem(10 / 2)};
                background: #00c8ff;
                color: #fff;
                text-align: center;
                font-size: ${rem(32 / 2)};
                font-weight: 500;
                white-space: nowrap;

                @media ${breakpoints.lg} {
                  padding: ${rem(0)} ${rem(10)};
                  border-radius: ${rem(10)};
                  font-size: ${rem(24)};
                }

                &:nth-child(n + 2) {
                  margin-top: ${rem(20 / 2)};

                  @media ${breakpoints.lg} {
                    margin-top: ${rem(20)};
                  }
                }

                .is-small {
                  font-size: ${rem(28 / 2)};

                  @media ${breakpoints.lg} {
                    font-size: ${rem(18)};
                  }
                }
              }
            }
          }
        }

        > .e-box3-content {
          display: flex;
          flex-wrap: wrap;
          margin-top: ${rem(30 / 2)};
          /* padding: ${rem(60 / 2)} ${rem(60 / 2)} ${rem(80 / 2)};*/

          @media ${breakpoints.lg} {
            margin-top: ${rem(30)};
            /* padding: ${rem(50)} ${rem(60)}; */
          }

          > .e-box3-left,
          > .e-box3-right {
            width: 100%;
            padding-left: ${rem(0)};
            padding-right: ${rem(0)};
            text-align: justify;

            @media ${breakpoints.lg} {
              width: 50%;
              padding-left: ${rem(20)};
              padding-right: ${rem(20)};
            }
          }

          > .e-box3-left {
            text-align: center;

            img {
              width: ${rem(400)};
              max-width: 100%;
              height: auto;
            }
          }

          > .e-box3-right {
            font-size: ${rem(28 / 2)};
            margin-top: ${rem(30 / 2)};
            line-height: 1.6;

            @media ${breakpoints.lg} {
              font-size: ${rem(18)};
              margin-top: ${rem(0)};
            }

            > .e-box3-illust {
              display: none;
              margin-top: ${rem(40 / 2)};
              text-align: center;

              @media ${breakpoints.lg} {
                display: block;
                margin-top: ${rem(14)};
              }

              img {
                width: ${rem(364.29 / 2)};
                max-width: 100%;
                height: auto;

                @media ${breakpoints.lg} {
                  width: ${rem(264)};
                }
              }
            }
          }
        }
      }
    }
  }
`

export const HowSection: (props: HowSectionProps) => JSX.Element = StyledComponent

export default HowSection
