import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { color, fontFamily, innerWidth, rem, breakpoints, innerPadding, innerDummyPadding } from 'variables/_index'
import Support2PointHead from './support2PointHead'

export interface Support2Point1SectionProps {
  readonly noProps?: string
}

type Support2Point1SectionPropsClassName = Support2Point1SectionProps & {
  readonly className: string
}

function Component({ className }: Support2Point1SectionPropsClassName) {
  return (
    <div className={className}>
      <Support2PointHead
        text="先取り授業"
        numberImageSVG={
          <svg height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg">
            <path
              d="m810.821 372.992a2.9 2.9 0 0 0 -.2-.9c-.3-.5-.995-.6-1.791-.6a3.68 3.68 0 0 0 -3.682 2.09v.1a17.707 17.707 0 0 1 -3.383 3.284c-2.587 1.99-4.577 3.682-6.269 5.174-.2.2-.5.5-.5.8a1.292 1.292 0 0 0 .2 1 3.152 3.152 0 0 0 2.289.9h.6a2.607 2.607 0 0 0 2.488-1.095c.4-1 2.488-2.587 3.98-3.781a9.333 9.333 0 0 1 1.194-.9 64.3 64.3 0 0 1 -3.284 11.343c-2.189 5.97-4.378 11.94-5.97 17.911a2.938 2.938 0 0 0 -.2 1.294c0 1.592 1.194 2.09 2.488 1.791a3 3 0 0 0 2.587-2.886c.4-4.677 4.08-13.731 5.572-18.806.4-1.294.9-2.587 1.393-4.08.9-2.189 1.99-4.577 1.99-6.866v-1c0-1.493.6-2.886.6-4.279-.202-.195-.102-.295-.102-.494z"
              fill="#00c8ff"
              transform="translate(-782.961 -371.482)"
            />
            <path d="m0 0h40v40h-40z" fill="none" />
          </svg>
        }
      />
      <div className="e-image">
        <div className="e-image-student">
          <StaticImage
            src="../../../../../assets/images/course/high-school/support/support2/point1/student1.png"
            alt=""
            placeholder="none"
            loading="lazy"
          />
        </div>
        <div className="e-image-star1-1">
          <StaticImage src="../../../../../assets/images/course/high-school/support/common/star-blue1.png" alt="" placeholder="none" loading="lazy" />
        </div>
        <div className="e-image-star1-2">
          <StaticImage src="../../../../../assets/images/course/high-school/support/common/star-blue2.png" alt="" placeholder="none" loading="lazy" />
        </div>
        <div className="e-image-star1-3">
          <StaticImage src="../../../../../assets/images/course/high-school/support/common/star-blue3.png" alt="" placeholder="none" loading="lazy" />
        </div>
        <div className="e-image-star1-4">
          <StaticImage src="../../../../../assets/images/course/high-school/support/common/star-blue4.png" alt="" placeholder="none" loading="lazy" />
        </div>
      </div>
      <div className="e-box">
        <div className="e-box-text">
          学校の授業を先取りする<span className="is-em">予習型授業</span>
          だから、学校の授業が理解しやすくなります。<span className="is-em">授業ごとに行う単元テストで、</span>
          理解度をチェックするので、<span className="is-em">苦手をそのままにしません。</span>
        </div>
        <div className="e-box-image1">
          <StaticImage
            src="../../../../../assets/images/course/high-school/support/support2/point1/image1.png"
            alt=""
            placeholder="none"
            loading="lazy"
          />
        </div>
        <div className="e-box-movie">
          <h6 className="e-movie-head">実際の授業の様子</h6>
          <iframe
            className="e-movie-iframe"
            src="https://www.youtube-nocookie.com/embed/mRcVCE_LH_A?si=K-OL1bj-9gD7lCj0"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <StaticImage
            className="e-movie-illust1"
            src="../../../../../assets/images/course/high-school/support/support2/point1/movie-illust1.png"
            alt=""
            placeholder="none"
            loading="lazy"
          />
          <StaticImage
            className="e-movie-illust2"
            src="../../../../../assets/images/course/high-school/support/support2/point1/movie-illust2.png"
            alt=""
            placeholder="none"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<Support2Point1SectionPropsClassName>`
  padding-top: ${rem(60 / 2)};

  @media ${breakpoints.lg} {
    padding-top: ${rem(40)};
  }

  > .e-head {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  > .e-image {
    margin-top: ${rem(20 / 2)};
    position: relative;
    z-index: 2;

    @media ${breakpoints.lg} {
      margin-top: ${rem(20)};
    }

    > .e-image-student {
      text-align: center;

      img {
        width: ${rem(264.62 / 2)};

        @media ${breakpoints.lg} {
          width: ${rem(264.62)};
        }
      }
    }

    > .e-image-star1-1 {
      width: ${rem(30 / 2)};
      height: ${rem(30 / 2)};
      position: absolute;
      top: ${rem(36 / 2)};
      right: calc(50% + ${rem(106 / 2)});
      animation: highSchoolStar linear var(--star-speed) infinite forwards 0.7s;

      @media ${breakpoints.lg} {
        width: ${rem(20)};
        height: ${rem(20)};
        top: ${rem(36)};
        right: calc(50% + ${rem(106)});
      }

      img {
        width: 100%;
      }
    }

    > .e-image-star1-2 {
      width: ${rem(20 / 2)};
      height: ${rem(20 / 2)};
      position: absolute;
      top: ${rem(46 / 2)};
      left: calc(50% + ${rem(18 / 2)});
      animation: highSchoolStar linear var(--star-speed) infinite forwards 0s;

      @media ${breakpoints.lg} {
        width: ${rem(20)};
        height: ${rem(20)};
        top: ${rem(46)};
        left: calc(50% + ${rem(18)});
      }
    }

    > .e-image-star1-3 {
      width: ${rem(30 / 2)};
      height: ${rem(30 / 2)};
      position: absolute;
      top: ${rem(68 / 2)};
      left: calc(50% + ${rem(56 / 2)});
      animation: highSchoolStar linear var(--star-speed) infinite forwards 1.4s;

      @media ${breakpoints.lg} {
        width: ${rem(30)};
        height: ${rem(30)};
        top: ${rem(68)};
        left: calc(50% + ${rem(56)});
      }
    }

    > .e-image-star1-4 {
      width: ${rem(20 / 2)};
      height: ${rem(20 / 2)};
      position: absolute;
      top: ${rem(34 / 2)};
      left: calc(50% + ${rem(84 / 2)});
      animation: highSchoolStar linear var(--star-speed) infinite forwards 2.1s;

      @media ${breakpoints.lg} {
        width: ${rem(20)};
        height: ${rem(20)};
        top: ${rem(34)};
        left: calc(50% + ${rem(84)});
      }
    }
  }

  > .e-box {
    margin-top: ${rem(-30 / 2)};
    background: #edfbff;
    border-radius: ${rem(10 / 2)};
    padding: ${rem(60 / 2)} ${rem(60 / 2)} ${rem(70 / 2)};

    @media ${breakpoints.lg} {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: ${rem(40)} ${rem(60)};
      margin: ${rem(-20)} 0 0;
      border-radius: ${rem(10)};
      padding: ${rem(30)} ${rem(60)} ${rem(50)};
    }

    > .e-box-text {
      font-size: ${rem(32 / 2)};
      line-height: 2;
      text-align: justify;

      @media ${breakpoints.lg} {
        font-size: ${rem(18)};
        width: calc(50% - ${rem(60 / 2)});
      }

      .is-em {
        background: linear-gradient(transparent 82%, #b2eeff 0);
      }
    }

    > .e-box-image1 {
      text-align: center;
      margin-top: ${rem(40 / 2)};

      @media ${breakpoints.lg} {
        margin-top: ${rem(0)};
        width: calc(50% - ${rem(60 / 2)});
      }

      img {
        width: ${rem(430 / 2)};

        @media ${breakpoints.lg} {
          width: ${rem(300)};
        }
      }
    }

    > .e-box-movie {
      margin-top: ${rem(44)};
      position: relative;

      @media ${breakpoints.lg} {
        width: 100%;
        margin-top: 0;
      }

      > .e-movie-head {
        font-weight: 700;
        font-size: ${rem(32 / 2)};
        letter-spacing: 0.05em;
        text-align: center;

        @media ${breakpoints.lg} {
          font-family: ${fontFamily.zenKaku};
          font-weight: 700;
          font-size: ${rem(24)};
          letter-spacing: 0.05em;
          text-align: center;
        }
      }

      > .e-movie-iframe {
        width: 100%;
        margin: ${rem(20 / 2)} 0 0;
        aspect-ratio: 560 / 315;
        border: none;
        box-shadow: none;
        display: block;
        margin-left: auto;
        margin-right: auto;

        @media ${breakpoints.lg} {
          width: ${rem(440)};
          margin-top: ${rem(17)};
        }
      }

      > .e-movie-illust1 {
        display: none;

        @media ${breakpoints.lg} {
          display: block;
          position: absolute;
          width: ${rem(97.58)};
          bottom: ${rem(112)};
          right: calc(50% + ${rem(242)});
        }
      }

      > .e-movie-illust2 {
        display: none;

        @media ${breakpoints.lg} {
          display: block;
          position: absolute;
          width: ${rem(148.75)};
          bottom: ${rem(54)};
          left: calc(50% + ${rem(236)});
        }
      }
    }
  }
`

export const Support2Point1Section: (props: Support2Point1SectionProps) => JSX.Element = StyledComponent

export default Support2Point1Section
